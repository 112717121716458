import React, { useState, useEffect, useContext } from 'react'
import { CircleLoading, Footer, Header } from '../components/dino-ui'
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { toast } from 'react-toastify'
import { Context } from "../Store";
import { getEnv } from '../libs/Env';
import erc20Abi from '../abi/erc20.json';
import shillAbi from '../abi/shill.json';
import BigNumber from 'bignumber.js';
import { isGreaterThan, parseFromWei } from '../libs/utils';
import { ButtonLoading } from '../components/Forms';

export const CreateShill = () => {
   
    const [state, dispatch] = useContext(Context);
    const [storeHash, setStoreHash] = useState(null);
    const [projectName, setProjectName] = useState('');
    const [projectLogo, setProjectLogo] = useState('');
    const [password, setPassword] = useState('');
    const [telegramUsername, setTelegramUsername] = useState('');
    const [linkProject, setLinkProject] = useState('');
    const [owner, setOwner] = useState('');
    const [initialReward, setInitialReward] = useState('');
    const [slugPool, setSlugPool] = useState('');
    const [rewardDapps, setRewardDapps] = useState('');
    const [rewardPage, setRewardPage] = useState('');
    const [balanceDino,setBalanceDino] = useState(0)
    const [startDate, setStartDate] = useState(new Date());
    const [shillConfigs,setShillConfigs] = useState({})
    const [isLoadingBalance,setIsLoadingBalance] = useState(false)
    const [isLoadingSubmit,setIsLoadingSubmit] = useState(false)
    const [txReceipt,setTxReceipt] = useState({})

    const client = axios.create({
        baseURL: 'https://api-shill.dinodapps.com/api/pool/create',
    });

    useEffect(()=>{
        
    },[])

    useEffect(async () => {
        if (state && state.web3 && state.account) {
            // console.log(state.account)
            setOwner(state.account);
            getConfig()
        }
    }, [state])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // alert(state.web3.utils.toWei(initialReward)) 
        // alert(state.web3.utils.toHex(shillConfigs.configs.receiver))
        try {
            setIsLoadingSubmit(true)
            await axios.post('https://api-shill.dinodapps.com/api/pool/create', {
                projectName: projectName,
                projectLogo: projectLogo,
                password: password,
                telegramUsername: telegramUsername,
                linkProject: linkProject,
                owner: state.account,
                initialReward: initialReward,
                slugPool: slugPool,
                rewardDapps: rewardDapps,
                rewardPage: rewardPage,
                dateLive: startDate,
            }).then(async(res)=>{
                const contract = new state.web3.eth.Contract(erc20Abi, state.web3.utils.toHex(shillConfigs.configs.quote))
                const contractShill = new state.web3.eth.Contract(shillAbi,state.web3.utils.toHex(shillConfigs.configs.shill))
                const allowance = await contract.methods.allowance(state.web3.utils.toHex(state.account),state.web3.utils.toHex(shillConfigs.configs.shill)).call()
                const _initialReward = state.web3.utils.toWei(initialReward);
                if(isGreaterThan(allowance,_initialReward)){
                    await contract.methods.approve(
                        state.web3.utils.toHex(shillConfigs.configs.shill),
                        _initialReward
                    ).send({
                        from: state.web3.utils.toHex(state.account)
                    })
                }

                await contractShill.methods.quote(
                    state.web3.utils.toHex(state.account),
                    _initialReward
                ).send({
                    from: state.web3.utils.toHex(state.account),
                    value: state.web3.utils.toWei('0.000001')
                }).then(async(receipt) => {
                    await axios.post('https://api-shill.dinodapps.com/api/pool/edit',{
                        address: state.account,
                        password: password,
                        slugPool: res.data.result.slugPool,
                        txHash: receipt.transactionHash
                    }).then((res2)=>{
                        toast.success("Success create pool")
                        
                        setTimeout(()=>{
                            setIsLoadingSubmit(false)
                            window.location = "/shill"
                        },3000)
                    }).catch(err=>{
                        setIsLoadingSubmit(false)
                    })
                });
            }).catch(error=>{
                toast.error('Error: ' + error.response.data?.message, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

            // toast.success('Success', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });

            // setProjectName('');
            // setProjectLogo('');
            // setPassword('');
            // setTelegramUsername('');
            // setLinkProject('');
            // setOwner('');
            // setInitialReward('');
            // setSlugPool('');
            // setRewardDapps('');
            // setRewardPage('');
            // setStartDate(new Date());

            
        } catch(e){
            console.error("Submit Err",e)
        } finally {
            setIsLoadingSubmit(false)
        }

        // addPosts(projectName, projectLogo, password, telegramUsername, linkProject, owner, initialReward, slugPool, rewardDapps, rewardPage, startDate);
    }

    const addPosts = async (tx) => {
        try {
            

            window.location = "/shill"
        } catch (error) {
            console.log(error.response.data);
            
        } finally {
            setIsLoadingSubmit(false)
        }
    };

    const getConfig = async() => {
        try {
            const { data } = await axios.get("https://api.webooswap.com/api/config?chain_id=97&code=FORM_REGISTRATION_DINO_SHILL",{
                headers: {
                    "Accept": "application/json",
                    "Authorization": "Bearer "+getEnv().WEBOOSAFU_ACCESS_TOKEN
                }
            })
            setShillConfigs(data.result.data[0])
            getDinoBalance(data.result.data[0].configs.quote)
        } catch(e){
            console.error("failed get config",e)
        }
    }

    const getDinoBalance = async(tokenAddress) => {
        setIsLoadingBalance(true)
        const contract = await new state.web3.eth.Contract(erc20Abi,tokenAddress)
        const balance = await contract.methods.balanceOf(state.account).call()
        setBalanceDino(parseFromWei(balance))
        setIsLoadingBalance(false)
    }

    return (
        <>
            <Header />
            <section className="relative py-4 overflow-hidden bg-gray-100 dark:bg-gray-900 md:py-20 lg:py-16 font-body">
                <section className="items-center gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero">
                    <div className="col-span-1 md:col-span-6">
                        <div className="text-3xl font-bold leading-snug dark:text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                            Create <span className="text-green-500">Shill</span>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="flex flex-col px-8 pt-6 pb-8 my-2 mb-4 bg-white rounded shadow-md">
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="project-name">
                                    Project Name
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="project-name" type="text" placeholder="Dino dApps" value={projectName} onChange={(e) => setProjectName(e.target.value)} required />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="slug-pool">
                                    Pool Name
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="slug-pool" type="text" placeholder="shill-test-and-get-free-dino" value={slugPool} onChange={(e) => setSlugPool(e.target.value)}  required/>
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="link-project">
                                    Link Project <span className="text-red-600">(Make sure you already register at Dino dApps)</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="link-project" type="text" placeholder="https://dinodapps.com/detail-dapps/test" value={linkProject} onChange={(e) => setLinkProject(e.target.value)} required />
                                <div className="flex items-center justify-between w-full">
                                    <div>
                                        <p className="text-xs italic text-red">Please fill out this field with your project link at Dino dApps.</p>
                                        <p className="text-xs italic text-red-500">* Register your project at the Dino dApps store here .</p>
                                    </div>
                                    <a href="https://dinodapps.com/add-dapps" target="_blank" rel="noreferrer" className="font-medium bg-green-500 text-white py-2 rounded-md px-4">
                                        Register
                                    </a>
                                </div>
                            </div>
                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="telegram">
                                    Telegram Username
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="telegram" type="text" placeholder="@yourtelegramid" value={telegramUsername} onChange={(e) => setTelegramUsername(e.target.value)} required />
                                <p className="text-xs italic text-red">Required (for notification).</p>
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="grid-password">
                                    Password
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="grid-password" type="password" placeholder="******************" value={password} onChange={(e) => setPassword(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Make it as long and as crazy as you'd like</p>
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="owner">
                                    Owner
                                </label>
                                <input className="block w-full px-4 py-3 bg-gray-300 border rounded appearance-none text-grey-darker border-grey-lighter" id="owner" type="text" placeholder="0x" value={state.account} onChange={(e) => setOwner(e.target.value)} required />

                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="initial-reward">
                                    Reward Allocation (DINO)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="initial-reward" type="text" placeholder="1000000" value={initialReward} onChange={(e) => setInitialReward(e.target.value)} required />
                                <p className="text-xs italic text-red flex flex-row items-center text-gray-500">
                                    Balance: &nbsp;
                                    
                                    {isLoadingBalance && <CircleLoading size={3} />}
                                    {!isLoadingBalance && (
                                        balanceDino+" DINO"
                                    )}
                                </p>
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="project-logo">
                                    Project Logo Url (get url from your dapps on dinodapps)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="project-logo" type="text" placeholder="https://dinodapps.com/images/dino.png" value={projectLogo} onChange={(e) => setProjectLogo(e.target.value)} required />
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="reward-dapps">
                                    Reward Per Store Visit (DINO)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="reward-dapps" type="text" placeholder="100" value={rewardDapps} onChange={(e) => setRewardDapps(e.target.value)} required />
                                <p className="text-xs italic text-red">Store is your project page at Dino dApps.</p>
                            </div>
                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="reward-page">
                                    Reward Per dApps Visit (DINO)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="reward-page" type="text" placeholder="200" value={rewardPage} onChange={(e) => setRewardPage(e.target.value)} required />
                                <p className="text-xs italic text-red">dApps is your Platform / Website.</p>
                            </div>
                        </div>
                        <div className="mb-2 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="date-live">
                                    Date Live
                                </label>
                                <DatePicker selected={startDate} className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" onChange={(date) => setStartDate(date)} />
                            </div>
                        </div>
                        <div className="mt-5 w-32">
                            <ButtonLoading isLoading={isLoadingSubmit} title={"Create Shill"} type={"submit"}>

                            </ButtonLoading>
                            
                        </div>
                    </form>
                </section>
            </section>
            <Footer />
        </>
    )
}
