import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AirdropDetail from "./pages/Airdrop/AirdropDetail";
// import AirdropCreatePool from "./pages/AirdropCreatePool";
import AirdropList from "./pages/Airdrop/AirdropList";
import Referral from "./pages/Referral";
import ReferralMember from "./pages/ReferralMember";
// import Home from "./pages/Home";
import Investment from "./pages/Investment";
import Shill from "./pages/Shill";
import Store from "./Store";
import Staking from "./pages/Staking";
import History from "./pages/History";
import ShillDetail from "./pages/ShillDetail";
import RegisterDapps from "./pages/RegisterDapps";
import { CreateShill } from "./pages/CreateShill";
import Developer from "./pages/Developer";
import EditDapps from "./pages/EditDapps";

export default function Navigation() {
    return (
        <Store>
            <BrowserRouter>
                <Routes>
                    <Route path={"/"} element={<Investment />} />
                    <Route path={"/tes"} element={<Staking />} />
                    <Route path={"/history"} element={<History />} />
                    <Route path={"/developer"} element={<Developer />} />
                    <Route path={"/dapps-edit/:id"} element={<EditDapps />} />
                    <Route path={"/create-shill"} element={<CreateShill />} />
                    <Route path={"/register-dapps"} element={<RegisterDapps />} />
                    <Route path={"/shill"} element={<Shill />} />
                    <Route path={"/shill-detail/:slug"} element={<ShillDetail />} />
                    <Route path={"/referral"} element={<Referral />} />
                    <Route path={"/referral-member"} element={<ReferralMember />} />
                    <Route path={"/faucet-list"} element={<AirdropList />} />
                    <Route path={"/faucet/:id"} element={<AirdropDetail />} />
                    {/* <Route path={"/airdrop-create-pool"} element={<AirdropCreatePool />} /> */}
                </Routes>
            </BrowserRouter>
        </Store>
    )
}