import { Tab } from '@headlessui/react'
import React, { Fragment } from 'react'
import AutoStaking from '../components/AutoStaking'
import { Footer, Header } from '../components/dino-ui'
import Farming from '../components/Farming'
import ManualStaking from '../components/ManualStaking'
import RewardForm from '../components/RewardForm'
import Reward from './Reward'

export default function Investment() {
  function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
  }
  return (
    <>
      <Header />
      <div className="w-full mx-auto">
        <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
          <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
            <div className="col-span-1 md:col-span-6">
              <div className="text-3xl font-bold leading-snug dark:text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                Stake <span className="text-green-500">DINO</span> Choose Your
                Staking Reward Token
              </div>
              <div className="grid grid-cols-1 mt-4 md:mt-12 lg:mt-16 md:grid-cols-12">
                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                  <svg
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005"
                      stroke="#00B67A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <p className="ml-4 font-normal text-dark-2 dark:text-white">
                    Choose Your Staking <br /> Reward Token
                  </p>
                </div>
                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                  <svg
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005"
                      stroke="#00B67A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <p className="ml-4 font-normal text-dark-2 dark:text-white">
                    Stake DINO With <br /> Simple Interest Method
                  </p>
                </div>
                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                  <svg
                    width="15"
                    height="11"
                    viewBox="0 0 15 11"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005"
                      stroke="#00B67A"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <p className="ml-4 font-normal text-dark-2 dark:text-white">
                    Stake DINO With <br /> Compounding Method
                  </p>
                </div>
              </div>
            </div>
            <div className="col-span-1 md:col-span-6">
              <div className="flex justify-center w-full">
                <img
                  src="./images/stake-illustration.png"
                  className="w-9/12"
                  alt=""
                />
              </div>
            </div>
          </section>
        </section>
        <Tab.Group>
          {/* Start Tabs */}
          <Tab.List className="flex items-center justify-center w-full gap-4 py-5 bg-white dark:bg-gray-900">
            {/* <Tab
              className={({ selected }) =>
                classNames(
                  'px-5 py-2 rounded-full',
                  selected
                    ? 'text-white bg-gray-900 border border-gray-900'
                    : 'text-gray-900 bg-white border border-gray-900',
                )
              }
            >
              All
            </Tab> */}
            <Tab
              className={({ selected }) =>
                classNames(
                  'px-5 py-2 rounded-full',
                  selected
                    ? 'text-white bg-gray-900 border border-gray-900'
                    : 'text-gray-900 bg-white border border-gray-900',
                )
              }
            >
              Staking
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'px-5 py-2 rounded-full',
                  selected
                    ? 'text-white bg-gray-900 border border-gray-900'
                    : 'text-gray-900 bg-white border border-gray-900',
                )
              }
            >
              Farm
            </Tab>
            <Tab
              className={({ selected }) =>
                classNames(
                  'px-5 py-2 rounded-full',
                  selected
                    ? 'text-white bg-gray-900 border border-gray-900'
                    : 'text-gray-900 bg-white border border-gray-900',
                )
              }
            >
              Reward
            </Tab>
          </Tab.List>
          {/* End Tabs */}
          <Tab.Panels className="p-4 bg-gray-100 dark:bg-gray-800 md:p-10 lg:p-10">
            {/* Start Staking Tabs */}
            <Tab.Panel className="content-center px-1 md:px-20 lg:px-24">
              <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-2">
                <AutoStaking />
                <ManualStaking />

              </div>
            </Tab.Panel>
            <Tab.Panel className="content-center px-1 md:px-20 lg:px-24">
              <Farming />
            </Tab.Panel>
            <Tab.Panel className="content-center px-1 md:px-20 lg:px-24">
              <RewardForm />
            </Tab.Panel>
            {/* End Staking Tabs */}
          </Tab.Panels>
        </Tab.Group>
      </div>
      <Footer />
    </>
  )
}
