import React, { useContext, useEffect, useState } from "react";
import {
  BtnWalletConnect,
  CopyButton,
  Footer,
  Header,
  ModalTxHash,
} from "../components/dino-ui";
import { Context } from "../Store";
import { ButtonLoading } from "../components/Forms";
import { checkValidEmail } from "../libs/WebooLib";
import axios from "axios";
import { getAppByChainId } from "../libs/Env";
import Transaction from "ethereumjs-tx";
import { HiCheck } from 'react-icons/hi';

const featuresTwo = [
  {
    name: "Name Token",
    description: "Dino Dapps Token.",
    icon: HiCheck,
  },
  {
    name: "Symbol",
    description: "DINO",
    icon: HiCheck,
  },
  {
    name: "Decimal",
    description: "18",
    icon: HiCheck,
  },
  {
    name: "Total Supply",
    description: "1.000.000.000",
    icon: HiCheck,
  },
  {
    name: "Treasury",
    description: "Treasury",
    icon: HiCheck,
  },
  {
    name: "Staking",
    description: "High APR Staking With Long Last Invesment.",
    icon: HiCheck,
  },
  {
    name: "No Mint",
    description: "No Mintable.",
    icon: HiCheck,
  },
];

const features = [    
  {
    name: '1% Staking Rewards',
    description:
      'From Buy Fee.',
    icon: HiCheck,
  },
  {
    name: '2.5% Staking Rewards',
    description:
      'From Sell Fee.',
    icon: HiCheck,
  },
  {
    name: '1% Marketing',
    description:
      'From Buy Fee.',
    icon: HiCheck,
  },
  {
    name: '2.5% Marketing',
    description:
      'From Sell Fee.',
    icon: HiCheck,
  },
  {
    name: '1% Operational',
    description:
      'From Buy Fee.',
    icon: HiCheck,
  },
  {
    name: '2% Operational',
    description:
      'From Sell Fee.',
    icon: HiCheck,
  },
  {
    name: '1% Treasury and Buyback',
    description:
      'From Buy Fee.',
    icon: HiCheck,
  },
  {
    name: '2% Treasury and Buyback',
    description:
      'From Sell Fee.',
    icon: HiCheck,
  },
]

export default function ReferralMember() {
  const [isLoading, setIsLoading] = useState(false)
  const [state, dispatch] = useContext(Context);
  const [isValidReferralLink, setIsValidReferralLink] = useState(true);

  useEffect(async ()=>{
    if(state && state.web3 && state.account) {
      let parentAddr = (new URLSearchParams(window.location.search)).get("addr")
      let child = state.account;
      setIsLoading(true)
      await submitMemberAPI(parentAddr,child);
      
    } 
  },[state])

  const submitMemberAPI = async(parent,child) => {
    await axios.post("https://api-farming.webooswap.com/api/v1/referral/member",{
        parent: parent,
        child: child,
        project: "Dino Project",
      }).then(async(res)=>{
        if(res.data.code === 200) await submitMemberSC(parent,child)
        else setIsValidReferralLink(false);
      }).catch(e=>{
        setIsValidReferralLink(false);
      })
  }

  const submitMemberSC = async(parent,child) => {
    const referralPrivateKey = 'ccdcccd2b03f5e46d81ab7b3b4e87bfd4a0e5403d06e608466409f2221b22035';
    const privateKeyBuffer = Buffer.from(referralPrivateKey, 'hex');
    const userReferral = await state.web3.eth.accounts.privateKeyToAccount(referralPrivateKey);
    const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).BSAFU_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS,{
      from: userReferral.address
    });
    const check = await token.methods.referralAddress(child).call();
    if(check === '0x0000000000000000000000000000000000000000'){
      const setReferralFunction = await token.methods.setReferral(parent,child);
      const encodeABI = setReferralFunction.encodeABI();
      await setReferralFunction.estimateGas({
        from:state.web3.utils.toHex(userReferral.address)
      }).then(gasAmount => {
        state.web3.eth.getTransactionCount(state.web3.utils.toHex(userReferral.address)).then(_nonce => {
          const nonce = _nonce.toString(16);
          // console.log("Nonce",nonce)
          const txParams = {
            gasPrice: 5000000000,
            gasLimit: gasAmount,
            to: state.web3.utils.toHex(getAppByChainId(state.chainId).TOKEN_ADDRESS),
            data: encodeABI,
            from: state.web3.utils.toHex(userReferral.address),
            nonce: '0x' + nonce
          };
          // console.log(txParams)
          const tx = new Transaction (txParams,{'chain':'bsc'});
          tx.sign(privateKeyBuffer); // Transaction Signing here

          const serializedTx = tx.serialize();

          state.web3.eth.sendSignedTransaction('0x' + serializedTx.toString('hex'))
          .on("transactionHash", (hash) => {
            console.log(hash);
          }).on("receipt", (hash)=>{
            setIsLoading(false);
          })
        })
      })
    } else {
      setIsLoading(false);
    }
  }

  const onclick = (url) => {
    window.location.href = url;
  }
  return (
    <>
      <Header />
      <>
        <section className="py-2 bg-white dark:bg-gray-800 md:py-8 lg:py-10">
          <div className="px-4 py-2 mx-2 bg-gray-100 dark:bg-gray-900 md:px-8 lg:px-10 sm:mx-12 md:mx-24 lg:mx-32">
            <div className="flex flex-col mx-auto lg:flex-row">
              {isValidReferralLink &&
                <div className="w-full pl-2 md:pl-6 lg:pl-3">
                  <BtnWalletConnect>
                    <div className="items-start justify-start block gap-1 lg:flex md:flex">
                      <div className="flex flex-col mx-auto lg:block">
                          <div className='w-full pl-6'>
                              <div className='mt-10 font-semibold text-green-500 md:mt-20 lg:mt-10'>Dino Tokenomics</div>
                              <h1 className='mt-3 text-2xl font-extrabold text-black dark:text-white sm:text-3xl lg:text-4xl lg:leading-tight'>Dino <br/> Tokenomics.</h1>
                              <p className='font-semibold text-black dark:text-gray-100'>Fee Buy: 4% & Fee Sell: 9%</p>

                              <div className="my-10">
                                  <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                                      {features.map((feature) => (
                                      <div key={feature.name} className="relative">
                                          <dt>
                                          <div className="absolute flex items-center justify-center w-8 h-8 text-white bg-green-500 rounded-full">
                                              <feature.icon className="w-6 h-6" aria-hidden="true" />
                                          </div>
                                          <p className="ml-12 text-lg font-bold leading-6 text-black dark:text-gray-300">{feature.name}</p>
                                          </dt>
                                          <dd className="mt-2 ml-12 text-base text-black dark:text-gray-300">{feature.description}</dd>
                                      </div>
                                      ))}
                                  </dl>
                              </div>
                              <ButtonLoading title={"Buy on Pancakeswap"} isLoading={isLoading} onClick={()=>onclick("https://pancakeswap.finance/swap?outputCurrency=0x32bFd701655EDF95809EaA5e525F0024ea571267")} />
                          </div>
                      </div>
                      
                      <div className="flex flex-col mx-auto lg:flex-row">
                        <div className="w-full pl-6">
                          <div className="mt-10 font-normal text-green-500">
                            Dino Token
                          </div>
                          <h1 className="mt-3 text-2xl font-extrabold text-black dark:text-white sm:text-3xl lg:text-4xl lg:leading-tight">
                            Dino Token <br /> Information
                          </h1>
                          <p className="my-3 text-black dark:text-gray-100">
                            Dapps, Token Info Platform
                          </p>

                          <div className="my-10">
                            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
                              {featuresTwo.map((feature) => (
                                <div key={feature.name} className="relative">
                                  <dt>
                                    <div className="absolute flex items-center justify-center w-8 h-8 text-white bg-green-500 rounded-full">
                                      <feature.icon
                                        className="w-6 h-6"
                                        aria-hidden="true"
                                      />
                                    </div>
                                    <p className="ml-12 text-lg font-bold leading-6 text-black dark:text-gray-300">
                                      {feature.name}
                                    </p>
                                  </dt>
                                  <dd className="mt-2 ml-12 text-base text-black dark:text-gray-300">
                                    {feature.description}
                                  </dd>
                                </div>
                              ))}
                            </dl>
                          </div>
                          <ButtonLoading title={"Buy on Webooswap"} isLoading={isLoading} onClick={()=>onclick("https://swap.webooswap.com/swap?outputCurrency=0x32bFd701655EDF95809EaA5e525F0024ea571267")} />
                        </div>
                      </div>
                      
                    </div>
                  </BtnWalletConnect>
                </div>
              }
              {!isValidReferralLink && <h1>Invalid Referral Link</h1>}
            </div>
          </div>
        </section>
      </>
      <Footer />
    </>
  );
}
