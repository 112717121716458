import { Disclosure } from '@headlessui/react'
import BigNumber from 'bignumber.js'
import { Formik } from 'formik'
import React, { useContext, useEffect, useState } from 'react'
import {
  RiArrowUpLine,
  RiCalendar2Line,
  RiRefreshLine,
  RiFileCopy2Line,
} from 'react-icons/ri'
import { toast } from 'react-toastify'
import { usePricePerToken } from '../hooks/hooks'
import { getAppByChainId } from '../libs/Env'
import { amountFormat, amountFormatFromWei, getAmountDivider } from '../libs/WebooLib'
import { Context } from '../Store'
import { BtnWalletConnect, ChainIconCircle, CopyAddress, ToastContentTx } from './dino-ui'
import { InputNumberWithBalance } from './Forms'

export default function FarmingBNB() {
  const [state, dispatch] = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [hash, setHash] = useState('')
  const [txHash, setTxHash] = useState('')
  const [apr,setAPR] = useState(0)
  const [tokenBalance,setTokenBalance] = useState(0)
  const [participant,setParticipant] = useState(0)
  const [poolshare,setPoolshare] = useState(0)
  const [farmingBalance,setFarmingBalance] = useState(0);
  const [farmingAddress,setFarmingAddress] = useState("")
  const [amountTokenA,setAmountTokenA] = useState(0)
  const [amountTokenB,setAmountTokenB] = useState(0)
  const [amountTokenRevoke,setAmountTokenRevoke] = useState(0)
  const [tokenAAddress,setTokenAAddress] = useState("")
  const [tokenBAddress,setTokenBAddress] = useState("")
  const [isPlant,setIsPlant] = useState(false);
  const [isRevoke,setIsRevoke] = useState(false);
  const [isNeedApproveTokenB,setIsNeedApproveTokenB] = useState(true);
  const [isNeedApproveTokenRevoke,setIsNeedApproveTokenRevoke] = useState(true);
  const [{ pricePerToken }, updatePricePerToken] = usePricePerToken();
  const [isInsufficientToken,setIsInsufficientToken] = useState(true);
  const [isInsufficientTokenRevoke,setIsInsufficientTokenRevoke] = useState(true);

  useEffect(() => {
    const check = async () => {
      if (
        state &&
        state.web3 &&
        state.web3.utils.toChecksumAddress(state.account)
      ) {
        await init()
        setHash(state.storeHash)
      }
    }

    check()
  }, [state])

  useEffect(()=>{
    if(!txHash) return ;
    setHash(state.storeHash)
  },[txHash])

  useEffect(()=>{
    if(tokenAAddress != "" && tokenBAddress != ""){
      updatePricePerToken(tokenAAddress,tokenBAddress)
    }
    
  },[amountTokenA,amountTokenB])

  const init = async () => {
    try {
      setIsLoading(true)
      let chainId = state.chainId
      const token = await new state.web3.eth.Contract(
        getAppByChainId(chainId).ERC20_ABI,
        getAppByChainId(chainId).TOKEN_ADDRESS,
      )
      let _tokenBalance = await token.methods.balanceOf(state.web3.utils.toChecksumAddress(state.account)).call();
      setTokenBalance(amountFormatFromWei(_tokenBalance,4));

      setFarmingAddress(getAppByChainId(chainId).FARMING_BNB)
      const tokenFarming = await new state.web3.eth.Contract(
        getAppByChainId(chainId).FARMING_ABI,
        getAppByChainId(chainId).FARMING_BNB
      )
      let _tokenFarmingBalance = await tokenFarming.methods.balanceOf(state.web3.utils.toChecksumAddress(state.account)).call();
      setFarmingBalance(amountFormatFromWei(_tokenFarmingBalance,4))

      let _participant = await tokenFarming.methods.totalParticipant().call();
      setParticipant(_participant);

      let _poolshare = await tokenFarming.methods.totalSupply().call();
      setPoolshare(amountFormatFromWei(_poolshare,4));
      

      setTokenAAddress(getAppByChainId(chainId).TOKEN_ADDRESS);
      setTokenBAddress(getAppByChainId(chainId).WETH_ADDRESS);

      let _tokenAllowance = await token.methods.allowance(
        state.web3.utils.toChecksumAddress(state.account),
        state.web3.utils.toChecksumAddress(farmingAddress)
      ).call();
      let _balance = new BigNumber(_tokenBalance);
      let _allowance = new BigNumber(_tokenAllowance);
      if(_balance.eq(0)){
        setIsInsufficientToken(true)
      } else {
        setIsInsufficientToken(false) 
        if(_balance.gt(_allowance)) {
          setIsNeedApproveTokenB(true) 
        } else {
          setIsNeedApproveTokenB(false)
        }
      }

      //revoke
      
      let _tokenAllowanceRevoke = await tokenFarming.methods.allowance(
        state.web3.utils.toChecksumAddress(state.account),
        state.web3.utils.toChecksumAddress(farmingAddress)
      ).call();
      let _balanceRevoke = new BigNumber(_tokenFarmingBalance);
      let _allowanceRevoke = new BigNumber(_tokenAllowanceRevoke);
      console.log("revoke",_balanceRevoke.toString(),_allowanceRevoke.toString())
      if(_balanceRevoke.eq(0)){
        setIsInsufficientTokenRevoke(true)
      } else {
        setIsInsufficientTokenRevoke(false) 
        if(_balanceRevoke.gt(_allowanceRevoke)) {
          setIsNeedApproveTokenRevoke(true) 
        } else {
          setIsNeedApproveTokenRevoke(false)
        }
      }
      
    } catch (e) {
      console.error('Init Farming', e)
    } finally {
      setIsLoading(false)
    }
  }

  const onValidate = (values) => {
    try {
      BigNumber.config({
        FORMAT: {
          prefix: "",
          decimalSeparator: ".",
          groupSeparator: "",
        },
      });
      const amountB = new BigNumber(values.tokenB);
      const amountA = new BigNumber(values.tokenA);
      const multiplier = new BigNumber(10);
      const multiplierB = multiplier.pow(18);
      const pricePerTokenB = new BigNumber(pricePerToken);
      const priceDivMultiplierB = pricePerTokenB.dividedBy(multiplierB);
      // alert(priceDivMultiplierB.toString());
      const price = new BigNumber(
        getAmountDivider(
          pricePerToken,
          18,
          18
        )
      );
      if (values.tokenA === amountTokenA) {
        const val = amountB.div(priceDivMultiplierB).toFormat(0);
        setAmountTokenA(val);
        setAmountTokenB(values.tokenB);
      } else {
        const val = amountA.multipliedBy(priceDivMultiplierB).toFormat(8);
        setAmountTokenA(values.tokenA);
        setAmountTokenB(val);
      }
    } catch (e) {
      if (isNaN(values.tokenA)) setAmountTokenA(0);
      if (isNaN(values.tokenB)) setAmountTokenB(0);
    }
  }

  const onValidateRevoke = (values) => {
    try {
      BigNumber.config({
        FORMAT: {
          prefix: "",
          decimalSeparator: ".",
          groupSeparator: "",
        },
      });
      
      
    } catch (e) {
      if (isNaN(values.tokenA)) setAmountTokenRevoke(0);
    }
  }

  const onSubmit = async (data, { setFieldError }) => {
    let _amountA = new BigNumber(amountTokenA);
    if(_amountA.eq(new BigNumber(0))) {
      toast.error("Invalid Amount")
    } else {
      setIsLoading(true)
      let farming = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).FARMING_BNB_ABI,
        farmingAddress
      );
      await farming.methods.addFarming(
        state.web3.utils.toWei(data.tokenA),
        state.web3.utils.toHex(state.account)
      ).send({
        from: state.account,
        value: state.web3.utils.toWei(data.tokenB),
      }).on('transactionHash', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Plant Submitted"} chainId={state.chainId} txHash={h} />);
        setTxHash(h)
      })
      .on('receipt', async(h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Plant Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        setTxHash(h.transactionHash)
        // init()
        await init();
      })
      await init();
      
    }
  }

  const onEnableContract = async()=> {
    try {
      setIsLoading(true)
      const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, tokenAAddress);
      const supply = await token.methods.totalSupply().call();
      await token.methods.approve(
        state.web3.utils.toChecksumAddress(farmingAddress),
        supply
      ).send({
        from: state.account
      }).on('transactionHash', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Submitted"} chainId={state.chainId} txHash={h} />);
        setTxHash(h)
      })
      .on('receipt', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        setTxHash(h.transactionHash)
        
      })
      await init()
    } catch(e){
      toast.error(e)
      console.error("Enable Contract",e)
    } finally {
      setIsLoading(false)
    }
  }

  const onEnableContractRevoke = async()=> {
    try {
      setIsLoading(true)
      const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, farmingAddress);
      const supply = await token.methods.totalSupply().call();
      await token.methods.approve(
        state.web3.utils.toChecksumAddress(farmingAddress),
        supply
      ).send({
        from: state.account
      }).on('transactionHash', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Submitted"} chainId={state.chainId} txHash={h} />);
        setTxHash(h)
      })
      .on('receipt', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        setTxHash(h.transactionHash)
        
      })
      await init()
    } catch(e){
      toast.error(e)
      console.error("Enable Contract",e)
    } finally {
      setIsLoading(false)
    }
  }

  const onSubmitRevoke = async (data, { setFieldError }) => {
    let _amountA = new BigNumber(data.tokenA);
    if(_amountA.eq(new BigNumber(0))) {
      toast.error("Invalid Amount")
    } else {
      
      setIsLoading(true)
      let farming = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).FARMING_BNB_ABI,
        farmingAddress
      );
      await farming.methods.removeFarming(
        state.web3.utils.toWei(data.tokenA),
        state.web3.utils.toHex(state.account)
      ).send({
        from: state.account,
      }).on('transactionHash', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Revoke Submitted"} chainId={state.chainId} txHash={h} />);
        setTxHash(h)
      })
      .on('receipt', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Revoke Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        setTxHash(h.transactionHash)
        init()
      })
      await init()
    }
  }

  return (
    <div className="w-full p-2 mx-auto bg-white rounded-2xl">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left text-green-900 bg-green-100 rounded-lg hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
              <div className="flex items-center justify-between gap-2">
                <ChainIconCircle
                  icon1={'/images/icon-reward/dino-token.png'}
                  icon2={'/images/icon-reward/bnb.svg'}
                  size={40}
                  borderWidth={4}
                />

                <div className="block">
                  {/* <div className="p-2 mb-2 font-bold text-white bg-red-900 border border-red-800 rounded-lg">
                                                                We are under maintenance !
                                                            </div> */}
                  <hr />
                  <div className="flex justify-between">
                    <strong className={'text-black dark:text-white mr-2'}>
                      {/* {item.name == 'Weboo-BNB' && <small>This Farming Under Maintenance <br />(Migration From v1 to v2)<br /></small>} */}
                      DINO - BNB
                    </strong>
                    <div className={'flex'}>
                      <div className={'flex flex-row items-center'}>
                        <img
                          src={
                            'https://cdn.webooswap.com/storage/file/file_202201221420076947_1642861207.png'
                          }
                          style={{
                            width: 20,
                            height: 20,
                          }}
                          className={'mr-1'}
                          alt={'Weboo'}
                        />
                      </div>
                      <div className={'flex flex-row items-center'}>
                        <img
                          src={
                            'https://cdn.webooswap.com/storage/file/file_202202040612042699_1643955124.svg'
                          }
                          style={{
                            width: 20,
                            height: 20,
                          }}
                          className={'mr-1'}
                          alt={'Weboo'}
                        />
                      </div>
                    </div>
                  </div>
                  <div className={'text-gray-900 dark:text-white text-sm'}>
                    Est. APR: {apr}%
                  </div>
                </div>
              </div>
              <RiArrowUpLine
                className={`${
                  open ? 'rotate-180 transform' : ''
                } h-5 w-5 text-green-500`}
              />
            </Disclosure.Button>
            <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
              <BtnWalletConnect>
                {isPlant && (
                  <Formik
                  initialValues={{ tokenA: amountTokenA, tokenB: amountTokenB }}
                  enableReinitialize={true}
                  validate={(values) => onValidate(values)}
                  onSubmit={onSubmit}
                >
                  {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                    <form onSubmit={handleSubmit}>
                      <InputNumberWithBalance
                        title={"Amount"}
                        onChange={handleChange("tokenA")}
                        value={isNaN(values.tokenA) ? 0 : values.tokenA}
                        subFix={"DINO"}
                        prefix={"DINO"}
                        address={tokenAAddress}
                      />
                      <div
                        className={
                          "flex flex-row items-center mb-2 justify-center font-bold text-lg"
                        }
                      >
                        +
                      </div>
                      <InputNumberWithBalance
                        title={"Amount"}
                        onChange={handleChange("tokenB")}
                        value={isNaN(values.tokenB) ? 0 : values.tokenB}
                        subFix={"BNB"}
                        prefix={"BNB"}
                        address={tokenBAddress}
                      />

                  <div className="text-center w-full gap-2">
                    <div className="flex items-center justify-between w-full gap-2">
                      {isNeedApproveTokenB && (
                        <button
                          type="button"
                          className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                          onClick={()=>{onEnableContract()}}
                          disabled={isLoading}
                        >
                          <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                          {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                            <div>Enable Contract</div>
                          </div>
                        </button>
                      )}
                      {isInsufficientToken && !isLoading && (
                     <button
                        type="button"
                        className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                        onClick={()=>{alert("plant")}}
                        disabled={true}
                      >
                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                        {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                          <div>Insufficient Amount</div>
                        </div>
                      </button> 
                      )}
                       {!isInsufficientToken && (
                        <button
                        type="submit"
                        className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                        disabled={isNeedApproveTokenB || isLoading}
                      >
                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                        {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                          <div>Plant</div>
                        </div>
                      </button>
                       )}
                      
                    </div>
                    <div className="text-black dark:text-white">Or</div>
                    <button
                      type="button"
                      className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                      onClick={()=>{setIsPlant(false);setIsRevoke(true)}}
                      disabled={isLoading}
                    >
                      <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                        <div>Revoke</div>
                      </div>
                    </button>
                  </div>
                    </form>
                  )}
                </Formik>
                )}
                {isRevoke && (
                  <Formik
                  initialValues={{ tokenA: amountTokenRevoke}}
                  enableReinitialize={true}
                  validate={(values) => onValidateRevoke(values)}
                  onSubmit={onSubmitRevoke}
                >
                  {({ handleChange, handleBlur, handleSubmit, values, errors }) => (
                    <form onSubmit={handleSubmit}>
                      <InputNumberWithBalance
                        title={"Amount"}
                        onChange={handleChange("tokenA")}
                        value={isNaN(values.tokenA) ? 0 : values.tokenA}
                        subFix={"Dino FP"}
                        prefix={"Dino FP"}
                        address={farmingAddress}
                      />
                      

                  <div className="text-center w-full gap-2">
                    <div className="flex items-center justify-between w-full gap-2">
                      {isNeedApproveTokenRevoke && (
                        <button
                          type="button"
                          className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                          onClick={()=>{onEnableContractRevoke()}}
                          disabled={isLoading}
                        >
                          <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                          {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                            <div>Enable Contract</div>
                          </div>
                        </button>
                      )}
                      {isInsufficientTokenRevoke && !isLoading && (
                     <button
                        type="button"
                        className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                        onClick={()=>{alert("plant")}}
                        disabled={true}
                      >
                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                        {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                          <div>Insufficient Amount</div>
                        </div>
                      </button> 
                      )}
                       {!isInsufficientTokenRevoke && (
                        <button
                        type="submit"
                        className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                        disabled={isNeedApproveTokenRevoke || isLoading}
                      >
                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                        {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                          <div>Revoke</div>
                        </div>
                      </button>
                       )}
                      
                    </div>
                    <div className="text-black dark:text-white">Or</div>
                    <button
                      type="button"
                      className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                      onClick={()=>{setIsPlant(true);setIsRevoke(false)}}
                      disabled={isLoading}
                    >
                      <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                        <div>Plant</div>
                      </div>
                    </button>
                  </div>
                    </form>
                  )}
                </Formik>
                )}
                {(!isPlant && !isRevoke) && (
                  <div className="flex flex-row items-center justify-center w-full align-middle">
                  <div className="flex items-center justify-between w-full gap-2">
                    <button
                      type="button"
                      className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                      onClick={()=>{setIsPlant(true);setIsRevoke(false)}}
                      disabled={isLoading}
                    >
                      <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                        <div>Plant</div>
                      </div>
                    </button>
                    <div className="text-black dark:text-white">Or</div>
                    <button
                      type="button"
                      className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800 disabled:opacity-25"
                      onClick={()=>{setIsPlant(false);setIsRevoke(true)}}
                      disabled={isLoading}
                    >
                      <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-4 h-4 border-4 border-white border-solid rounded-full float animate-spin"
                            />
                          )}
                        <div>Revoke</div>
                      </div>
                    </button>
                  </div>
                </div>
                )}
                
              </BtnWalletConnect>

              <div className="flex flex-row items-center justify-center w-full mt-4 align-middle">
                <div className="flex flex-col w-full p-4 bg-white border-2 border-gray-400 rounded-xl dark:bg-gray-900 dark:border-gray-800">
                  <div className="mb-2 text-xs text-black dark:text-white">
                    Harvest To
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <div className="flex flex-row items-center text-sm font-bold text-black dark:text-white">
                      <img
                        src="/images/icon-reward/dino-token.png"
                        className="mr-2 rounded-full"
                        alt="WEBOO"
                        style={{ width: '30px', height: '30px' }}
                      />
                      <div className="flex flex-row text-xs">
                        <span className="text-green-900">Est:&nbsp;</span>
                        0.00000000
                      </div>
                    </div>
                    <div>
                      <button
                        type="button"
                        className="block w-full flex flex-row justify-center items-center px-5 py-2 text-sm text-white bg-green-500 rounded-full disabled:opacity-25"
                        disabled={isLoading}
                      >
                        {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-3 h-3 border-2 border-white border-solid rounded-full float animate-spin"
                            />
                          )}&nbsp;
                          <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                            <div>Harvest</div>
                          </div>
                        
                        
                      </button>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="w-full mb-2 border-t border-gray-300 dark:border-gray-800"></div>
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <div className="flex flex-row items-center text-sm font-bold text-black dark:text-white">
                      <img
                        src="/images/icon-reward/bsafu.svg"
                        className="mr-2 rounded-full"
                        alt="WEBOO"
                        style={{ width: '30px', height: '30px' }}
                      />
                      <div className="flex flex-row text-xs">
                        <span className="text-green-900">Est:&nbsp;</span>
                        0.00000000
                      </div>
                    </div>
                    <div>
                    <button
                        type="button"
                        className="block w-full flex flex-row justify-center items-center px-5 py-2 text-sm text-white bg-green-500 rounded-full disabled:opacity-25"
                        disabled={isLoading}
                      >
                        {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-3 h-3 border-2 border-white border-solid rounded-full float animate-spin"
                            />
                          )}&nbsp;
                          <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                            <div>Harvest</div>
                          </div>
                        
                      </button>
                    </div>
                  </div>
                  <div className="relative">
                    <div className="w-full mb-2 border-t border-gray-300 dark:border-gray-800"></div>
                  </div>
                  <div className="flex flex-row items-center justify-between mb-2">
                    <div className="flex flex-row items-center text-sm font-bold text-black dark:text-white">
                      <img
                        src="https://cdn.webooswap.com/storage/file/file_202201221441567442_1642862516.svg"
                        className="mr-2 rounded-full"
                        alt="BNB"
                        style={{ width: '30px', height: '30px' }}
                      />
                      <div className="flex flex-row text-xs">
                        <span className="text-green-900">Est:&nbsp;</span>
                        0.00000000
                      </div>
                    </div>
                    <div>
                    <button
                        type="button"
                        className="block w-full flex flex-row justify-center items-center px-5 py-2 text-sm text-white bg-green-500 rounded-full disabled:opacity-25"
                        disabled={isLoading}
                      >
                        {isLoading && (
                            <div
                              style={{
                                borderTopColor: "transparent",
                              }}
                              className="w-3 h-3 border-2 border-white border-solid rounded-full float animate-spin"
                            />
                          )}&nbsp;
                          <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                            <div>Harvest</div>
                          </div>
                        
                      </button>
                    </div>
                  </div>
                  <span className="pb-2 text-xs text-black dark:text-white">
                    * Harvest Dino get 4% more token
                  </span>
                  <span className="pb-2 text-xs text-black dark:text-white">
                    ** Import farming token into your wallet
                  </span>
                </div>
              </div>

              {/* <div className="flex flex-row items-center justify-between">
                <div className="flex flex-col gap-y-2 py-3">
                  <span className="text-gray-900 font-bold dark:text-white text-sm">
                    Last Update Dividend
                  </span>
                  <p className="flex items-center gap-1 text-gray-700 dark:text-gray-200">
                    <RiCalendar2Line /> 06/09/2022 15:30
                  </p>
                </div>
                <button
                  type="button"
                  className="flex gap-1 items-center rounded-full text-white px-5 py-2 text-sm bg-green-900 hover:bg-gray-800"
                >
                  <RiRefreshLine /> Update
                </button>
              </div> */}
              <div className="flex flex-col gap-1 mt-3">
                <div className="flex flex-row justify-between">
                  <span className="text-gray-900 dark:text-white text-sm">
                    Dino Ecosystem
                  </span>
                  <span className="text-gray-900 dark:text-white text-sm">{tokenBalance}</span>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-gray-900 dark:text-white text-sm">
                    DINOBNB-FP
                  </span>
                  <span className="text-gray-900 dark:text-white text-sm">{farmingBalance}</span>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-gray-900 dark:text-white text-sm">
                    Pool Share
                  </span>
                  <span className="text-gray-900 dark:text-white text-sm">{poolshare}</span>
                </div>
                <div className="flex flex-row justify-between">
                  <span className="text-gray-900 dark:text-white text-sm">
                    Participant
                  </span>
                  <span className="text-gray-900 dark:text-white text-sm">
                    {participant}
                  </span>
                </div>
                <div className="flex items-center justify-between pb-2 text-sm text-black dark:text-white">
                  <div>SC Farming Token:</div>
                  <div className="flex items-center gap-1">
                    <CopyAddress addr={farmingAddress} />
                  </div>
                </div>
              </div>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  )
}
