import React, { Fragment, useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from "../Store";
import { Footer, Header } from '../components/dino-ui'
import axios from 'axios'

export default function Developer() {
    const [state, dispatch] = useContext(Context);
    const [owner, setOwner] = useState('');
    const [dapps, setDapps] = useState([]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(async () => {
        function getOwner() {
            if (state && state.web3 && state.account) {
                // console.log(state.account)
                setOwner(state.account);
                axios.get('http://localhost:8000/api/product/getbyowner?search=' + state.account + '&dir=asc&column=id&length=10&draw=0&page=1')
                    .then(res => {
                        setDapps(res.data.data);
                        // console.log(res.data.data)
                    })
            }
        }
        getOwner();
    }, [state])

    return (
        <>
            <Header />
            <div className="items-center gap-12 px-4 mx-auto bg-gray-100 max-w-screen-2xl lg:px-28">
                <section className="relative flex flex-col items-center justify-center py-4 overflow-hidden bg-gray-100 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <h5 className='text-2xl font-bold'>Dashboard</h5>
                    <p className='mt-3'>List dApps Already You Create It</p>
                </section>

                <div className="content-center py-10">
                    <div className="grid content-center grid-cols-3 gap-6 align-middle lg:grid-cols-3">
                        {dapps.map((dapp, dappsIndex) => {
                            return (
                                <div className='p-4 bg-white rounded-md' key={dappsIndex}>
                                    <div className="flex gap-4">
                                        <div className='flex items-center justify-center p-1 border border-gray-600 rounded-full'>
                                            <img className='w-12 h-12' src={'http://localhost:8000/images/icon-dapps/' + dapp.icon_url} alt="" />
                                        </div>
                                        <div>
                                            <div className='font-semibold capitalize'>
                                                {dapp.name}
                                            </div>
                                            <div className='flex items-center gap-1 mt-2'>
                                                {dapp.networks?.map((network, netIndex) => {
                                                    return (
                                                        <div key={netIndex}>
                                                            <img src={'https://dinodapps.com' + network.logo_url} alt="" />
                                                        </div>
                                                    )
                                                })}
                                                <div></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='mt-4 capitalize'>
                                        {dapp.tagline}
                                    </div>
                                    <div className='flex justify-end pt-3'>
                                        <NavLink to={'/dapps-edit/' + dapp.id} className='px-3 py-1 text-white bg-green-500 rounded-md'>Edit</NavLink>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
