import React, { Fragment, useContext, useEffect, useState } from "react";
import {
    BtnWalletConnect,
    ChainIconCircle,
    CopyAddress,
    Footer,
    Header,
    ModalTxHash,
} from "../components/dino-ui";
import { FiRefreshCw } from 'react-icons/fi'
import { BsQuestionCircle, BsChevronDown } from 'react-icons/bs'

import { Disclosure } from '@headlessui/react'
import { ButtonLoading, ButtonLoadingGray, InputNumberWithBalance } from "../components/Forms";
import { Context } from "../Store";
import { getAppByChainId } from "../libs/Env";
import { BigNumber } from "bignumber.js";
import { amountFormat, countAPY } from "../libs/WebooLib";
import axios from "axios";
import { Tab } from '@headlessui/react'
import { RiArrowUpLine, RiCalendar2Line, RiCoinFill, RiFileCopy2Line, RiRefreshLine } from "react-icons/ri";

const FormCompounding = ({ onGetTotalStacked = (e) => { } }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [hash, setHash] = useState(null);
    const [decimal, setDecimal] = useState(18);
    const [tokenAddress, setTokenAddress] = useState(null);
    const [amount, setAmount] = useState(0);
    const [txHash, setTxHash] = useState(null);
    const [isNeedApprove, setIsNeedApprove] = useState(false);
    const [isNeedApproveUnstake, setIsNeedApproveUnstake] = useState(false);
    const [apr, setApr] = useState(0);
    const [apy, setApy] = useState(0);
    const [isUnstake, setIsUnstake] = useState(false);
    const [stakingAddress, setStakingAddress] = useState(null);
    const [stakingBalance, setStakingBalance] = useState(0);
    const [unclaimBalance, setUnclaimBalance] = useState(0);
    const [totalStacked, setTotalStacked] = useState(0);

    useEffect(async () => {
        if (state && state.web3 && state.account) {
            await init();
            setHash(state.storeHash);
        }
    }, [state, txHash])

    useEffect(() => {
        onGetTotalStacked(totalStacked);
    }, [totalStacked])

    const checkIfNeedEnableContract = async () => {
        try {
            if (state && state.chainId && state.account) {
                // alert("0xb39AF32036e58605bE3dD3BB91D5898c9bf76F1F")
                const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
                setTokenAddress(getAppByChainId(state.chainId).TOKEN_ADDRESS);
                const scAllowance = await token.methods.allowance(
                    state.web3.utils.toHex(state.account),
                    state.web3.utils.toHex(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS)
                ).call();
                const allowance = new BigNumber(
                    scAllowance
                );
                let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
                const dec = await token.methods.decimals().call();
                setDecimal(dec);

                if (allowance.isGreaterThanOrEqualTo(balance)) {
                    setIsNeedApprove(false)
                } else {
                    setIsNeedApprove(true);
                }
            }
        } catch (e) {
            console.error("check Need Enable", e);
        }
    }

    const checkIfNeedEnableUnstake = async () => {
        try {
            if (state && state.chainId && state.account) {
                const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
                const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
                const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, autoStakingAddress);
                const allowance = new BigNumber(await token.methods.allowance(state.account, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS).call());
                let balance = new BigNumber(await token.methods.balanceOf(state.account).call());

                if (allowance.isGreaterThanOrEqualTo(balance)) {
                    setIsNeedApproveUnstake(false)
                } else {
                    setIsNeedApproveUnstake(true);
                }
            }
        } catch (e) {
            console.error("check Need Enable", e);
        }
    }

    const init = async () => {
        await checkIfNeedEnableContract();
        await checkIfNeedEnableUnstake();

        const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
        const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
        const staking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).AUTO_STAKING_ABI, autoStakingAddress);
        const dec = await staking.methods.decimals().call();
        const totalSupply = new BigNumber(await staking.methods.totalSupply().call());
        const APR = await staking.methods.APR().call();
        const totalLoop = await staking.methods.loopInterest().call();
        const dividendOf = new BigNumber(await staking.methods.dividendOf(state.account).call());
        setUnclaimBalance(amountFormat(dividendOf.dividedBy(10 ** 18), 18))
        let stkBalance = new BigNumber(await staking.methods.balanceOf(state.account).call());
        stkBalance = stkBalance.dividedBy(10 ** dec);
        setStakingBalance(amountFormat(stkBalance, 4));
        setApr(APR);
        setApy(countAPY(APR, totalLoop));
        setStakingAddress(autoStakingAddress);
        setTotalStacked(amountFormat(totalSupply.div(10 ** dec).toString()))
    }

    const onStake = async () => {
        try {
            setIsLoading(true);
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            let amnt = new BigNumber(amount);
            amnt = amnt.multipliedBy(10 ** dec);

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
            await routerStaking.methods.stake(autoStakingAddress, state.account, amnt.toFixed(0)).send({
                from: state.account
            }).on("transactionHash", (hash) => setTxHash(hash))
                .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
        } catch (e) {
            console.error("On Stake", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onUnstake = async () => {
        try {
            setIsLoading(true);
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            let amnt = new BigNumber(amount);
            amnt = amnt.multipliedBy(10 ** dec);
            // alert(amnt.toFixed(0))

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
            
            await routerStaking.methods.unstake(autoStakingAddress, state.account, amnt.toFixed(0)).send({
                from: state.account
            }).on("transactionHash", (hash) => setTxHash(hash))
                .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
        } catch (e) {
            console.error("On UnStake", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onEnable = async () => {
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
        let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
        balance = balance.multipliedBy(200);
        setIsLoading(true);
        await token.methods.approve(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS, balance.toFixed(0)).send({
            from: state.account
        }).on("transactionHash", (hash) => setTxHash(hash))
            .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
    }

    const onEnableUnstake = async () => {
        const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
        const autoStakingAddress = await routerStaking.methods.autoStakingAddress().call();
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, autoStakingAddress);
        let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
        balance = balance.multipliedBy(200);
        setIsLoading(true);
        await token.methods.approve(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS, balance.toFixed(0)).send({
            from: state.account
        }).on("transactionHash", (hash) => setTxHash(hash))
            .on("receipt", (hash) => {
                setTxHash(hash);
                setIsLoading(false);
                setIsNeedApproveUnstake(false);
            });
    }

    return (
        <>
            <div className="flex justify-between w-full py-2">
                <p className="text-gray-900 dark:text-white">APY</p>
                <p className="text-gray-900 dark:text-white">{apy}%</p>
            </div>
            <div className="flex justify-between w-full py-2">
                <p className="text-gray-900 dark:text-white">Balance (DINO-SP(AUTO))</p>
                <p className="font-bold text-gray-900 dark:text-white">{stakingBalance}</p>
            </div>
            <div className="flex justify-between w-full py-2">
                <p className="text-gray-900 dark:text-white">Unclaim Reward (DINO)</p>
                <p className="font-bold text-gray-900 dark:text-white">{unclaimBalance}</p>
            </div>

            {/* <div className="block w-full py-2">
                <p className="text-gray-900 dark:text-white">Recent DINO Profit:</p>
                <p className="text-gray-900 underline">0.1% unstaking fee if withdrawn within 72h</p>
            </div> */}
            <div className="block w-full py-2">
                <p className="font-semibold text-gray-900 dark:text-white">
                    {!isUnstake ? "Start Earning" : "Unstake"}
                </p>
                {/* disable button compounding */}
                {!isUnstake &&
                    <BtnWalletConnect>
                        <InputNumberWithBalance
                            title={"Amount"}
                            onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                            value={amount}
                            subFix={"DINO"}
                            prefix={"DINO"}
                            address={tokenAddress}
                            key={'dino' + txHash}
                        />
                        {isNeedApprove &&
                            <ButtonLoading
                                title={"Enable Contract"}
                                onClick={() => onEnable()}
                                isLoading={isLoading}
                            />
                        }
                        {!isNeedApprove &&
                            <div className="flex items-center justify-between gap-2">
                                <ButtonLoading title={"Stake"} isLoading={isLoading} onClick={() => onStake()} />
                                <ButtonLoadingGray title={"Unstake"} isLoading={isLoading} onClick={() => setIsUnstake(true)} />
                            </div>
                        }
                    </BtnWalletConnect>
                }
                {isUnstake &&
                    <BtnWalletConnect>
                        <InputNumberWithBalance
                            title={"Amount"}
                            onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                            value={amount}
                            subFix={"DINO-SP(Auto)"}
                            prefix={"DINO-SP(Auto)"}
                            address={stakingAddress}
                            key={'dino-sp' + txHash}
                        />
                        {isNeedApproveUnstake &&
                            <>
                                <ButtonLoading
                                    title={"Enable Contract"}
                                    onClick={() => onEnableUnstake()}
                                    isLoading={isLoading}
                                />
                                <div className="text-center">or</div>
                                <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                            </>
                        }
                        {!isNeedApproveUnstake &&
                            <div className="flex items-center justify-between gap-2">
                                <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                                <ButtonLoading title={"Unstake"} isLoading={isLoading} onClick={() => onUnstake()} />
                            </div>
                        }
                    </BtnWalletConnect>
                }
                <ModalTxHash txHash={txHash} />
            </div>
            <div>
                <p>Note: Auto-claim will be carried out alternately with the DINO transaction trigger. Please be patient to get an auto-claim turn.</p>
            </div>
        </>
    )
}

const FormManualStaking = ({ onGetTotalStacked = (e) => { } }) => {
    const [isLoading, setIsLoading] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [hash, setHash] = useState(null);
    const [decimal, setDecimal] = useState(18);
    const [tokenAddress, setTokenAddress] = useState(null);
    const [amount, setAmount] = useState(0);
    const [txHash, setTxHash] = useState(null);
    const [isNeedApprove, setIsNeedApprove] = useState(false);
    const [isNeedApproveUnstake, setIsNeedApproveUnstake] = useState(false);
    const [apr, setApr] = useState(0);
    const [apy, setApy] = useState(0);
    const [isUnstake, setIsUnstake] = useState(false);
    const [stakingAddress, setStakingAddress] = useState(null);
    const [totalStacked, setTotalStacked] = useState(0);
    const [amountUnclaim, setAmountUnclaim] = useState(0)
    const [stakingBalance, setStakingBalance] = useState(0);

    useEffect(async () => {
        if (state && state.web3 && state.account) {
            await init();
            setHash(state.storeHash);
        }
    }, [state, txHash])

    useEffect(() => {
        onGetTotalStacked(totalStacked);
    }, [totalStacked])

    const checkIfNeedEnableContract = async () => {
        try {
            if (state && state.chainId) {
                const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
                setTokenAddress(getAppByChainId(state.chainId).TOKEN_ADDRESS);
                const allowance = new BigNumber(await token.methods.allowance(state.account, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS).call());
                let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
                const dec = await token.methods.decimals().call();
                // balance = balance.div(10**dec);
                setDecimal(dec);

                if (allowance.isGreaterThanOrEqualTo(balance)) {
                    setIsNeedApprove(false)
                } else {
                    setIsNeedApprove(true);
                }
            }
        } catch (e) {
            console.error("check Need Enable", e);
        }
    }

    const checkIfNeedEnableUnstake = async () => {
        try {
            if (state && state.chainId) {
                const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
                const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
                const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, manualStakingAddress);
                const allowance = new BigNumber(await token.methods.allowance(state.account, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS).call());
                let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
                // console.log(allowance.toString(),balance.toString());
                if (allowance.isGreaterThanOrEqualTo(balance)) {
                    setIsNeedApproveUnstake(false)
                } else {
                    setIsNeedApproveUnstake(true);
                }
            }
        } catch (e) {
            console.error("check Need Enable", e);
        }
    }

    const init = async () => {
        await checkIfNeedEnableContract();
        await checkIfNeedEnableUnstake();

        const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
        const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
        const staking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).AUTO_STAKING_ABI, manualStakingAddress);
        const dividendOf = new BigNumber(await staking.methods.dividendOf(state.account).call());

        const dec = await staking.methods.decimals().call();
        const totalSupply = new BigNumber(await staking.methods.totalSupply().call());
        const APR = await staking.methods.APR().call();
        let stkBalance = new BigNumber(await staking.methods.balanceOf(state.account).call());
        stkBalance = stkBalance.dividedBy(10 ** dec);
        setStakingBalance(amountFormat(stkBalance, 4));
        setAmountUnclaim(amountFormat(dividendOf.dividedBy(10 ** dec), 18))
        setApr(APR);
        setStakingAddress(manualStakingAddress);
        setTotalStacked(amountFormat(totalSupply.div(10 ** dec).toString()))
    }

    const onStake = async () => {
        try {
            setIsLoading(true);
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            let amnt = new BigNumber(amount);
            amnt = amnt.multipliedBy(10 ** dec);

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
            await routerStaking.methods.stake(manualStakingAddress, state.account, amnt.toFixed(0)).send({
                from: state.account
            }).on("transactionHash", (hash) => setTxHash(hash))
                .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
        } catch (e) {
            console.error("On Stake", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onUnstake = async () => {
        try {
            setIsLoading(true);
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            let amnt = new BigNumber(amount);
            amnt = amnt.multipliedBy(10 ** dec);

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
            await routerStaking.methods.unstake(manualStakingAddress, state.account, amnt.toFixed(0)).send({
                from: state.account
            }).on("transactionHash", (hash) => setTxHash(hash))
                .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
        } catch (e) {
            console.error("On UnStake", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onEnable = async () => {
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
        let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
        balance = balance.multipliedBy(200);
        setIsLoading(true);
        await token.methods.approve(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS, balance.toFixed(0)).send({
            from: state.account
        }).on("transactionHash", (hash) => setTxHash(hash))
            .on("receipt", (hash) => { setTxHash(hash); setIsLoading(false); });
    }

    const onEnableUnstake = async () => {
        const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
        const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, manualStakingAddress);
        let balance = new BigNumber(await token.methods.balanceOf(state.account).call());
        balance = balance.multipliedBy(200);
        setIsLoading(true);
        await token.methods.approve(getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS, balance.toFixed(0)).send({
            from: state.account
        }).on("transactionHash", (hash) => setTxHash(hash))
            .on("receipt", (hash) => {
                setTxHash(hash);
                setIsLoading(false);
                setIsNeedApproveUnstake(false);
            });
    }
    return (
        <>
            <div className="flex justify-between w-full p-6 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-t-2xl">
                <div className="block">
                    <h3 className="items-center text-xl font-semibold text-gray-900 dark:text-white">Simple Interest <span className="px-2 py-1 text-xs text-white bg-red-700 rounded-lg">Recommended</span></h3>
                    <p className="font-normal text-gray-700 dark:text-gray-100">Earn All Coin, Stake DINO </p>
                </div>
                <div className="">
                    <img src="images/icon-reward/dino-token.png" className="border border-gray-200 rounded-full w-14" alt="" />
                    <img src="images/icon-reward/btc.svg" className="absolute z-10 w-8 ml-8 -mt-5 border border-gray-200 rounded-full" alt="" />
                </div>
            </div>
            <div className="w-full px-6 py-3 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">
                <div className="flex justify-between w-full py-2">
                    <p className="text-gray-900 dark:text-white">APR</p>
                    <p className="text-gray-900 dark:text-white">{apr}%</p>
                </div>
                <div className="flex justify-between w-full py-2">
                    <p className="text-gray-900 dark:text-white">Balance (DINO-SP)</p>
                    <p className="font-bold text-gray-900 dark:text-white">{stakingBalance}</p>
                </div>
                <div className="flex justify-between w-full py-2">
                    <p className="text-gray-900 dark:text-white">Unclaim Reward (BNB)</p>
                    <p className="font-bold text-gray-900 dark:text-white">{amountUnclaim}</p>
                </div>
                <div className="block w-full py-2">
                    <p className="font-semibold text-gray-900 dark:text-white">Start Earning</p>
                    {/* disable button simple interest */}
                    {!isUnstake &&
                        <BtnWalletConnect>
                            <InputNumberWithBalance
                                title={"Amount"}
                                onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                                value={amount}
                                subFix={"DINO"}
                                prefix={"DINO"}
                                address={tokenAddress}
                                key={'dino' + txHash}
                            />
                            {isNeedApprove &&
                                <ButtonLoading
                                    title={"Enable Contract"}
                                    onClick={() => onEnable()}
                                    isLoading={isLoading}
                                />
                            }
                            {!isNeedApprove &&
                                <div className="flex items-center justify-between gap-2">
                                    <ButtonLoading title={"Stake"} isLoading={isLoading} onClick={() => onStake()} />
                                    <ButtonLoadingGray title={"Unstake"} isLoading={isLoading} onClick={() => setIsUnstake(true)} />
                                </div>
                            }
                        </BtnWalletConnect>
                    }
                    {isUnstake &&
                        <BtnWalletConnect>
                            <InputNumberWithBalance
                                title={"Amount"}
                                onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                                value={amount}
                                subFix={"DINO-SP"}
                                prefix={"DINO-SP"}
                                address={stakingAddress}
                                key={'dino-sp' + txHash}
                            />
                            {isNeedApproveUnstake &&
                                <>
                                    <ButtonLoading
                                        title={"Enable Contract"}
                                        onClick={() => onEnableUnstake()}
                                        isLoading={isLoading}
                                    />
                                    <div className="text-center">or</div>
                                    <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                                </>
                            }
                            {!isNeedApproveUnstake &&
                                <div className="flex items-center justify-between gap-2">
                                    <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                                    <ButtonLoading title={"Unstake"} isLoading={isLoading} onClick={() => onUnstake()} />
                                </div>
                            }
                        </BtnWalletConnect>
                    }
                </div>
                <ModalTxHash txHash={txHash} />
            </div>
        </>
    )
}

const EarnManualStaking = () => {
    const [dividendBSAFU, setDividendBSAFU] = useState(0);
    const [dividendBNB, setDividendBNB] = useState(0);
    const [dividendBUSD, setDividendBUSD] = useState(0);
    const [dividendBTCB, setDividendBTCB] = useState(0);
    const [dividendBWETH, setDividendBWETH] = useState(0);
    const [dividendWeboo, setDividendWeboo] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [state, dispatch] = useContext(Context);
    const [hash, setHash] = useState(null);
    const [txHash, setTxHash] = useState(null);
    useEffect(async () => {
        if (state && state.web3 && state.account) {
            await init();
            setHash(state.storeHash);
        }
    }, [state, txHash])

    const getEstimation = async (tokenAddress, amountIn) => {
        const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, tokenAddress);
        const dec = await token.methods.decimals().call()
        const routerDex = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_ABI, getAppByChainId(state.chainId).ROUTER_ADDRESS);
        let path = [
            await routerDex.methods.WETH().call(),
            tokenAddress
        ];
        const est = await routerDex.methods.getAmountsOut(
            amountIn,
            path
        ).call()
        let a = new BigNumber(est[1]);
        return amountFormat((a.div(10 ** dec)).toString(), parseInt(dec));
    }

    const init = async () => {
        try {
            setIsLoading(false);

            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
            const staking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).MANUAL_STAKING_ABI, manualStakingAddress);
            let dividend = new BigNumber(await staking.methods.dividendOf(state.account).call());
            const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
            const dec = await token.methods.decimals().call();
            const divBNB = dividend.div(10 ** dec);
            setDividendBNB(amountFormat(divBNB, 18));
            setDividendBSAFU(await getEstimation(getAppByChainId(state.chainId).TOKEN_ADDRESS, dividend.toString()));
            setDividendBUSD(await getEstimation(getAppByChainId(state.chainId).BUSD_ADDRESS, dividend.toString()));
            setDividendBTCB(await getEstimation(getAppByChainId(state.chainId).BTCB_ADDRESS, dividend.toString()));
            setDividendBWETH(await getEstimation(getAppByChainId(state.chainId).BETH_ADDRESS, dividend.toString()));
            setDividendWeboo(await getEstimation(getAppByChainId(state.chainId).WEBOO_ADDRESS, dividend.toString()));


        } catch (e) {
            console.error("Init Earn Staking", e)
        } finally {
            setIsLoading(false);
        }
    }

    const onClaimReward = async (targetToken) => {
        try {
            setIsLoading(true)
            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
            if (targetToken) {
                await routerStaking.methods.claimToOther(manualStakingAddress, state.account, targetToken).send({
                    from: state.account
                }).on("transactionHash", (hash) => setTxHash(hash))
                    .on("receipt", (hash) => {
                        setTxHash(hash);
                        setIsLoading(false);
                    });
            } else {
                await routerStaking.methods.claimToEth(manualStakingAddress, state.account).send({
                    from: state.account
                }).on("transactionHash", (hash) => setTxHash(hash))
                    .on("receipt", (hash) => {
                        setTxHash(hash);
                        setIsLoading(false);
                    });
            }
        } catch (e) {
            console.error("Claim DINO", e)
        } finally {
            await init();
            // setIsLoading(false);
        }
    }

    return (
        <div className="w-full px-6 py-4 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">
            <div className="block w-full">
                <div className="py-3">
                    <p className="text-sm font-semibold text-gray-900 dark:text-white"><span className="text-green-500">DINO</span> EARNED</p>
                    <div className="flex justify-between item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h6 className="font-semibold text-gray-900 dark:text-white">{dividendBSAFU}</h6>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/dino-token.png" className="w-4 h-4 rounded-full" alt="" />
                                        DINO
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).TOKEN_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* BNB */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBNB}</h6>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/bnb.svg" className="w-4 h-4 rounded-full" alt="" />
                                        BNB
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward()} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* BTCB */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBTCB}</h6>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/btc.svg" className="w-4 h-4 rounded-full" alt="" />
                                        BTCB
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BTCB_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* BUSD */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBUSD}</h6>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/busd.svg" className="w-4 h-4 rounded-full" alt="" />
                                        BUSD
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BUSD_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* BETH */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBWETH}</h6>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/eth.svg" className="w-4 h-4 rounded-full" alt="" />
                                        ETH
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BETH_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                    {/* WEBOO */}
                    <div className="flex justify-between mt-3 item-center">
                        <div className="w-2/3">
                            <div className="block">
                                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendWeboo}</h6>
                                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                                    <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                                        <img src="images/icon-reward/weboo.svg" className="w-4 h-4 rounded-full" alt="" />
                                        WEBOO
                                    </span>
                                </p>
                            </div>
                        </div>
                        <div className="w-1/3">
                            <div className="flex items-center justify-end">
                                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).WEBOO_ADDRESS)} />
                                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <ModalTxHash txHash={txHash} />
            </div>
        </div>
    )
}

export default function Staking() {
    const [totalStacked, setTotalStacked] = useState(0);
    const [totalStackedManual, setTotalStackedManual] = useState(0);
    const [totalContributor, setTotalContributor] = useState(0)
    const [totalContributorManual, setTotalContributorManual] = useState(0)
    const [autoStakingAddress, setAutoStakingAddress] = useState(0);
    const [manualStakingAddress, setManualStakingAddress] = useState(0);
    const [state, dispatch] = useContext(Context);

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ')
    }

    useEffect(async () => {
        if (state && state.web3) {
            const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
            const autoAddress = await routerStaking.methods.autoStakingAddress().call();
            const manualAddress = await routerStaking.methods.manualStakingAddress().call();
            setAutoStakingAddress(autoAddress);
            setManualStakingAddress(manualAddress);

            try {
                const autoContributor = await axios.get(`https://api.covalenthq.com/v1/56/tokens/${autoAddress}/token_holders/?&key=ckey_d2e4a992aa174572b0609a1f235`);
                setTotalContributor(autoContributor.data.data.items.length);
                const manualContributor = await axios.get(`https://api.covalenthq.com/v1/56/tokens/${manualAddress}/token_holders/?&key=ckey_d2e4a992aa174572b0609a1f235`);
                setTotalContributorManual(manualContributor.data.data.items.length);
            } catch (e) {
                console.error(e)
            }

        }
    }, [state])

    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-3xl font-bold leading-snug dark:text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                                Stake <span className="text-green-500">DINO</span> Choose Your Staking Reward Token
                            </div>
                            <div className="grid grid-cols-1 mt-4 md:mt-12 lg:mt-16 md:grid-cols-12">
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Choose Your Staking <br /> Reward Token</p>
                                </div>
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Stake DINO With <br /> Simple Interest Method</p>
                                </div>
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg width="15" height="11" viewBox="0 0 15 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005" stroke="#00B67A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">Stake DINO With <br /> Compounding Method</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 md:col-span-6">
                            <div className="flex justify-center w-full">
                                <img src="./images/stake-illustration.png" className="w-9/12" alt="" />
                            </div>
                        </div>
                    </section>
                </section>
                <Tab.Group>
                    {/* Start Tabs */}
                    <Tab.List className="flex items-center justify-center w-full gap-4 py-5 bg-white dark:bg-gray-900">
                        <Tab
                            className={({ selected }) =>
                                classNames(
                                    'px-5 py-2 rounded-full',
                                    selected
                                        ? 'text-white bg-gray-900 border border-gray-900'
                                        : 'text-gray-900 bg-white border border-gray-900'
                                )
                            }>
                            All
                        </Tab>
                        <Tab className={({ selected }) =>
                            classNames(
                                'px-5 py-2 rounded-full',
                                selected
                                    ? 'text-white bg-gray-900 border border-gray-900'
                                    : 'text-gray-900 bg-white border border-gray-900'
                            )
                        }>
                            Staking
                        </Tab>
                        <Tab className={({ selected }) =>
                            classNames(
                                'px-5 py-2 rounded-full',
                                selected
                                    ? 'text-white bg-gray-900 border border-gray-900'
                                    : 'text-gray-900 bg-white border border-gray-900'
                            )
                        }>
                            Farm
                        </Tab>
                        <Tab className={({ selected }) =>
                            classNames(
                                'px-5 py-2 rounded-full',
                                selected
                                    ? 'text-white bg-gray-900 border border-gray-900'
                                    : 'text-gray-900 bg-white border border-gray-900'
                            )
                        }>
                            Reward
                        </Tab>
                    </Tab.List>
                    {/* End Tabs */}

                    {/* Start Tabs Content */}
                    <Tab.Panels className="p-4 bg-gray-100 dark:bg-gray-800 md:p-10 lg:p-10">
                        {/* Start All Tabs */}
                        <Tab.Panel className="content-center px-1 md:px-20 lg:px-24">
                            <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-2">
                                <div className="w-full mx-auto">
                                    <div className="border-2 border-green-500 rounded-2xl">
                                        {/* <div className="px-6 py-5 mt-4 mb-3 mb-4 ml-4 mr-4 text-base text-center text-red-700 bg-red-600 rounded-lg w-100" role="alert">
                                        <span className="text-white">
                                            Sorry, Undermaintenance
                                        </span>
                                    </div> */}
                                        <div className="w-full bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-2xl">
                                            <div className="flex justify-between w-full p-6 border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-t-2xl">
                                                <div className="block">
                                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Compounding</h3>
                                                    <p className="font-normal text-gray-700 dark:text-gray-100">Automatic Restaking <br /></p>
                                                </div>
                                                <div className="">
                                                    <img src="images/icon-reward/dino-token.png" className="border border-gray-200 rounded-full w-14" alt="" />
                                                    <FiRefreshCw className="absolute z-10 w-8 h-8 p-2 ml-8 -mt-5 text-white bg-green-500 border border-gray-200 rounded-full" alt="" />
                                                </div>
                                            </div>

                                            <div className="w-full px-6 py-3 border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">

                                                <FormCompounding onGetTotalStacked={(e) => setTotalStacked(e)} />
                                            </div>

                                            <div className="w-full px-6 py-4 border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-b-2xl">
                                                <Disclosure>
                                                    {({ open }) => (
                                                        <>
                                                            <div className="flex items-center justify-between">
                                                                <div className="flex items-center justify-start gap-2">
                                                                    <span className="flex items-center gap-1 px-3 py-1 text-sm font-semibold text-green-500 border border-green-500 rounded-full"><FiRefreshCw />Auto</span>
                                                                    <BsQuestionCircle />
                                                                </div>
                                                                <Disclosure.Button className="flex items-center gap-1 font-semibold text-green-500">
                                                                    {open ? "Hide" : "Details"} <BsChevronDown className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-green-500 font-semibold`} />
                                                                </Disclosure.Button>
                                                            </div>
                                                            <Disclosure.Panel className="py-2 text-gray-900">
                                                                <div className="flex items-center justify-between">
                                                                    <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Total Contributors:</h6>
                                                                    <p className="text-sm font-semibold text-gray-900 dark:text-white">{totalContributor}</p>
                                                                </div>
                                                                <div className="flex items-center justify-between">
                                                                    <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Total Staked:</h6>
                                                                    <p className="text-sm font-semibold text-gray-900 dark:text-white">{totalStacked} DINO</p>
                                                                </div>
                                                                <div className="flex items-center justify-between">
                                                                    <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Contract Address:</h6>
                                                                    <p className="text-sm font-semibold text-gray-900 dark:text-white">
                                                                        <CopyAddress addr={autoStakingAddress} />
                                                                    </p>
                                                                </div>
                                                            </Disclosure.Panel>
                                                        </>
                                                    )}
                                                </Disclosure>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full mx-auto">
                                    <div className="border-2 border-gray-300 dark:border-gray-800 rounded-2xl">
                                        <FormManualStaking onGetTotalStacked={(e) => setTotalStackedManual(e)} />
                                        <EarnManualStaking />
                                        <div className="w-full px-6 py-4 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-b-2xl">
                                            <Disclosure>
                                                {({ open }) => (
                                                    <>
                                                        <div className="flex items-center justify-between">
                                                            <div className="flex items-center justify-start gap-2">
                                                                <span className="flex items-center gap-1 px-3 py-1 text-sm font-semibold text-green-500 border border-green-500 rounded-full"><FiRefreshCw />Manual</span>
                                                                <BsQuestionCircle />
                                                            </div>
                                                            <Disclosure.Button className="flex items-center gap-1 font-semibold text-green-500">
                                                                {open ? "Hide" : "Details"} <BsChevronDown className={`${open ? 'transform rotate-180' : ''} w-4 h-4 text-green-500 font-semibold`} />
                                                            </Disclosure.Button>
                                                        </div>
                                                        <Disclosure.Panel className="py-2 text-gray-900">
                                                            <div className="flex items-center justify-between">
                                                                <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Total Contributors:</h6>
                                                                <p className="text-sm font-semibold text-gray-900 dark:text-white">{totalContributorManual}</p>
                                                            </div>
                                                            <div className="flex items-center justify-between">
                                                                <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Total Staked:</h6>
                                                                <p className="text-sm font-semibold text-gray-900 dark:text-white">{totalStackedManual} DINO</p>
                                                            </div>
                                                            <div className="flex items-center justify-between">
                                                                <h6 className="text-sm font-semibold text-gray-900 dark:text-white">Contract Address:</h6>
                                                                <p className="text-sm font-semibold text-gray-900 dark:text-white">
                                                                    <CopyAddress addr={manualStakingAddress} />
                                                                </p>
                                                            </div>
                                                        </Disclosure.Panel>
                                                    </>
                                                )}
                                            </Disclosure>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Panel>
                        {/* End All Tabs */}

                        {/* Start Stake Tabs */}
                        <Tab.Panel className="content-center px-1 md:px-20 lg:px-24">
                            <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-2">
                                <div className="w-full mx-auto">
                                    Staking Card Component Compounding
                                </div>
                                <div className="w-full mx-auto">
                                    Staking Card Component Auto Staking
                                </div>
                            </div>
                        </Tab.Panel>
                        {/* End Stake Tabs */}

                        {/* Start Farm Tabs */}
                        <Tab.Panel className="content-center px-1 md:px-20 lg:px-24">
                            <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-3">
                                {/* Start Card Farm For Component */}
                                <div className="w-full p-2 mx-auto bg-white rounded-2xl">
                                    <Disclosure>
                                        {({ open }) => (
                                            <>
                                                <Disclosure.Button className="flex items-center justify-between w-full px-4 py-2 text-sm font-medium text-left text-green-900 bg-green-100 rounded-lg hover:bg-green-200 focus:outline-none focus-visible:ring focus-visible:ring-green-500 focus-visible:ring-opacity-75">
                                                    <div className="flex items-center justify-between gap-2">
                                                        <ChainIconCircle
                                                            icon1={'/images/icon-reward/dino-token.png'}
                                                            icon2={'/images/icon-reward/bnb.svg'}
                                                            size={40}
                                                            borderWidth={4}
                                                        />

                                                        <div className="block">
                                                            {/* <div className="p-2 mb-2 font-bold text-white bg-red-900 border border-red-800 rounded-lg">
                                                                We are under maintenance !
                                                            </div> */}
                                                            <hr />
                                                            <div className="flex justify-between">
                                                                <strong className={"text-black dark:text-white mr-2"}>
                                                                    {/* {item.name == 'Weboo-BNB' && <small>This Farming Under Maintenance <br />(Migration From v1 to v2)<br /></small>} */}

                                                                    DINO - BNB
                                                                </strong>
                                                                <div className={"flex"}>
                                                                    <div className={"flex flex-row items-center"}>
                                                                        <img
                                                                            src={
                                                                                "https://cdn.webooswap.com/storage/file/file_202201221420076947_1642861207.png"
                                                                            }
                                                                            style={{
                                                                                width: 20,
                                                                                height: 20,
                                                                            }}
                                                                            className={"mr-1"}
                                                                            alt={"Weboo"}
                                                                        />
                                                                    </div>
                                                                    <div className={"flex flex-row items-center"}>
                                                                        <img
                                                                            src={
                                                                                "https://cdn.webooswap.com/storage/file/file_202202040612042699_1643955124.svg"
                                                                            }
                                                                            style={{
                                                                                width: 20,
                                                                                height: 20,
                                                                            }}
                                                                            className={"mr-1"}
                                                                            alt={"Weboo"}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div
                                                                className={"text-gray-900 dark:text-white text-sm"}
                                                            >
                                                                Est. APR: 999,9%
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <RiArrowUpLine
                                                        className={`${open ? 'rotate-180 transform' : ''
                                                            } h-5 w-5 text-green-500`}
                                                    />
                                                </Disclosure.Button>
                                                <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                                    <div className="flex flex-row items-center justify-center w-full align-middle">
                                                        <div className="flex items-center justify-between w-full gap-2">
                                                            <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800">
                                                                <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                                    <div>Plant</div>
                                                                </div>
                                                            </button>
                                                            <div className="text-black dark:text-white">Or</div>
                                                            <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-900 rounded-full hover:bg-gray-800">
                                                                <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                                    <div>Revoke</div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-row items-center justify-center w-full mt-4 align-middle">
                                                        <div className="flex flex-col w-full p-4 bg-white border-2 border-gray-400 rounded-xl dark:bg-gray-900 dark:border-gray-800">
                                                            <div className="mb-2 text-xs text-black dark:text-white">Harvest To</div>
                                                            <div className="flex flex-row items-center justify-between mb-2">
                                                                <div className="flex flex-row items-center text-sm font-bold text-black dark:text-white">
                                                                    <img src="/images/icon-reward/dino-token.png" className="mr-2 rounded-full" alt="WEBOO" style={{ width: '30px', height: '30px' }} />
                                                                    <div className="flex flex-row text-xs">
                                                                        <span className="text-green-900">Est:&nbsp;</span>0.00000000
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                                            <div>Harvest</div>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="relative">
                                                                <div className="w-full mb-2 border-t border-gray-300 dark:border-gray-800">
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-row items-center justify-between mb-2">
                                                                <div className="flex flex-row items-center text-sm font-bold text-black dark:text-white">
                                                                    <img src="/images/icon-reward/bsafu.svg" className="mr-2 rounded-full" alt="WEBOO" style={{ width: '30px', height: '30px' }} />
                                                                    <div className="flex flex-row text-xs">
                                                                        <span className="text-green-900">Est:&nbsp;</span>0.00000000
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                                            <div>Harvest</div>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className="relative">
                                                                <div className="w-full mb-2 border-t border-gray-300 dark:border-gray-800">
                                                                </div>
                                                            </div>
                                                            <div className="flex flex-row items-center justify-between mb-2">
                                                                <div className="flex flex-row items-center text-sm font-bold text-black dark:text-white">
                                                                    <img src="https://cdn.webooswap.com/storage/file/file_202201221441567442_1642862516.svg" className="mr-2 rounded-full" alt="BNB" style={{ width: '30px', height: '30px' }} />
                                                                    <div className="flex flex-row text-xs">
                                                                        <span className="text-green-900">Est:&nbsp;</span>0.00000000
                                                                    </div>
                                                                </div>
                                                                <div>
                                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                                            <div>Harvest</div>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <span className="pb-2 text-xs text-black dark:text-white">* Harvest Dino get 4% more token</span>
                                                            <span className="pb-2 text-xs text-black dark:text-white">** Import farming token into your wallet</span>
                                                        </div>
                                                    </div>

                                                    <div className="flex flex-row items-center justify-between">
                                                        <div class="flex flex-col gap-y-2 py-3">
                                                            <span class="text-gray-900 font-bold dark:text-white text-sm">
                                                                Last Update Dividend
                                                            </span>
                                                            <p class="flex items-center gap-1 text-gray-700 dark:text-gray-200">
                                                                <RiCalendar2Line /> 06/09/2022 15:30
                                                            </p>
                                                        </div>
                                                        <button type="button" class="flex gap-1 items-center rounded-full text-white px-5 py-2 text-sm bg-green-900 hover:bg-gray-800">
                                                            <RiRefreshLine /> Update
                                                        </button>
                                                    </div>
                                                    <div className="flex flex-col gap-1 mt-3">
                                                        <div class="flex flex-row justify-between">
                                                            <span class="text-gray-900 dark:text-white text-sm">WebooBNB-FP</span>
                                                            <span class="text-gray-900 dark:text-white text-sm">0</span>
                                                        </div>
                                                        <div class="flex flex-row justify-between">
                                                            <span class="text-gray-900 dark:text-white text-sm">Pool Share</span>
                                                            <span class="text-gray-900 dark:text-white text-sm">0</span>
                                                        </div>
                                                        <div class="flex flex-row justify-between">
                                                            <span class="text-gray-900 dark:text-white text-sm">Participant</span>
                                                            <span class="text-gray-900 dark:text-white text-sm">9999</span>
                                                        </div>
                                                        <div class="flex items-center justify-between pb-2 text-sm text-black dark:text-white">
                                                            <div>SC Farming Token:</div>
                                                            <div class="flex items-center gap-1">
                                                                <span id="myInput">0xabcd...123</span>
                                                                <button type="button"><RiFileCopy2Line /></button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Disclosure.Panel>
                                            </>
                                        )}
                                    </Disclosure>
                                </div>
                                {/* End Card Farm For Component */}
                            </div>
                        </Tab.Panel>
                        {/* End Farm Tabs */}

                        {/* Start Rewards Tabs */}
                        <Tab.Panel className="content-center px-1 md:px-20 lg:px-24">
                            <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-2">
                                <div className="w-full mx-auto">
                                    <div className="border-2 border-green-500 rounded-2xl">
                                        <div className="w-full bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-2xl">
                                            <div className="flex justify-between px-6 py-6">
                                                <div className="block">
                                                    <h3 className="text-xl font-semibold text-gray-900 dark:text-white">Dino Rewards</h3>
                                                    <p className="font-normal text-gray-700 dark:text-gray-100">Est. APR: 999.99% <br /></p>
                                                </div>
                                                <div className="">
                                                    <img src="images/icon-reward/dino-token.png" className="border border-gray-200 rounded-full w-14" alt="" />
                                                    <RiCoinFill className="absolute z-10 w-8 h-8 p-2 ml-8 -mt-5 text-white bg-green-500 border border-gray-200 rounded-full" alt="" />
                                                </div>

                                            </div>
                                            <div className="flex items-center justify-between px-6 py-3">
                                                <div className="flex items-center justify-start">
                                                    <img src="/images/icon-reward/dino-token.png" className="w-10 h-10" alt="" />
                                                    <div className="block ml-3">
                                                        <h1 className="text-lg font-bold text-black dark:text-white">
                                                            Dino Reward
                                                        </h1>
                                                        <p className="text-gray-700 dark:text-gray-500 text-md">
                                                            <span className="font-light text-green-900">Est</span> 0 DINO
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                            <div>Claim</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between px-6 py-3">
                                                <div className="flex items-center justify-start">
                                                    <img src="/images/icon-reward/bsafu.svg" className="w-10 h-10" alt="" />
                                                    <div className="block ml-3">
                                                        <h1 className="text-lg font-bold text-black dark:text-white">
                                                            BSAFU Reward
                                                        </h1>
                                                        <p className="text-gray-700 dark:text-gray-500 text-md">
                                                            <span className="font-light text-green-900">Est</span> 0 BSAFU
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                            <div>Claim</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between px-6 py-3">
                                                <div className="w-full py-4 font-bold text-center border-t border-b border-gray-400">
                                                    Or Claim With
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between px-6 py-3">
                                                <div className="flex items-center justify-start">
                                                    <img src="/images/icon-reward/bnb.svg" className="w-10 h-10" alt="" />
                                                    <div className="block ml-3">
                                                        <h1 className="text-lg font-bold text-black dark:text-white">
                                                            BNB Reward
                                                        </h1>
                                                        <p className="text-gray-700 dark:text-gray-500 text-md">
                                                            0 BNB
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                            <div>Claim</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between px-6 py-3">
                                                <div className="flex items-center justify-start">
                                                    <img src="/images/icon-reward/busd.svg" className="w-10 h-10" alt="" />
                                                    <div className="block ml-3">
                                                        <h1 className="text-lg font-bold text-black dark:text-white">
                                                            BUSD Reward
                                                        </h1>
                                                        <p className="text-gray-700 dark:text-gray-500 text-md">
                                                            0 BUSD
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                            <div>Claim</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between px-6 py-3">
                                                <div className="flex items-center justify-start">
                                                    <img src="/images/icon-reward/btc.svg" className="w-10 h-10" alt="" />
                                                    <div className="block ml-3">
                                                        <h1 className="text-lg font-bold text-black dark:text-white">
                                                            BTCB Reward
                                                        </h1>
                                                        <p className="text-gray-700 dark:text-gray-500 text-md">
                                                            0 BTCB
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                            <div>Claim</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                            <div className="flex items-center justify-between px-6 py-3">
                                                <div className="flex items-center justify-start">
                                                    <img src="/images/icon-reward/eth.svg" className="w-10 h-10" alt="" />
                                                    <div className="block ml-3">
                                                        <h1 className="text-lg font-bold text-black dark:text-white">
                                                            ETH Reward
                                                        </h1>
                                                        <p className="text-gray-700 dark:text-gray-500 text-md">
                                                            0 ETH
                                                        </p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button type="button" className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full " disabled="">
                                                        <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                                                            <div>Claim</div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="w-full mx-auto">
                                    <div className="border-2 border-green-500 rounded-2xl">
                                        <div className="w-full px-6 py-6 bg-white rounded-2xl">
                                            <div className="font-normal text-green-900">Learn How To Claim</div>
                                            <h1 className="mt-3 text-xl font-extrabold text-black dark:text-white sm:text-2xl lg:text-3xl lg:leading-tight">
                                                How To Claim
                                            </h1>
                                            <div className="my-5 text-black dark:text-white">
                                                <p>1. Connect Wallet First!</p>
                                                <p>2. Make Sure You Have Weboo v2 (CE0)</p>
                                                <p>3. Make Sure Reward is not zero!</p>
                                                <p>4. Select asset you want to claim your reward</p>
                                                <p>5. Click claim, and pay the gas, wait until loading done!</p>
                                                <p>6. Your reward already claim</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Tab.Panel>
                        {/* End Rewards Tabs */}
                    </Tab.Panels>
                    {/* End Tabs Content */}
                </Tab.Group>
            </div >
            <Footer />
        </>
    );
}
