import BigNumber from "bignumber.js"

export const parseFromWei = (amount,decimal=18,precision=8) => {
  let amountBigNumber = new BigNumber(amount)
  const divison = new BigNumber(10**decimal);
  return amountBigNumber.dividedBy(divison).toFixed(precision)
}

export const isGreaterThan = (one,two) => {
  const _one = new BigNumber(one);
  const _two = new BigNumber(two);
  return _two.isGreaterThan(_one);
}