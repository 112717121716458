import React, { useState, useEffect, useContext, useRef } from 'react'
import { Footer, Header } from '../components/dino-ui'
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios'
import { toast } from 'react-toastify'
import { Context } from "../Store";
import { getEnv } from '../libs/Env';
import { ButtonLoading } from '../components/Forms';
import { useParams } from 'react-router-dom';

export default function EditDapps() {
    const { id } = useParams();
    const [state, dispatch] = useContext(Context);
    const [dappsName, setDappsName] = useState('');
    const [slug, setSlug] = useState('');
    const [pin, setPin] = useState('');
    const [tagline, setTagline] = useState('');
    const [shortDescription, setShortDescription] = useState('');
    const [description, setDescription] = useState('');
    const [dappsUrl, setDappsUrl] = useState('');
    const [isTrending, setIsTrending] = useState('0');
    const [isNew, setIsNew] = useState('1');
    const [isAds, setIsAds] = useState('0');
    const [isShow, setIsShow] = useState('1');
    const [position, setPosition] = useState('1');
    const [like, setLike] = useState('0');
    const [bannerOneText, setBannerOneText] = useState('');
    const [bannerTwoText, setBannerTwoText] = useState('');
    const [bannerThreeText, setBannerThreeText] = useState('');
    const [youtubeBannerUrl, setYoutubeBannerUrl] = useState('');
    const [youtubeBannerTitle, setYoutubeBannerTitle] = useState('');
    const [youtubeBannerSlug, setYoutubeBannerSlug] = useState('');
    const [twitterUrl, setTwitterUrl] = useState('');
    const [facebookUrl, setFacebookUrl] = useState('');
    const [githubUrl, setGithubUrl] = useState('');
    const [instagramUrl, setInstagramUrl] = useState('');
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [discordUrl, setDiscordUrl] = useState('');
    const [mediumUrl, setMediumUrl] = useState('');
    const [redditUrl, setRedditUrl] = useState('');
    const [telegramOwner, setTelegramOwner] = useState('');
    const [telegramUrl, setTelegramUrl] = useState('');
    const [coinmarketcapUrl, setCoinmarketcapUrl] = useState('');
    const [coingeckoUrl, setCoingeckoUrl] = useState('');
    const [auditUrl, setAuditUrl] = useState('');
    const [owner, setOwner] = useState('');
    const [formFields, setFormFields] = useState([
        {
            network: '',
            contracts: [
                {
                    contract: '0x',
                    is_token_contract: true
                },
                {
                    contract: '0x',
                    is_token_contract: false
                },
                {
                    contract: '0x',
                    is_token_contract: false
                }
            ]
        }
    ]);
    const [imageIcon, setImageIcon] = useState('');
    const [imageBannerOne, setImageBannerOne] = useState('');
    const [imageBannerTwo, setImageBannerTwo] = useState('');
    const [imageBannerThree, setImageBannerThree] = useState('');

    const [imagePreviewIcon, setImagePreviewIcon] = useState('');
    const [imagePreviewBannerOne, setImagePreviewBannerOne] = useState('');
    const [imagePreviewBannerTwo, setImagePreviewBannerTwo] = useState('');
    const [imagePreviewBannerThree, setImagePreviewBannerThree] = useState('');

    const fileInputRefIcon = useRef();
    const fileInputRefBannerOne = useRef();
    const fileInputRefBannerTwo = useRef();
    const fileInputRefBannerThree = useRef();

    useEffect(() => {
        async function fetchData() {
            const response = await fetch(`http://localhost:8000/api/product/${id}`);
            const data = await response.json();
            setDappsName(data.data.name);
            setSlug(data.data.slug);
            setPin(data.data.pin);
            setTagline(data.data.tagline);
            setDescription(data.data.description);
            setShortDescription(data.data.short_description);
            setDappsUrl(data.data.dapps_url);
            setTelegramOwner(data.data.telegram_owner);
            setBannerOneText(data.data.banner_one_text);
            setBannerTwoText(data.data.banner_two_text);
            setBannerThreeText(data.data.banner_three_text);
            setYoutubeBannerUrl(data.data.youtube_banner_url);
            setYoutubeBannerTitle(data.data.youtube_banner_title);
            setYoutubeBannerSlug(data.data.youtube_banner_slug);
            setTwitterUrl(data.data.twitter_url);
            setFacebookUrl(data.data.facebook_url);
            setGithubUrl(data.data.github_url);
            setInstagramUrl(data.data.instagram_url);
            setYoutubeUrl(data.data.youtube_url);
            setDiscordUrl(data.data.discord_url);
            setMediumUrl(data.data.medium_url);
            setRedditUrl(data.data.reddit_url);
            setTelegramUrl(data.data.telegram_url);
            setCoingeckoUrl(data.data.coingecko_url);
            setCoinmarketcapUrl(data.data.coinmarketcap_url);
            setAuditUrl(data.data.audit_url);
            setImagePreviewIcon(data.data.icon_url);
            setImagePreviewBannerOne(data.data.banner_one_url);
            setImagePreviewBannerTwo(data.data.banner_two_url);
            setImagePreviewBannerThree(data.data.banner_three_url);
            setCategorys(data.data.categorys);
            setNetworks(data.data.networks);
        }
        fetchData();
    }, []);

    const handleFormChange = (event, index) => {
        let data = [...formFields];
        data[index][event.target.name] = event.target.value;
        setFormFields(data);
    }

    const handleContractChange = (event, index, contractIndex) => {
        let data = [...formFields];
        data[index].contracts[contractIndex][event.target.name] = event.target.value;
        setFormFields(data);
    }

    const addNetwork = () => {
        let object = {
            network: '',
            contracts: [
                {
                    contract: '',
                    is_token_contract: false
                }
            ]
        };

        setFormFields([...formFields, object]);
    }

    const [categorys, setCategorys] = useState([
    ]);

    const [networks, setNetworks] = useState([
    ]);

    const [categoriesList, setCategoriesList] = useState([]);
    const [networksList, setNetworksList] = useState([]);

    useEffect(() => {
        async function fetchCategories() {
            try {
                const res = await fetch('https://api-dapps.dinowallet.org/api/category/list');
                const data = await res.json();
                setCategoriesList(data.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchCategories();

        async function fetchNetworks() {
            try {
                const res = await fetch('https://api-dapps.dinowallet.org/api/network/list');
                const data = await res.json();
                setNetworksList(data.data);
            } catch (error) {
                console.error(error);
            }
        }
        fetchNetworks();
    }, []);

    const removeNetwork = (index) => {
        let data = [...formFields];
        data.splice(index, 1);
        setFormFields(data);
    }

    const addContract = (index) => {
        let data = [...formFields];
        data[index].contracts.push({ contract: '', is_token_contract: false });
        setFormFields(data);
    }

    const removeContract = (index, contractIndex) => {
        let data = [...formFields];
        data[index].contracts.splice(contractIndex, 1);
        setFormFields(data);
    }

    const handleFileChangeIcon = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageIcon(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleFileChangeBannerOne = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageBannerOne(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleFileChangeBannerTwo = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageBannerTwo(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleFileChangeBannerThree = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.onloadend = () => {
            setImageBannerThree(reader.result);
        }
        reader.readAsDataURL(file);
    }

    const handleCategoryChange = (event, index) => {
        let data = [...categorys];
        data[index] = categoriesList.find(category => category.name === event.target.value);
        setCategorys(data);
    }

    const handleNetworkChange = (event, index) => {
        let data = [...networks];
        data[index] = networksList.find(network => network.name === event.target.value);
        setNetworks(data);
    }

    const addCategory = () => {
        let object = {
            text: '',
            tiClasses: []
        };

        setCategorys([...categorys, object]);
    }

    const removeCategory = (index) => {
        let data = [...categorys];
        data.splice(index, 1);
        setCategorys(data);
    }

    const addNetworks = () => {
        let object = {
            text: '',
            tiClasses: []
        };

        setNetworks([...networks, object]);
    }

    const removeNetworks = (index) => {
        let data = [...networks];
        data.splice(index, 1);
        setNetworks(data);
    }

    const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

    const client = axios.create({
        baseURL: 'https://api-shill.dinodapps.com/api/pool/create',
    });

    useEffect(async () => {
        if (state && state.web3 && state.account) {
            // console.log(state.account)
            setOwner(state.account);
        }
    }, [state])

    const handleSubmit = async (e) => {
        e.preventDefault();
        // alert(state.web3.utils.toWei(initialReward)) 
        // alert(state.web3.utils.toHex(shillConfigs.configs.receiver))
        try {
            setIsLoadingSubmit(true)
            await axios.put(`http://localhost:8000/api/product/update-data/${id}`, {
                id: "",
                name: dappsName,
                slug: slug,
                tagline: tagline,
                short_description: shortDescription,
                description: description,
                submitted_at: "",
                dapps_url: dappsUrl,
                is_trending: isTrending,
                is_new: isNew,
                is_ads: isAds,
                is_show: isShow,
                position: position,
                like: like,
                icon_url: imageIcon,
                banner_one_url: imageBannerOne,
                banner_two_url: imageBannerTwo,
                banner_three_url: imageBannerThree,
                banner_one_text: bannerOneText,
                banner_two_text: bannerTwoText,
                banner_three_text: bannerThreeText,
                youtube_banner_url: youtubeBannerUrl,
                youtube_banner_title: youtubeBannerTitle,
                youtube_banner_slug: youtubeBannerSlug,
                twitter_url: twitterUrl,
                facebook_url: facebookUrl,
                github_url: githubUrl,
                instagram_url: instagramUrl,
                youtube_url: youtubeUrl,
                discord_url: discordUrl,
                medium_url: mediumUrl,
                reddit_url: redditUrl,
                telegram_url: telegramUrl,
                coinmarketcap_url: coinmarketcapUrl,
                coingecko_url: coingeckoUrl,
                audit_url: auditUrl,
                token_contract: formFields,
                categorys: categorys,
                networks: networks,
                owner: owner,
                telegram_owner: telegramOwner,
                pin: pin,
            }).then(async (res) => {
                toast.success("Success update dapps");
            }).catch(error => {
                console.log(error.response)
                toast.error('Error: ' + error.response.data?.data, {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            });

            // toast.success('Success', {
            //     position: "top-right",
            //     autoClose: 5000,
            //     hideProgressBar: false,
            //     closeOnClick: true,
            //     pauseOnHover: true,
            //     draggable: true,
            //     progress: undefined,
            // });

            // setProjectName('');
            // setProjectLogo('');
            // setPassword('');
            // setTelegramUsername('');
            // setLinkProject('');
            // setOwner('');
            // setInitialReward('');
            // setSlugPool('');
            // setRewardDapps('');
            // setRewardPage('');
            // setStartDate(new Date());


        } catch (e) {
            console.error("Submit Err", e)
        } finally {
            setIsLoadingSubmit(false)
        }

        // addPosts(projectName, projectLogo, password, telegramUsername, linkProject, owner, initialReward, slugPool, rewardDapps, rewardPage, startDate);
    }

    return (
        <>
            <Header />
            <section className="relative py-4 overflow-hidden bg-gray-100 dark:bg-gray-900 md:py-20 lg:py-16 font-body">
                <section className="items-center gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero">
                    <div className="col-span-1 md:col-span-6">
                        <div className="mb-4 text-3xl font-bold leading-snug dark:text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                            Edit <span className="text-green-500">dApps</span>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="flex flex-col px-8 pt-6 pb-8 my-2 mb-4 bg-white rounded shadow-md">
                        <label className="mb-6 block text-2xl font-semibold text-[#07074D]">
                            Project Information
                        </label>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                    Icon<span className="text-red-500">*</span>
                                </label>

                                <div className="pt-2 mb-6">
                                    <div className="mb-3">
                                        <input type='file' ref={fileInputRefIcon} onChange={handleFileChangeIcon} name="icon_url" id="icon_url" className="sr-only" />
                                        <label
                                            htmlFor="icon_url"
                                            className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                        >
                                            <div>
                                                <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                                                    Drop files here
                                                </span>
                                                <span className="mb-2 block text-base font-medium text-[#6B7280]">
                                                    Or
                                                </span>
                                                <span
                                                    className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]"
                                                >
                                                    Browse
                                                </span>
                                            </div>
                                        </label>

                                        {imagePreviewIcon !== '' ? <div className="p-3 mt-3 bg-white border-2 rounded-lg">
                                            <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                                Old Images
                                            </label>
                                            <img src={'http://localhost:8000/images/icon-dapps/' + imagePreviewIcon} className="w-24 h-24" alt="img" />
                                        </div> : <>
                                        </>}
                                        {imageIcon !== '' ? <div className="p-3 mt-3 bg-white border-2 rounded-lg">
                                            <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                                Preview Image
                                            </label>
                                            <img src={imageIcon} className="w-24 h-24" alt="img" />
                                        </div> : <>
                                        </>}
                                    </div>
                                    <p className="text-xs italic text-grey-dark">Your dapps Icon, Format Allowed: png/webp, 120x120 pixel</p>
                                </div>
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="owner">
                                    Owner <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 bg-gray-300 border rounded appearance-none text-grey-darker border-grey-lighter" id="owner" type="text" placeholder="0x" value={state.account} onChange={(e) => setOwner(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Owner dapps, Based on address who already Connect Wallet</p>
                                <label className="block mt-6 mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="telegram-owner">
                                    Telegram Owner (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="telegram-owner" type="text" placeholder="@elonmusk" value={telegramOwner} onChange={(e) => setTelegramOwner(e.target.value)} />
                                <p className="text-xs italic text-grey-dark">Owners Telegram, For information about project</p>
                                <label className="block mt-6 mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="pin">
                                    Pin <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="pin" type="text" placeholder="Your Pin" value={pin} onChange={(e) => setPin(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Pin for update data, space not allowed</p>
                            </div>
                        </div>
                        <div className="mt-4 mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="dapps-name">
                                    dApps Name <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="dapps-name" type="text" placeholder="Dino dApps" value={dappsName} onChange={(e) => setDappsName(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Your dApps Name, Example: Dino Dapps</p>
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="slug-pool">
                                    Slug Pool <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="slug-pool" type="text" placeholder="dino-dapps" value={slug} onChange={(e) => setSlug(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Slug for url, Example: dinodapps or dino-dapps , space not allowed</p>
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="tagline">
                                    Tagline <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="tagline" type="text" placeholder="Dino dApps - dApps Platform" value={tagline} onChange={(e) => setTagline(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Tagline dapps, Based on project tagline</p>
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="short-description">
                                    Short Description <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="short-description" type="text" placeholder="Short Description about your project" value={shortDescription} onChange={(e) => setShortDescription(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Short Description dapps, Tell the world about your project in short description</p>
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="description">
                                    Description <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="description" type="text" placeholder="Dino dApps" value={description} onChange={(e) => setDescription(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Description dapps, Tell the world about your project description</p>
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="dapps-url">
                                    Dapps URL <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="dapps-url" type="text" placeholder="https://yourwebsite.domain" value={dappsUrl} onChange={(e) => setDappsUrl(e.target.value)} required />
                                <p className="text-xs italic text-grey-dark">Your dapps website url, Example: https://dinodapps.com</p>
                            </div>
                        </div>
                        <div className="hidden mb-6 -mx-3 md:hidden">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="is_trending">
                                    Is Trending <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="is_trending" type="text" placeholder="Dino dApps" value={isTrending} onChange={(e) => setIsTrending(e.target.value)} required />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="is_new">
                                    Is New <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="is_new" type="text" placeholder="shill-test-and-get-free-dino" value={isNew} onChange={(e) => setIsNew(e.target.value)} required />
                            </div>
                        </div>
                        <div className="hidden mb-6 -mx-3 md:hidden">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="is_ads">
                                    Is Ads <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="is_ads" type="text" placeholder="Dino dApps" value={isAds} onChange={(e) => setIsAds(e.target.value)} required />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="is_show">
                                    Is Show <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="is_show" type="text" placeholder="shill-test-and-get-free-dino" value={isShow} onChange={(e) => setIsShow(e.target.value)} required />
                            </div>
                        </div>
                        <div className="hidden mb-6 -mx-3 md:hidden">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="position">
                                    Position <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="position" type="text" placeholder="Dino dApps" value={position} onChange={(e) => setPosition(e.target.value)} required />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="like">
                                    Like <span className="text-red-500">*</span>
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="like" type="text" placeholder="shill-test-and-get-free-dino" value={like} onChange={(e) => setLike(e.target.value)} required />
                            </div>
                        </div>
                        <label className="mb-6 block text-2xl font-semibold text-[#07074D]">
                            dApps Media
                        </label>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 md:w-1/3">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="banner-one-url">
                                    Screenshot 1 <span className="text-red-500">*</span>
                                </label>
                                <div className="pt-4 mb-6">
                                    <div className="mb-3">
                                        <input type='file' ref={fileInputRefBannerOne} onChange={handleFileChangeBannerOne} name="banner_one_url" id="banner_one_url" className="sr-only" />
                                        <label
                                            htmlFor="banner_one_url"
                                            className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                        >
                                            <div>
                                                <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                                                    Drop files here
                                                </span>
                                                <span className="mb-2 block text-base font-medium text-[#6B7280]">
                                                    Or
                                                </span>
                                                <span
                                                    className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]"
                                                >
                                                    Browse
                                                </span>
                                            </div>
                                        </label>

                                        {imagePreviewBannerOne !== '' ? <div className="p-3 mt-3 bg-white border-2 rounded-lg">
                                            <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                                Old Image
                                            </label>
                                            <img src={'http://localhost:8000/images/ss-dapps/' + imagePreviewBannerOne} className="w-48 h-24 mt-2 rounded-md" alt="img" />
                                        </div> : <>
                                        </>}
                                        {imageBannerOne !== '' ? <div className="p-3 mt-3 bg-white border-2 rounded-lg">
                                            <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                                Preview Image
                                            </label>
                                            <img src={imageBannerOne} className="w-48 h-24 mt-2 rounded-md" alt="img" />
                                        </div> : <>
                                        </>}
                                    </div>
                                    <p className="text-xs italic text-grey-dark">Your Banner 1, Format Allowed: png/webp, 120x120 pixel</p>
                                </div>

                                <div className="mt-3 mb-6">
                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="banner-one-text">
                                        Screenshot 1 Text <span className="text-red-500">*</span>
                                    </label>
                                    <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="banner-one-text" type="text" placeholder="Homepage UI" value={bannerOneText} onChange={(e) => setBannerOneText(e.target.value)} required />
                                </div>
                            </div>

                            <div className="px-3 md:w-1/3">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="banner-two-url">
                                    Screenshot 2 <span className="text-red-500">*</span>
                                </label>
                                <div className="pt-4 mb-6">
                                    <div className="mb-3">
                                        <input type='file' ref={fileInputRefBannerTwo} onChange={handleFileChangeBannerTwo} name="banner_two_url" id="banner_two_url" className="sr-only" />
                                        <label
                                            htmlFor="banner_two_url"
                                            className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                        >
                                            <div>
                                                <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                                                    Drop files here
                                                </span>
                                                <span className="mb-2 block text-base font-medium text-[#6B7280]">
                                                    Or
                                                </span>
                                                <span
                                                    className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]"
                                                >
                                                    Browse
                                                </span>
                                            </div>
                                        </label>

                                        {imagePreviewBannerTwo !== '' ? <div className="p-3 mt-3 bg-white border-2 rounded-lg">
                                            <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                                Old Image
                                            </label>
                                            <img src={'http://localhost:8000/images/ss-dapps/' + imagePreviewBannerTwo} className="w-48 h-24 mt-2 rounded-md" alt="img" />
                                        </div> : <>
                                        </>}
                                        {imageBannerTwo !== '' ? <div className="p-3 mt-3 bg-white border-2 rounded-lg">
                                            <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                                Preview Image
                                            </label>
                                            <img src={imageBannerTwo} className="w-48 h-24 mt-2 rounded-md" alt="img" />
                                        </div> : <>
                                        </>}
                                    </div>
                                    <p className="text-xs italic text-grey-dark">Your Banner 2, Format Allowed: png/webp</p>
                                </div>

                                <div className="mt-3 mb-6">
                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="banner-two-text">
                                        Screenshot 2 Text <span className="text-red-500">*</span>
                                    </label>
                                    <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="banner-two-text" type="text" placeholder="Staking UI" value={bannerTwoText} onChange={(e) => setBannerTwoText(e.target.value)} required />
                                </div>
                            </div>

                            <div className="px-3 md:w-1/3">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="banner-three-url">
                                    Screenshot 3 <span className="text-red-500">*</span>
                                </label>
                                <div className="pt-4 mb-6">
                                    <div className="mb-3">
                                        <input type='file' ref={fileInputRefBannerThree} onChange={handleFileChangeBannerThree} name="banner_three_url" id="banner_three_url" className="sr-only" />
                                        <label
                                            htmlFor="banner_three_url"
                                            className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                        >
                                            <div>
                                                <span className="mb-2 block text-xl font-semibold text-[#07074D]">
                                                    Drop files here
                                                </span>
                                                <span className="mb-2 block text-base font-medium text-[#6B7280]">
                                                    Or
                                                </span>
                                                <span
                                                    className="inline-flex rounded border border-[#e0e0e0] py-2 px-7 text-base font-medium text-[#07074D]"
                                                >
                                                    Browse
                                                </span>
                                            </div>
                                        </label>

                                        {imagePreviewBannerThree !== '' ? <div className="p-3 mt-3 bg-white border-2 rounded-lg">
                                            <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                                Old Image
                                            </label>
                                            <img src={'http://localhost:8000/images/ss-dapps/' + imagePreviewBannerThree} className="w-48 h-24 mt-2 rounded-md" alt="img" />
                                        </div> : <>
                                        </>}
                                        {imageBannerThree !== '' ? <div className="p-3 mt-3 bg-white border-2 rounded-lg">
                                            <label className="block text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="icon-url">
                                                Preview Image
                                            </label>
                                            <img src={imageBannerThree} className="w-48 h-24 mt-2 rounded-md" alt="img" />
                                        </div> : <>
                                        </>}
                                    </div>
                                    <p className="text-xs italic text-grey-dark">Your Banner 3, Format Allowed: png/webp</p>
                                </div>

                                <div className="mt-3 mb-6">
                                    <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="banner-three-text">
                                        Screenshot 3 Text <span className="text-red-500">*</span>
                                    </label>
                                    <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="banner-three-text" type="text" placeholder="Farming UI" value={bannerThreeText} onChange={(e) => setBannerThreeText(e.target.value)} required />
                                </div>
                            </div>
                        </div>
                        <label className="mb-6 block text-2xl font-semibold text-[#07074D]">
                            Youtube Media
                        </label>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 md:w-1/3">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="youtube-banner-url">
                                    Youtube Trailer URL (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="youtube-banner-url" type="text" placeholder="https://www.youtube.com/watch?v=videosid" value={youtubeBannerUrl} onChange={(e) => setYoutubeBannerUrl(e.target.value)} />
                            </div>

                            <div className="px-3 mb-6 md:w-1/3 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="youtube-banner-title">
                                    Youtube Trailer Title (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="youtube-banner-title" type="text" placeholder="Dino dApps Trailer" value={youtubeBannerTitle} onChange={(e) => setYoutubeBannerTitle(e.target.value)} />
                            </div>

                            <div className="px-3 md:w-1/3">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="youtube-banner-slug">
                                    Youtube Trailer Slug (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="youtube-banner-slug" type="text" placeholder="Dino Trailer" value={youtubeBannerSlug} onChange={(e) => setYoutubeBannerSlug(e.target.value)} />
                            </div>
                        </div>
                        <label className="mb-6 block text-2xl font-semibold text-[#07074D]">
                            Social Media Url
                        </label>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="twitter-url">
                                    Twitter Url (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="twitter-url" type="text" placeholder="https://twitter.com" value={twitterUrl} onChange={(e) => setTwitterUrl(e.target.value)} />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="facebook-url">
                                    Facebook URL (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="facebook-url" type="text" placeholder="https://facebook.com" value={facebookUrl} onChange={(e) => setFacebookUrl(e.target.value)} />
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="github-url">
                                    Github Url (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="github-url" type="text" placeholder="https://github.com" value={githubUrl} onChange={(e) => setGithubUrl(e.target.value)} />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="instagram-url">
                                    Instagram URL (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="instagram-url" type="text" placeholder="hhttps://instagram.com" value={instagramUrl} onChange={(e) => setInstagramUrl(e.target.value)} />
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="youtube-url">
                                    Youtube Url (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="youtube-url" type="text" placeholder="https://youtube.com/" value={youtubeUrl} onChange={(e) => setYoutubeUrl(e.target.value)} />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="discord-url">
                                    Discord URL (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="discord-url" type="text" placeholder="https://discord.gg" value={discordUrl} onChange={(e) => setDiscordUrl(e.target.value)} />
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="medium-url">
                                    Medium Url (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="medium-url" type="text" placeholder="https://medium.com" value={mediumUrl} onChange={(e) => setMediumUrl(e.target.value)} />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="reddit-url">
                                    Reddit URL (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="reddit-url" type="text" placeholder="https://reddit.com" value={redditUrl} onChange={(e) => setRedditUrl(e.target.value)} />
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="telegram-url">
                                    Telegram Url (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="telegram-url" type="text" placeholder="https://t.me" value={telegramUrl} onChange={(e) => setTelegramUrl(e.target.value)} />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="coinmarketcap-url">
                                    Coinmarketcap URL (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="coinmarketcap-url" type="text" placeholder="https://coinmarketcap.com/currencies/dino" value={coinmarketcapUrl} onChange={(e) => setCoinmarketcapUrl(e.target.value)} />
                            </div>
                        </div>
                        <div className="mb-6 -mx-3 md:flex">
                            <div className="px-3 mb-6 md:w-1/2 md:mb-0">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="coingecko-url">
                                    Coingecko Url (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red" id="coingecko-url" type="text" placeholder="https://www.coingecko.com/en/coins/bnb" value={coingeckoUrl} onChange={(e) => setCoingeckoUrl(e.target.value)} />
                            </div>

                            <div className="px-3 md:w-1/2">
                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="audit-url">
                                    Audit URL (Optional)
                                </label>
                                <input className="block w-full px-4 py-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-grey-lighter" id="audit-url" type="text" placeholder="https://blocksafu.com/project-detail/0x" value={auditUrl} onChange={(e) => setAuditUrl(e.target.value)} />
                            </div>
                        </div>
                        <label className="mb-6 block text-2xl font-semibold text-[#07074D]">
                            Contract Information
                        </label>
                        {formFields.map((form, index) => {
                            return (
                                <div key={index} className="p-4 border border-gray-400 rounded-md">
                                    <div className="gap-4 mb-4">
                                        <div className="flex items-center justify-between w-full">
                                            <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="coinmarketcap-url">
                                                Contract Information
                                            </label>
                                            <button className='px-3 py-1 text-white bg-red-500 rounded-md' onClick={() => removeNetwork(index)}>Remove Network</button>
                                        </div>
                                        <input
                                            name='network'
                                            className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red"
                                            placeholder='Network'
                                            onChange={event => handleFormChange(event, index)}
                                            value={form.network}
                                        />
                                        {form.contracts.map((contract, contractIndex) => {
                                            return (
                                                <div key={contractIndex}>
                                                    <div className="p-4 mt-2 bg-gray-300 rounded-md">
                                                        <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="coinmarketcap-url">
                                                            Contract Address
                                                        </label>
                                                        <input
                                                            name='contract'
                                                            className="block w-full px-4 py-3 mb-3 border rounded appearance-none bg-grey-lighter text-grey-darker border-red"
                                                            placeholder='Contract Address'
                                                            onChange={event => handleContractChange(event, index, contractIndex)}
                                                            value={contract.contract}
                                                        />
                                                        <select
                                                            name='is_token_contract'
                                                            className='mr-1'
                                                            onChange={event => handleContractChange(event, index, contractIndex)}
                                                            value={contract.is_token_contract}
                                                        >
                                                            <option value={true}>Yes</option>
                                                            <option value={false}>No</option>
                                                        </select>
                                                        Is Token Contract
                                                    </div>
                                                    {contractIndex > 0 && (
                                                        <button type="button" className='px-3 py-1 mt-4 text-white bg-red-500 rounded-md' onClick={() => removeContract(index, contractIndex)}>Remove Contract</button>
                                                    )}
                                                </div>
                                            );
                                        })}
                                    </div>
                                    <button type="button" className='px-3 py-1 text-white bg-gray-800 rounded-md' onClick={() => addContract(index)}>Add Contract</button>
                                </div>
                            );
                        })}
                        <button type="button" className='px-3 py-1 mt-3 text-white bg-green-500 rounded-md' onClick={addNetwork}>Add More Network</button>
                        <div className="mt-6 mb-6 -mx-3 md:flex">
                            <div className="w-1/2 px-3">
                                <label className="mb-5 mt-5 block text-2xl font-semibold text-[#07074D]">
                                    Category Information
                                </label>
                                <div className='p-4 border border-gray-400 rounded-md'>
                                    {categorys.map((category, index) => {
                                        return (
                                            <div key={index}>
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="coinmarketcap-url">
                                                    Category {index + 1}
                                                </label>
                                                <div className="relative flex items-center justify-between mb-3">
                                                    <select
                                                        name='category'
                                                        className="block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker"
                                                        onChange={event => handleCategoryChange(event, index)}
                                                        value={category.name}
                                                        defaultValue={category.name}
                                                        required
                                                    >
                                                        <option value="">--Please choose an option--</option>
                                                        {categoriesList.map((category, index) => {
                                                            return (
                                                                <option key={index} value={category.name}>
                                                                    {category.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker">
                                                        <svg className="justify-end w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>
                                                </div>
                                                {index > 0 && (
                                                    <button type="button" className='px-3 py-1 mb-3 text-white bg-red-500 rounded-md' onClick={() => removeCategory(index)}>Remove</button>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="flex justify-end">
                                    <button type="button" className='px-3 py-1 mt-3 text-white bg-gray-800 rounded-md' onClick={addCategory}>Add Category</button>
                                </div>
                            </div>
                            <div className="w-1/2 px-3">
                                <label className="mb-5 mt-5 block text-2xl font-semibold text-[#07074D]">
                                    Network Information
                                </label>
                                <div className='p-4 border border-gray-400 rounded-md'>
                                    {networks.map((network, index) => {
                                        return (
                                            <div key={index}>
                                                <label className="block mb-2 text-xs font-bold tracking-wide uppercase text-grey-darker" htmlFor="coinmarketcap-url">
                                                    Network {index + 1}
                                                </label>
                                                <div className="relative flex items-center justify-between mb-3">
                                                    <select
                                                        name='network'
                                                        className='block w-full px-4 py-3 pr-8 border rounded appearance-none bg-grey-lighter border-grey-lighter text-grey-darker'
                                                        onChange={event => handleNetworkChange(event, index)}
                                                        value={network.name}
                                                        required
                                                    >
                                                        <option value="">--Please choose an option--</option>
                                                        {networksList.map((network, index) => {
                                                            return (
                                                                <option key={index} value={network.name}>
                                                                    {network.name}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                    <div className="absolute right-0 flex items-center px-2 pointer-events-none pin-y pin-r text-grey-darker">
                                                        <svg className="justify-end w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" /></svg>
                                                    </div>
                                                </div>
                                                {index > 0 && (
                                                    <button type="button" className='px-3 py-1 mb-3 text-white bg-red-500 rounded-md' onClick={() => removeNetworks(index)}>Remove</button>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>
                                <div className="flex justify-end">
                                    <button type="button" className='px-3 py-1 mt-3 text-white bg-gray-800 rounded-md' onClick={addNetworks}>Add Network</button>
                                </div>
                            </div>
                        </div>


                        <div className="w-32 mt-5">
                            <ButtonLoading isLoading={isLoadingSubmit} title={"Register dApps"} type={"submit"}>
                            </ButtonLoading>
                        </div>
                    </form>
                </section>
            </section>
            <Footer />
        </>
    )
}