import React from "react";

export const Banner = () => {
  return (
    <section className="py-2 bg-white dark:bg-gray-900 md:py-8 lg:py-10">
      <div className="flex flex-col py-2 mx-2 lg:flex-row sm:mx-12 md:mx-24 lg:mx-32">
        <div className="relative block bg-white shadow-2xl carousel md:hidden lg:hidden">
          <a href="https://earn.webooswap.com" className="relative w-full overflow-hidden carousel-inner">
            <input
              className="hidden carousel-open"
              type="radio"
              id="carousel-1"
              name="carousel"
              aria-hidden="true"
              hidden=""
              checked="checked"
              onChange={() => {}}
            />
            <div
              className="absolute opacity-0 carousel-item"
              style={{ height: "200px" }}
            >
              <div className="block w-full h-full text-5xl text-center text-white bg-indigo-500">
                <img src="./images/sakura-farming.webp" alt="" />
              </div>
            </div>
            {/* <label
              htmlFor="carousel-3"
              className="absolute inset-y-0 left-0 z-10 hidden w-10 h-10 my-auto ml-2 text-3xl font-bold leading-tight text-center text-black bg-white rounded-full cursor-pointer prev control-1 md:ml-10 hover:text-white hover:bg-green-700"
            >
              ‹
            </label>
            <label
              htmlFor="carousel-2"
              className="absolute inset-y-0 right-0 z-10 hidden w-10 h-10 my-auto mr-2 text-3xl font-bold leading-tight text-center text-black bg-white rounded-full cursor-pointer next control-1 md:mr-10 hover:text-white hover:bg-green-700"
            >
              ›
            </label> */}

            <input
              className="hidden carousel-open"
              type="radio"
              id="carousel-2"
              name="carousel"
              aria-hidden="true"
              hidden=""
              onChange={() => {}}
            />
            <div
              className="absolute opacity-0 carousel-item"
              style={{ height: "50vh" }}
            >
              <div className="block w-full h-full text-5xl text-center text-white bg-orange-500">
                Slide 2
              </div>
            </div>
            <label
              htmlFor="carousel-1"
              className="absolute inset-y-0 left-0 z-10 hidden w-10 h-10 my-auto ml-2 text-3xl font-bold leading-tight text-center text-black bg-white rounded-full cursor-pointer prev control-2 md:ml-10 hover:text-white hover:bg-green-700"
            >
              ‹
            </label>
            <label
              htmlFor="carousel-3"
              className="absolute inset-y-0 right-0 z-10 hidden w-10 h-10 my-auto mr-2 text-3xl font-bold leading-tight text-center text-black bg-white rounded-full cursor-pointer next control-2 md:mr-10 hover:text-white hover:bg-green-700"
            >
              ›
            </label>

            <input
              className="hidden carousel-open"
              type="radio"
              id="carousel-3"
              name="carousel"
              aria-hidden="true"
              onChange={() => {}}
              hidden=""
            />
            <div
              className="absolute opacity-0 carousel-item"
              style={{ height: "50vh" }}
            >
              <div className="block w-full h-full text-5xl text-center text-white bg-green-500">
                Slide 3
              </div>
            </div>
            <label
              htmlFor="carousel-2"
              className="absolute inset-y-0 left-0 z-10 hidden w-10 h-10 my-auto ml-2 text-3xl font-bold leading-tight text-center text-black bg-white rounded-full cursor-pointer prev control-3 md:ml-10 hover:text-white hover:bg-green-700"
            >
              ‹
            </label>
            <label
              htmlFor="carousel-1"
              className="absolute inset-y-0 right-0 z-10 hidden w-10 h-10 my-auto mr-2 text-3xl font-bold leading-tight text-center text-black bg-white rounded-full cursor-pointer next control-3 md:mr-10 hover:text-white hover:bg-green-700"
            >
              ›
            </label>
          </a>
        </div>
        <div className="hidden w-full px-8 py-8 text-white bg-red-800 rounded-md md:py-12 lg:py-16 md:px-12 lg:px-16 md:block lg:block">
          <div className="flex flex-col lg:flex-row">
            <div className="w-full md:w-1/2 lg:w-1/2">
              <h1 className="mt-3 text-2xl font-extrabold text-white sm:text-3xl lg:text-4xl lg:leading-tight">
                WebooSwap Reward
                <br /> Track.
              </h1>
              <div className="my-6">
                (Please make sure you are visiting
                https://earn.webooswap.com/reward)
              </div>
              <div className="flex gap-6 md:gap-8 lg:gap-12 flex:col">
                <div>
                  <h1 className="mt-3 font-extrabold text-white sm:text-2xl lg:text-3xl text-1xl lg:leading-tight">
                    Yield
                  </h1>
                  <div className="my-1">Farming</div>
                </div>
                <div>
                  <h1 className="mt-3 font-extrabold text-white sm:text-2xl lg:text-3xl text-1xl lg:leading-tight">
                    Multi
                  </h1>
                  <div className="my-1">Reflectionary</div>
                </div>
                <div>
                  <h1 className="mt-3 font-extrabold text-white sm:text-2xl lg:text-3xl text-1xl lg:leading-tight">
                    Swap
                  </h1>
                  <div className="my-1">PlathtmlForm</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
