import React, { Fragment, useState, useEffect, useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Context } from "../Store";
import { Footer, Header } from '../components/dino-ui'
import axios from 'axios'

export default function Shill() {
    const [state, dispatch] = useContext(Context);
    const [storeHash, setStoreHash] = useState(null);
    const [poolList, setPoolList] = useState([]);

    useEffect(async () => {
        await axios.get('https://api-shill.dinodapps.com/api/pool/list')
            .then(res => {
                console.log(res.data.result);
                setPoolList(res.data.result);
            })
    }, [])

    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-100 dark:bg-gray-900 md:py-20 lg:py-20 font-body">
                    <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-3xl font-bold leading-snug dark:text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                                Shill dApps to get <span className="text-green-500">DINO</span> as Reward
                            </div>
                            <div className="grid grid-cols-1 mt-4 md:mt-12 lg:mt-16 md:grid-cols-12">
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg
                                        width="15"
                                        height="11"
                                        viewBox="0 0 15 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005"
                                            stroke="#00B67A"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">
                                        Share and get <br /> Reward Token
                                    </p>
                                </div>
                                <div className="inline-flex items-center col-span-1 mb-3 md:col-span-6">
                                    <svg
                                        width="15"
                                        height="11"
                                        viewBox="0 0 15 11"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M13.1328 1.30005L4.88281 9.55005L1.13281 5.80005"
                                            stroke="#00B67A"
                                            strokeWidth="2"
                                            strokeLinecap="round"
                                            strokeLinejoin="round"
                                        ></path>
                                    </svg>
                                    <p className="ml-4 font-normal text-dark-2 dark:text-white">
                                        Shill to earn <br /> concept
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-1 md:col-span-6">
                            <div className="flex justify-center w-full">
                                <img
                                    src="./images/shill-illustration.png"
                                    className="w-9/12"
                                    alt=""
                                />
                            </div>
                        </div>
                    </section>
                </section>

                <div className="content-center px-1 py-10 bg-gray-100 md:px-20 lg:px-28">
                    <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-3">
                        {poolList.map((pool, index) => {
                            return (
                                <div className="w-full mx-auto">
                                    <div className="rounded-3xl">
                                        <div className="w-full bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-2xl">
                                            <div className="w-full p-6 border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-t-2xl" key={index}>
                                                <div className="block">
                                                    <img src={pool.projectLogo} className="w-16 h-16 border border-green-200 rounded-full" alt="" />
                                                    <h3 className="mt-3 text-2xl font-semibold text-gray-900 dark:text-white">
                                                        {pool.projectName}
                                                    </h3>
                                                    {pool.statusPool === 'upcoming' ? (
                                                        <p className="font-bold text-orange-500 capitalize text-md dark:text-white">{pool.statusPool}</p>
                                                    ) : pool.statusPool === 'live' ? (
                                                        <p className="font-bold text-green-500 capitalize text-md dark:text-white">{pool.statusPool}</p>
                                                    ) : pool.statusPool === 'canceled' ? (
                                                        <p className="font-bold text-gray-500 capitalize text-md dark:text-white">{pool.statusPool}</p>
                                                    ) : (
                                                        <p className="font-bold text-red-700 capitalize text-md dark:text-white">{pool.statusPool}</p>
                                                    )}
                                                    <div className="mt-4">
                                                        <h6>dApps Visit Commision</h6>
                                                        <h4 className="text-xl font-bold text-green-500">{pool.rewardDapps}</h4>
                                                    </div>
                                                    <div className="mt-4">
                                                        <h6>Store Visit Commision</h6>
                                                        <h4 className="text-xl font-bold text-green-500">{pool.rewardPage}</h4>
                                                    </div>
                                                    <div className="flex justify-between w-full mt-4">
                                                        <div>Total Reward</div>
                                                        <div>{pool.initialReward}</div>
                                                    </div>
                                                    <div className="w-full mt-4 border-t border-gray-300">
                                                        <div className="flex justify-end w-full mt-6">
                                                            <NavLink to={'/shill-detail/' + pool.slugPool} className="px-4 py-2 text-green-600 bg-green-200 rounded-xl">View Shill</NavLink>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
