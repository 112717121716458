import axios from 'axios';
import React, { Fragment, useContext, useState } from 'react'
import { useEffect } from 'react';
import { RiDiscordLine, RiFacebookBoxLine, RiFacebookLine, RiFileCopy2Line, RiFileCopyFill, RiGithubLine, RiGlobalLine, RiInstagramLine, RiMediumLine, RiRedditLine, RiTelegramLine, RiTwitterLine, RiYoutubeLine } from 'react-icons/ri'
import { useParams } from 'react-router-dom';
import { Footer, Header } from '../components/dino-ui'
import { Context } from "../Store";

export default function ShillDetail() {
    let { slug } = useParams();
    const [detailShill, setDetailShill] = useState([]);
    const [slugs, setSlugs] = useState('');
    const [projectDetail, setProjectDetail] = useState('');
    const [affiliateDetail, setAffiliateDetail] = useState('');
    const [state, dispatch] = useContext(Context);

    useEffect(async () => {
        if (state && state.web3 && state.account) {
            // console.log(state.account)
            axios.get('https://api-shill.dinodapps.com/api/afiliate/detail/' + state.account + '/' + slug)
                .then(res => {
                    setAffiliateDetail(res.data.result)
                    // console.log('Ref: ', res.data.result?.referral)
                })
        }

    }, [state])

    useEffect(() => {
        axios.get('https://api-shill.dinodapps.com/api/pool/detail/' + slug)
            .then(res => {
                // console.log(res.data.result)
                setDetailShill(res.data.result);
                let slugFinal
                slugFinal = (res.data.result.linkProject).replace("https://dinodapps.com/dapps-detail/", "");
                // console.log(slugFinal)
                setSlugs(slugFinal)
                axios.get('https://api-dapps.dinowallet.org/api/product/details/' + slugFinal)
                    .then(res => {
                        setProjectDetail(res.data.data[0])
                    })
            })
    }, [])

    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-1 overflow-hidden bg-gray-100 dark:bg-gray-900 md:py-2 lg:py-4 font-body">
                    <div className="px-1 md:px-24 lg:px-28">
                        <div className="flex w-full gap-3">
                            <div className="w-8/12 p-8 bg-white rounded-lg">
                                <div className="flex justify-start gap-3">
                                    <img src={detailShill.projectLogo} className="w-16 h-16 border border-green-200 rounded-full" alt="" />
                                    <div className="block w-full">
                                        <div className="flex items-center justify-between w-full">
                                            <h3 className="text-2xl font-semibold text-gray-900 dark:text-white">
                                                {detailShill.projectName}
                                            </h3>
                                            <div className="flex items-center gap-3 flex-end">
                                                {detailShill.statusPool === 'upcoming' ? (
                                                    <div className="flex items-center px-3 py-1 text-xs font-semibold text-orange-500 capitalize bg-orange-200 rounded-lg"><div className="w-2 h-2 mr-1 bg-orange-500 rounded-full"></div> {detailShill.statusPool}</div>
                                                ) : detailShill.statusPool === 'live' ? (
                                                    <div className="flex items-center px-3 py-1 text-xs font-semibold text-green-500 capitalize bg-green-200 rounded-lg"><div className="w-2 h-2 mr-1 bg-green-500 rounded-full"></div> {detailShill.statusPool}</div>
                                                ) : detailShill.statusPool === 'canceled' ? (
                                                    <div className="flex items-center px-3 py-1 text-xs font-semibold text-gray-500 capitalize bg-gray-200 rounded-lg"><div className="w-2 h-2 mr-1 bg-gray-500 rounded-full"></div> {detailShill.statusPool}</div>
                                                ) : (
                                                    <div className="flex items-center px-3 py-1 text-xs font-semibold text-red-500 capitalize bg-red-200 rounded-lg"><div className="w-2 h-2 mr-1 bg-red-500 rounded-full"></div> {detailShill.statusPool}</div>
                                                )}
                                            </div>
                                        </div>
                                        <div className="flex gap-2 mt-3">
                                            {projectDetail.categorys?.map((cate, index) => {
                                                return (
                                                    <p className="mb-0 font-medium text-gray-700 text-md dark:text-white" key={index}>{cate.text}</p>
                                                )
                                            })}
                                        </div>
                                        <div className="flex items-center justify-between w-full mt-3">
                                            <div className="flex w-full gap-2">
                                                <a href={projectDetail.dapps_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiGlobalLine />
                                                </a>
                                                <a href={projectDetail.twitter_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiTwitterLine />
                                                </a>
                                                <a href={projectDetail.telegram_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiTelegramLine />
                                                </a>
                                                <a href={projectDetail.facebook_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiFacebookLine />
                                                </a>
                                                <a href={projectDetail.youtube_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiYoutubeLine />
                                                </a>
                                                <a href={projectDetail.github_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiGithubLine />
                                                </a>
                                                <a href={projectDetail.instagram_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiInstagramLine />
                                                </a>
                                                <a href={projectDetail.discord_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiDiscordLine />
                                                </a>
                                                <a href={projectDetail.medium_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiMediumLine />
                                                </a>
                                                <a href={projectDetail.reddit_url} className="p-2 border border-gray-400 rounded-full">
                                                    <RiRedditLine />
                                                </a>
                                            </div>

                                            <div className="flex justify-end w-full gap-2">
                                                <a href="/detail-shill" className="px-4 py-1.5 text-white bg-blue-500 border border-blue-400 rounded-xl hover:bg-gray-900 hover:text-white">Open dApps Page</a>
                                            </div>
                                        </div>
                                        <div className="mt-4">
                                            <p>{projectDetail.description}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="w-4/12 p-8 bg-white rounded-lg">
                                <h1 className="text-xl font-semibold">Shill Detail</h1>
                                <div className="mt-4">
                                    <h6>dApps Visit Commision</h6>
                                    <h4 className="text-xl font-bold text-green-500">{detailShill.rewardDapps}</h4>
                                </div>
                                <div className="mt-4">
                                    <h6>Store Visit Commision</h6>
                                    <h4 className="text-xl font-bold text-green-500">{detailShill.rewardPage}</h4>
                                </div>
                                <div className="my-4">
                                    <h6>Total Reward Available</h6>
                                    <h4 className="text-xl font-bold text-green-500">{detailShill.initialReward}</h4>
                                </div>
                            </div>
                        </div>

                        <div className="w-full mt-4">
                            <div className="p-8 bg-white rounded-lg">
                                <h1 className="mb-3 text-lg font-semibold">Your Shill Link</h1>
                                <div className="flex items-center gap-2 bg-white">
                                    <input
                                        type="text"
                                        name="fName"
                                        id="fName"
                                        placeholder="https://dinodapps.com/dapps-detail/projectslug"
                                        value={'https://dinodapps.com/dapps-detail/' + projectDetail.slug + '?ref=' + affiliateDetail.referral + '&pool=' + slug}
                                        className="w-full rounded-md border border-[#e0e0e0] bg-white py-3 px-6 text-base font-medium text-[#6B7280] outline-none focus:border-[#6A64F1] focus:shadow-md"
                                    />
                                    <div className="px-4 py-3 font-medium border border-[#e0e0e0] bg-gray-200 rounded btn hover:bg-gray-300 flex items-center"><RiFileCopy2Line className="mr-1" /> Copy</div>
                                </div>
                            </div>
                        </div>

                        <div className="w-full mt-4">
                            <div className="p-8 bg-white rounded-lg">
                                <div className="flex w-full gap-3">
                                    <div className="flex flex-col w-full gap-3">
                                        <div className="w-full p-6 border border-gray-400 rounded-lg">
                                            <h1 className="mb-3 text-xl font-semibold">Your Reward</h1>
                                            <div className="flex items-center justify-between w-full">
                                                <div className="w-full">
                                                    <h1>Reward</h1>
                                                    <div className="text-lg font-semibold text-green-500">{affiliateDetail.balance}</div>
                                                </div>
                                                <div className="px-6 py-1 text-green-500 bg-green-200 rounded-lg">Claim</div>
                                            </div>
                                        </div>
                                        <div className="w-full p-6 border border-gray-400 rounded-lg">
                                            <h1 className="mb-3 text-xl font-semibold">Statistic</h1>
                                            <div className="flex flex-col gap-2">
                                                <div>
                                                    <h1>Page/Store Visit Count</h1>
                                                    {/* {affiliateDetail.id} */}
                                                    <div className="text-lg font-semibold text-green-500">
                                                        {affiliateDetail?.points?.map((point, index) => {
                                                            return (<div key={index}>{point.page}</div>)
                                                        })}
                                                    </div>
                                                </div>
                                                <div>
                                                    <h1>dApps Visit Count</h1>
                                                    <div className="text-lg font-semibold text-green-500">
                                                        {affiliateDetail?.points?.map((point, index) => {
                                                            return (<div key={index}>{point.dapps}</div>)
                                                        })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="w-full px-10 py-6 border border-gray-400 rounded-lg">
                                        <h1 className="mb-3 text-xl font-semibold">How to shill</h1>
                                        <ul className="list-decimal">
                                            <li>Connect wallet first, it's automatic add you for join shill to earn</li>
                                            <li>Copy shill link on form in above</li>
                                            <li>Start share to your community, etc</li>
                                            <li>Every one visitor visit page about project on dinodapps, you can earn reward</li>
                                            <li>Every one visitor visit dApps project (from button Open Dapsps in dinodapps), you can earn reward</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}
