import React, { useContext, useEffect, useState } from 'react'
import { RiCoinFill } from 'react-icons/ri'
import { toast } from 'react-toastify';
import { getAppByChainId } from '../libs/Env';
import { amountFormat, amountFormatFromWei } from '../libs/WebooLib';
import { Context } from '../Store'
import { BtnWalletConnect, ToastContentTx } from './dino-ui';
import FarmingBNB from './FarmingBNB';


export default function Farming() {
  const [state, dispatch] = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [hash,setHash] = useState("")
  const [txHash,setTxHash] = useState("")
  

  useEffect(() => {
    const check = async()=>{
      if (state && state.web3 && state.web3.utils.toChecksumAddress(state.account)) {
        await init()
        setHash(state.storeHash)
      }
    }
    
    check();
  }, [state, txHash])

  const init = async() => {
    try {
      let chainId = state.chainId


    } catch(e){
      console.error("Init Reward",e)
    }
  }

  

  return (
    <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-3">
      <FarmingBNB/>
    </div>
  )
}
