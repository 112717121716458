import React, { useContext, useEffect, useState } from "react";
import {
  BtnWalletConnect,
  CopyButton,
  Footer,
  Header,
  ModalTxHash,
} from "../components/dino-ui";
import { Context } from "../Store";
import { ButtonLoading } from "../components/Forms";
import { checkValidEmail } from "../libs/WebooLib";
import axios from "axios";

const FormReferral = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(null);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [referralLink, setReferralLink] = useState("");
  const [hash, setHash] = useState(null);

  useEffect(() => {
    setIsValidEmail(checkValidEmail(email));
  }, [email])

  const onsubmit = async () => {
    setIsLoading(true)
    await axios.post("https://api-farming.webooswap.com/api/v1/referral", {
      email: email,
      project: "Dino Dapps Project",
      address: state.account
    }).then(res => {
      setReferralLink("https://earn.dinodapps.com/referral-member?addr=" + state.account)
    }).catch(e => {
      alert("Failed registering address")
    }).finally(() => {
      setIsLoading(false)
    })
  }
  return (
    <BtnWalletConnect>
      <form>
        <input type={"text"}
          className={
            "border-l border-t border-b border-r border-red-600 text-black dark:text-white placeholder-red-600 text-sm focus:ring-red-900 focus:border-red-600 block w-full p-2.5 dark:bg-gray-800 dark:border-red-900 flex-shrink flex-grow leading-normal flex-1 h-10 border-grey-light px-3 relative rounded-lg mb-4"
          }
          readOnly={true}
          disabled={true}
          placeholder=""
          value={state.account}
        />
        <input type={"email"}
          className={
            "border-l border-t border-b border-r border-red-600 text-black dark:text-white placeholder-red-600 text-sm focus:ring-red-900 focus:border-red-600 block w-full p-2.5 dark:bg-gray-800 dark:border-red-900 flex-shrink flex-grow leading-normal flex-1 h-10 border-grey-light px-3 relative rounded-lg"
          }
          placeholder="Input your email"
          onChange={(e) => { setEmail(e.target.value) }}
        />
        {!isValidEmail && <small>*Please enter valid email</small>}
        <ButtonLoading
          isDisabled={!isValidEmail}
          title={"Submit"}
          onClick={() => onsubmit()}
          isLoading={isLoading}
        />


        {referralLink &&
          <div>
            <label className="font-bold text-bold">Your Referral Link</label>
            <input type={"text"}
              className={
                "border-l border-t border-b border-r border-red-600 text-black dark:text-white placeholder-red-600 text-sm focus:ring-red-900 focus:border-red-600 block w-full p-2.5 dark:bg-gray-800 dark:border-red-900 flex-shrink flex-grow leading-normal flex-1 h-10 border-grey-light px-3 relative rounded-lg mb-4"
              }
              readOnly={true}
              disabled={true}
              placeholder=""
              value={referralLink}
            />
            <CopyButton text={referralLink} />
          </div>
        }
      </form>
    </BtnWalletConnect>
  )
}

export default function Referral() {
  return (
    <>
      <Header />
      <>
        <section className="py-2 bg-gray-100 rounded-xl dark:bg-gray-800 md:py-8 lg:py-10">
          <div className="px-4 py-4 mx-2 bg-white dark:bg-gray-900 md:px-8 lg:px-10 sm:mx-12 md:mx-24 lg:mx-32">
            <div className="flex flex-col lg:flex-row">
              <div className="w-full py-5 pl-2 md:pl-6 lg:pl-6 md:w-1/2 lg:w-1/2">
                <div className="font-normal text-green-500">Form</div>
                <h1 className="mt-3 mb-5 text-xl font-extrabold text-black dark:text-white sm:text-3xl lg:text-4xl lg:leading-tight">
                  Registering Your Address
                </h1>
                <FormReferral />
              </div>
              <div className="w-full py-5 pl-0 md:pl-20 lg:pl-20 md:w-1/2 lg:w-1/2">
                <div className="font-normal text-green-500">
                  Learn How To Claim
                </div>
                <h1 className="mt-3 text-2xl font-extrabold text-black dark:text-white sm:text-3xl lg:text-4xl lg:leading-tight">
                  How To Claim
                </h1>
                <div className="mt-5 text-black dark:text-white">
                  <ol>
                    <li>Share this referral link to earn 1% Rewards from your referrals transaction!</li>
                    <li>You can register referrals after $DINO listing on PancakeSwap</li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Footer />
    </>
  );
}
