import React, { Fragment, useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import {
    BtnWalletConnect,
    CopyAddress,
    CopyButton,
    Empty,
    Footer,
    Header,
    LoadingWeboo,
    ModalTxHash,
} from "../../components/dino-ui";
import { Context } from "../../Store";
import { useGetAirdropList } from "./hook";

const RowAirdrop = ({row}) => {
    return (
        <>
            <div className="w-full mx-auto">
                <div className="p-4 bg-white border-2 border-gray-300 dark:border-gray-800 rounded-2xl">
                    <div className="flex justify-between w-full">
                        <div>
                            <img src={row?.icon} width="80" />
                        </div>
                        <div>
                            {row.status === "Upcoming" && (
                                <span className="gap-1 px-3 py-1 text-xs font-semibold text-white bg-gray-400 rounded-3xl dark:bg-red-500 dark:text-white">
                                    {row.status}
                                </span>
                            )}
                            {row.status === "Live" && (
                                <span className="gap-1 px-3 py-1 text-xs font-semibold text-white bg-green-400 rounded-3xl dark:bg-red-500 dark:text-white">
                                    {row.status}
                                </span>
                            )}
                            {row.status === "End" && (
                                <span className="gap-1 px-3 py-1 text-xs font-semibold text-white bg-red-400 rounded-3xl dark:bg-red-500 dark:text-white">
                                    {row.status}
                                </span>
                            )}
                            {row.status === "Canceled" && (
                                <span className="gap-1 px-3 py-1 text-xs font-semibold text-white bg-gray-600 rounded-3xl dark:bg-red-500 dark:text-white">
                                    {row.status}
                                </span>
                            )}
                            
                        </div>
                    </div>
                    <div>
                        <span className="text-2xl font-bold">
                            {row?.project_name}
                        </span>
                        <p className="text-sm text-gray-600">
                            {row.short_description}
                        </p>
                    </div>
                    <div>
                    <NavLink to={"/faucet/"+row.id} className={'flex items-center justify-center bg-green-600 gap-2 py-2 my-3 px-2 text-white rounded-lg'}>
                        View Detail
                    </NavLink>
                    </div>
                </div>
            </div>
        </>
    )
}


export default function AirdropList() {
    const [state, dispatch] = useContext(Context);
    const [{response,isLoading,errResponse},submit] = useGetAirdropList()

    useEffect(async ()=>{
        if(state && state.web3){
            
            
        }
    },[state])
    
    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-4 overflow-hidden bg-gray-200 dark:bg-gray-900 md:py-20 lg:py-20 font-body"  style={{
                      background: 'linear-gradient(90deg,#00519d,#102a85)'
                    }}>
                    <section className="grid items-center grid-cols-1 gap-12 px-4 mx-auto mt-2 max-w-screen-2xl lg:px-28 hero md:grid-cols-6 lg:grid-cols-12">
                        <div className="col-span-1 md:col-span-6">
                            <div className="text-3xl font-bold leading-snug text-white lg:leading-tight lg:text-5xl headline font-display md:text-7xl text-dark-1 md:leading-tight">
                              Faucet Pools
                            </div>
                            <div className="text-xl text-white">
                            Resurrecting your portofolio
                            </div>
                        </div>
                        <div className="col-span-1 md:col-span-6">
                            <div className="flex justify-center w-full">
                                <img src="./images/bounty-scammer-logo.svg" className="w-9/12" alt="" />
                            </div>
                        </div>
                    </section>
                </section>
                <div
                    className={
                        "py-5 w-full bg-white dark:bg-gray-900 gap-4 flex items-center justify-center"
                    }
                >
                    <button className="px-5 py-2 text-white bg-gray-900 rounded-full">
                        All Faucet
                    </button>
                    {/* <NavLink to={"/airdrop-create-pool"} className="px-5 py-2 text-white bg-green-600 rounded-full">
                      <div className="flex items-center justify-center">
                        <FaPlus />&nbsp;Create Pool
                      </div>
                    </NavLink> */}
                </div>
                <div className="w-full bg-gray-100 p-7 dark:bg-gray-800 md:p-10 lg:p-10">
                    <div className="container w-full md:mx-20">
                      {isLoading && <LoadingWeboo /> }
                      {!isLoading && !response && <Empty text={"No Airdrop Found"} /> }
                      {response && !isLoading &&
                        <div className="grid grid-cols-1 gap-6 align-middle lg:grid-cols-3">
                          {response.data.result.data.map((item,index)=>{
                            return <RowAirdrop row={item} key={index} />
                          })}
                        </div>
                      }
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
