import { Disclosure } from '@headlessui/react'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { BsChevronDown, BsQuestionCircle } from 'react-icons/bs'
import { FiRefreshCw } from 'react-icons/fi'
import { BtnWalletConnect, CopyAddress, ModalTxHash, ToastContentTx } from './dino-ui'
import {
  ButtonLoading,
  ButtonLoadingGray,
  InputNumberWithBalance,
} from './Forms'
import { Context } from '../Store'
import { getAppByChainId } from '../libs/Env'
import { BigNumber } from 'bignumber.js'
import { amountFormat, countAPY } from '../libs/WebooLib'
import { toast } from 'react-toastify'


const EarnManualStaking = () => {
  const [dividendBSAFU, setDividendBSAFU] = useState(0);
  const [dividendBNB, setDividendBNB] = useState(0);
  const [dividendBUSD, setDividendBUSD] = useState(0);
  const [dividendBTCB, setDividendBTCB] = useState(0);
  const [dividendBWETH, setDividendBWETH] = useState(0);
  const [dividendWeboo, setDividendWeboo] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [state, dispatch] = useContext(Context);
  const [hash, setHash] = useState(null);
  const [txHash, setTxHash] = useState(null);
  useEffect(async () => {
    if (state && state.web3 && state.account) {
      await init();
      setHash(state.storeHash);
    }
  }, [state, txHash])

  const getEstimation = async (tokenAddress, amountIn) => {
    const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, tokenAddress);
    const dec = await token.methods.decimals().call()
    const routerDex = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_ABI, getAppByChainId(state.chainId).ROUTER_ADDRESS);
    let path = [
      await routerDex.methods.WETH().call(),
      tokenAddress
    ];
    const est = await routerDex.methods.getAmountsOut(
      amountIn,
      path
    ).call()
    let a = new BigNumber(est[1]);
    return amountFormat((a.div(10 ** dec)).toString(), parseInt(dec));
  }

  const init = async () => {
    try {
      setIsLoading(false);

      const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
      const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
      const staking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).MANUAL_STAKING_ABI, manualStakingAddress);
      let dividend = new BigNumber(await staking.methods.dividendOf(state.account).call());
      const token = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ERC20_ABI, getAppByChainId(state.chainId).TOKEN_ADDRESS);
      const dec = await token.methods.decimals().call();
      const divBNB = dividend.div(10 ** dec);
      setDividendBNB(amountFormat(divBNB, 18));
      setDividendBSAFU(await getEstimation(getAppByChainId(state.chainId).TOKEN_ADDRESS, dividend.toString()));
      setDividendBUSD(await getEstimation(getAppByChainId(state.chainId).BUSD_ADDRESS, dividend.toString()));
      setDividendBTCB(await getEstimation(getAppByChainId(state.chainId).BTCB_ADDRESS, dividend.toString()));
      setDividendBWETH(await getEstimation(getAppByChainId(state.chainId).BETH_ADDRESS, dividend.toString()));
      setDividendWeboo(await getEstimation(getAppByChainId(state.chainId).WEBOO_ADDRESS, dividend.toString()));


    } catch (e) {
      console.error("Init Earn Staking", e)
    } finally {
      setIsLoading(false);
    }
  }

  const onClaimReward = async (targetToken) => {
    try {
      setIsLoading(true)
      const routerStaking = await new state.web3.eth.Contract(getAppByChainId(state.chainId).ROUTER_STAKING_ABI, getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS);
      const manualStakingAddress = await routerStaking.methods.manualStakingAddress().call();
      if (targetToken) {
        await routerStaking.methods.claimToOther(manualStakingAddress, state.account, targetToken).send({
          from: state.account
        }).on("transactionHash", (h) => {
          setTxHash(h)
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Submitted"} chainId={state.chainId} txHash={h} />);
        })
          .on("receipt", (h) => {
            setTxHash(h.transactionHash);
            toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Received"} chainId={state.chainId} txHash={h.transactionHash} />);
            setIsLoading(false);
          });
      } else {
        await routerStaking.methods.claimToEth(manualStakingAddress, state.account).send({
          from: state.account
        }).on("transactionHash", (h) => {
          setTxHash(h)
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Submitted"} chainId={state.chainId} txHash={h} />);
        })
          .on("receipt", (h) => {
            setTxHash(h.transactionHash);
            setIsLoading(false);
            toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Received"} chainId={state.chainId} txHash={h.transactionHash} />);

          });
      }
    } catch (e) {
      console.error("Claim DINO", e)
    } finally {
      await init();
      // setIsLoading(false);
    }
  }

  return (
    <div className="w-full px-6 py-4 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">
      <div className="block w-full">
        <div className="py-3">
          <p className="text-sm font-semibold text-gray-900 dark:text-white"><span className="text-green-500">DINO</span> EARNED</p>
          <div className="flex justify-between item-center">
            <div className="w-2/3">
              <div className="block">
                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBSAFU}</h6>
                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                  <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                    <img src="images/icon-reward/dino-token.png" className="w-4 h-4 rounded-full" alt="" />
                    DINO
                  </span>
                </p>
              </div>
            </div>
            <div className="w-1/3">
              <div className="flex items-center justify-end">
                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).TOKEN_ADDRESS)} />
                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
              </div>
            </div>
          </div>
          {/* BNB */}
          <div className="flex justify-between mt-3 item-center">
            <div className="w-2/3">
              <div className="block">
                <h6 className="font-semibold text-gray-900 dark:text-white">{dividendBNB}</h6>
                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                  <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                    <img src="images/icon-reward/bnb.svg" className="w-4 h-4 rounded-full" alt="" />
                    BNB
                  </span>
                </p>
              </div>
            </div>
            <div className="w-1/3">
              <div className="flex items-center justify-end">
                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward()} />
                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
              </div>
            </div>
          </div>
          {/* BTCB */}
          <div className="flex justify-between mt-3 item-center">
            <div className="w-2/3">
              <div className="block">
                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBTCB}</h6>
                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                  <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                    <img src="images/icon-reward/btc.svg" className="w-4 h-4 rounded-full" alt="" />
                    BTCB
                  </span>
                </p>
              </div>
            </div>
            <div className="w-1/3">
              <div className="flex items-center justify-end">
                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BTCB_ADDRESS)} />
                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
              </div>
            </div>
          </div>
          {/* BUSD */}
          <div className="flex justify-between mt-3 item-center">
            <div className="w-2/3">
              <div className="block">
                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBUSD}</h6>
                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                  <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                    <img src="images/icon-reward/busd.svg" className="w-4 h-4 rounded-full" alt="" />
                    BUSD
                  </span>
                </p>
              </div>
            </div>
            <div className="w-1/3">
              <div className="flex items-center justify-end">
                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BUSD_ADDRESS)} />
                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
              </div>
            </div>
          </div>
          {/* BETH */}
          <div className="flex justify-between mt-3 item-center">
            <div className="w-2/3">
              <div className="block">
                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendBWETH}</h6>
                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                  <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                    <img src="images/icon-reward/eth.svg" className="w-4 h-4 rounded-full" alt="" />
                    ETH
                  </span>
                </p>
              </div>
            </div>
            <div className="w-1/3">
              <div className="flex items-center justify-end">
                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).BETH_ADDRESS)} />
                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
              </div>
            </div>
          </div>
          {/* WEBOO */}
          <div className="flex justify-between mt-3 item-center">
            <div className="w-2/3">
              <div className="block">
                <h6 className="font-semibold text-gray-900 dark:text-white">Est: {dividendWeboo}</h6>
                <p className="flex items-center gap-1 text-sm font-light text-gray-900 dark:text-white">
                  <span className="flex items-center gap-1 px-2 py-1 text-xs bg-gray-200 border border-gray-400 rounded-full dark:text-white dark:border-gray-800 dark:bg-gray-800">
                    <img src="images/icon-reward/weboo.svg" className="w-4 h-4 rounded-full" alt="" />
                    WEBOO
                  </span>
                </p>
              </div>
            </div>
            <div className="w-1/3">
              <div className="flex items-center justify-end">
                <ButtonLoading title={"Claim"} isDisabled={isLoading} isLoading={isLoading} onClick={() => onClaimReward(getAppByChainId(state.chainId).WEBOO_ADDRESS)} />
                {/* <button className="flex items-center justify-center gap-2 px-4 py-2 text-white bg-green-500 rounded-lg hover:bg-green-600"><GiReceiveMoney /> Collect</button> */}
              </div>
            </div>
          </div>
        </div>
        {/* <ModalTxHash txHash={txHash} /> */}
      </div>
    </div>
  )
}
export default function ManualStaking() {
  const [isLoading, setIsLoading] = useState(false)
  const [state, dispatch] = useContext(Context)
  const [hash, setHash] = useState(null)
  const [decimal, setDecimal] = useState(18)
  const [tokenAddress, setTokenAddress] = useState(null)
  const [amount, setAmount] = useState(0)
  const [txHash, setTxHash] = useState(null)
  const [isNeedApprove, setIsNeedApprove] = useState(false)
  const [isNeedApproveUnstake, setIsNeedApproveUnstake] = useState(false)
  const [apr, setApr] = useState(0)
  const [apy, setApy] = useState(0)
  const [isUnstake, setIsUnstake] = useState(false)
  const [stakingAddress, setStakingAddress] = useState('0x')
  const [totalContributor, setTotalContributor] = useState(0)
  const [totalStacked, setTotalStacked] = useState(0)
  const [amountUnclaim, setAmountUnclaim] = useState(0)
  const [stakingBalance, setStakingBalance] = useState(0)
  const [earnIndex, setEarnIndex] = useState(1)
  useEffect(async () => {
    if (state && state.web3 && state.account) {
      await init()
      setHash(state.storeHash)
    }
  }, [state, txHash])

  useEffect(() => {
    // onGetTotalStacked(totalStacked);
  }, [totalStacked])

  const checkIfNeedEnableContract = async () => {
    try {
      if (state && state.chainId) {
        const token = await new state.web3.eth.Contract(
          getAppByChainId(state.chainId).ERC20_ABI,
          getAppByChainId(state.chainId).TOKEN_ADDRESS,
        )
        setTokenAddress(getAppByChainId(state.chainId).TOKEN_ADDRESS)
        const allowance = new BigNumber(
          await token.methods
            .allowance(
              state.account,
              getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
            )
            .call(),
        )
        let balance = new BigNumber(
          await token.methods.balanceOf(state.account).call(),
        )
        const dec = await token.methods.decimals().call()
        // balance = balance.div(10**dec);
        setDecimal(dec)

        if (allowance.isGreaterThanOrEqualTo(balance)) {
          setIsNeedApprove(false)
        } else {
          setIsNeedApprove(true)
        }
      }
    } catch (e) {
      console.error('check Need Enable', e)
    }
  }

  const checkIfNeedEnableUnstake = async () => {
    try {
      if (state && state.chainId) {
        const routerStaking = await new state.web3.eth.Contract(
          getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
          getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
        )
        const manualStakingAddress = await routerStaking.methods
          .manualStakingAddress()
          .call()
        const token = await new state.web3.eth.Contract(
          getAppByChainId(state.chainId).ERC20_ABI,
          manualStakingAddress,
        )
        const allowance = new BigNumber(
          await token.methods
            .allowance(
              state.account,
              getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
            )
            .call(),
        )
        let balance = new BigNumber(
          await token.methods.balanceOf(state.account).call(),
        )
        // console.log(allowance.toString(),balance.toString());
        if (allowance.isGreaterThanOrEqualTo(balance)) {
          setIsNeedApproveUnstake(false)
        } else {
          setIsNeedApproveUnstake(true)
        }
      }
    } catch (e) {
      console.error('check Need Enable', e)
    }
  }

  const init = async () => {
    await checkIfNeedEnableContract()
    await checkIfNeedEnableUnstake()

    const routerStaking = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
      getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
    )
    const manualStakingAddress = await routerStaking.methods
      .manualStakingAddress()
      .call()
    const staking = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).AUTO_STAKING_ABI,
      manualStakingAddress,
    )
    const dividendOf = new BigNumber(
      await staking.methods.dividendOf(state.account).call(),
    )

    const dec = await staking.methods.decimals().call()
    const totalSupply = new BigNumber(
      await staking.methods.totalSupply().call(),
    )
    const APR = await staking.methods.APR().call()
    let stkBalance = new BigNumber(
      await staking.methods.balanceOf(state.account).call(),
    )
    stkBalance = stkBalance.dividedBy(10 ** dec)
    setStakingBalance(amountFormat(stkBalance, 4))
    setAmountUnclaim(amountFormat(dividendOf.dividedBy(10 ** dec), 18))
    setApr(APR)
    setStakingAddress(manualStakingAddress)
    setTotalStacked(amountFormat(totalSupply.div(10 ** dec).toString()))
  }

  const onStake = async () => {
    try {
      setIsLoading(true)
      const token = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ERC20_ABI,
        getAppByChainId(state.chainId).TOKEN_ADDRESS,
      )
      const dec = await token.methods.decimals().call()
      let amnt = new BigNumber(amount)
      amnt = amnt.multipliedBy(10 ** dec)

      const routerStaking = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
        getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
      )
      const manualStakingAddress = await routerStaking.methods
        .manualStakingAddress()
        .call()
      await routerStaking.methods
        .stake(manualStakingAddress, state.account, amnt.toFixed(0))
        .send({
          from: state.account,
        })
        .on('transactionHash', (h) => {
          setTxHash(h)
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Submitted"} chainId={state.chainId} txHash={h} />);
        })
        .on('receipt', (h) => {
          setTxHash(h.transactionHash)
          setIsLoading(false)
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        })
    } catch (e) {
      console.error('On Stake', e)
      toast.error(e)
    } finally {
      setIsLoading(false)
      await init()
      setEarnIndex(earnIndex++)
    }
  }

  const onUnstake = async () => {
    try {
      setIsLoading(true)
      const token = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ERC20_ABI,
        getAppByChainId(state.chainId).TOKEN_ADDRESS,
      )
      const dec = await token.methods.decimals().call()
      let amnt = new BigNumber(amount)
      amnt = amnt.multipliedBy(10 ** dec)

      const routerStaking = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
        getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
      )
      const manualStakingAddress = await routerStaking.methods
        .manualStakingAddress()
        .call()
      await routerStaking.methods
        .unstake(manualStakingAddress, state.account, amnt.toFixed(0))
        .send({
          from: state.account,
        })
        .on('transactionHash', (h) => {
          setTxHash(h)
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Submitted"} chainId={state.chainId} txHash={h} />);
        })
        .on('receipt', (h) => {
          setTxHash(h.transactionHash)
          setIsLoading(false)
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        })
    } catch (e) {
      console.error('On UnStake', e)
    } finally {
      setIsLoading(false)
      await init()
      setEarnIndex(earnIndex++)
    }
  }

  const onEnable = async () => {
    const token = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).ERC20_ABI,
      getAppByChainId(state.chainId).TOKEN_ADDRESS,
    )
    let balance = new BigNumber(
      await token.methods.balanceOf(state.account).call(),
    )
    balance = balance.multipliedBy(200)
    setIsLoading(true)
    await token.methods
      .approve(
        getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
        balance.toFixed(0),
      )
      .send({
        from: state.account,
      })
      .on('transactionHash', (h) => {
        setTxHash(h)
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Submitted"} chainId={state.chainId} txHash={h} />);
      })
      .on('receipt', (h) => {
        setTxHash(h.transactionHash)
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        setIsLoading(false)
      })
  }

  const onEnableUnstake = async () => {
    const routerStaking = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
      getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
    )
    const manualStakingAddress = await routerStaking.methods
      .manualStakingAddress()
      .call()
    const token = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).ERC20_ABI,
      manualStakingAddress,
    )
    let balance = new BigNumber(
      await token.methods.balanceOf(state.account).call(),
    )
    balance = balance.multipliedBy(200)
    setIsLoading(true)
    await token.methods
      .approve(
        getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
        balance.toFixed(0),
      )
      .send({
        from: state.account,
      })
      .on('transactionHash', (h) => {
        setTxHash(h)
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Submitted"} chainId={state.chainId} txHash={h} />);
      })
      .on('receipt', (h) => {
        setTxHash(h.transactionHash)
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        setIsLoading(false)
        setIsNeedApproveUnstake(false)
      })
  }
  return (
    <div className="w-full mx-auto">
      <div className="flex justify-between w-full p-6 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-t-2xl">
        <div className="block">
          <h3 className="items-center text-xl font-semibold text-gray-900 dark:text-white">
            Simple Interest{' '}
            <span className="px-2 py-1 text-xs text-white bg-red-700 rounded-lg">
              Recommended
            </span>
          </h3>
          <p className="font-normal text-gray-700 dark:text-gray-100">
            Earn All Coin, Stake DINO{' '}
          </p>
        </div>
        <div className="">
          <img
            src="images/icon-reward/dino-token.png"
            className="border border-gray-200 rounded-full w-14"
            alt=""
          />
          <img
            src="images/icon-reward/btc.svg"
            className="absolute z-10 w-8 ml-8 -mt-5 border border-gray-200 rounded-full"
            alt=""
          />
        </div>
      </div>
      <div className="w-full px-6 py-3 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">
        <div className="flex justify-between w-full py-2">
          <p className="text-gray-900 dark:text-white">APR</p>
          <p className="text-gray-900 dark:text-white">{apr}%</p>
        </div>
        <div className="flex justify-between w-full py-2">
          <p className="text-gray-900 dark:text-white">Balance (DINO-SP)</p>
          <p className="font-bold text-gray-900 dark:text-white">
            {stakingBalance}
          </p>
        </div>
        <div className="flex justify-between w-full py-2">
          <p className="text-gray-900 dark:text-white">Unclaim Reward (BNB)</p>
          <p className="font-bold text-gray-900 dark:text-white">
            {amountUnclaim}
          </p>
        </div>
        <div className="block w-full py-2">
          <p className="font-semibold text-gray-900 dark:text-white">
            Start Earning
          </p>
          {/* disable button simple interest */}
          {!isUnstake && (
            <BtnWalletConnect>
              <InputNumberWithBalance
                title={'Amount'}
                onChange={(e) => {
                  setAmount(e.target ? e.target.value : e)
                }}
                value={amount}
                subFix={'DINO'}
                prefix={'DINO'}
                address={tokenAddress}
                key={'dino' + txHash}
              />
              {isNeedApprove && (
                <ButtonLoading
                  title={'Enable Contract'}
                  onClick={() => onEnable()}
                  isLoading={isLoading}
                />
              )}
              {!isNeedApprove && (
                <div className="flex items-center justify-between gap-2">
                  <ButtonLoading
                    title={'Stake'}
                    isLoading={isLoading}
                    onClick={() => onStake()}
                  />
                  <ButtonLoadingGray
                    title={'Unstake'}
                    isLoading={isLoading}
                    onClick={() => setIsUnstake(true)}
                  />
                </div>
              )}
            </BtnWalletConnect>
          )}
          {isUnstake && (
            <BtnWalletConnect>
              <InputNumberWithBalance
                title={'Amount'}
                onChange={(e) => {
                  setAmount(e.target ? e.target.value : e)
                }}
                value={amount}
                subFix={'DINO-SP'}
                prefix={'DINO-SP'}
                address={stakingAddress}
                key={'dino-sp' + txHash}
              />
              {isNeedApproveUnstake && (
                <>
                  <ButtonLoading
                    title={'Enable Contract'}
                    onClick={() => onEnableUnstake()}
                    isLoading={isLoading}
                  />
                  <div className="text-center">or</div>
                  <ButtonLoadingGray
                    title={'Stake'}
                    isLoading={isLoading}
                    onClick={() => setIsUnstake(false)}
                  />
                </>
              )}
              {!isNeedApproveUnstake && (
                <div className="flex items-center justify-between gap-2">
                  <ButtonLoadingGray
                    title={'Stake'}
                    isLoading={isLoading}
                    onClick={() => setIsUnstake(false)}
                  />
                  <ButtonLoading
                    title={'Unstake'}
                    isLoading={isLoading}
                    onClick={() => onUnstake()}
                  />
                </div>
              )}
            </BtnWalletConnect>
          )}
        </div>
        {/* <ModalTxHash txHash={txHash} /> */}
      </div>
      <EarnManualStaking />
      <div className="w-full px-6 py-4 bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-b-2xl">
        <Disclosure defaultOpen={true}>
          {({ open }) => (
            <>
              <div className="flex items-center justify-between">
                <div className="flex items-center justify-start gap-2">
                  <span className="flex items-center gap-1 px-3 py-1 text-sm font-semibold text-green-500 border border-green-500 rounded-full">
                    <FiRefreshCw />
                    Manual
                  </span>
                  <BsQuestionCircle />
                </div>
                <Disclosure.Button className="flex items-center gap-1 font-semibold text-green-500">
                  {open ? 'Hide' : 'Details'}{' '}
                  <BsChevronDown
                    className={`${open ? 'transform rotate-180' : ''
                      } w-4 h-4 text-green-500 font-semibold`}
                  />
                </Disclosure.Button>
              </div>
              <Disclosure.Panel className="py-2 text-gray-900">
                <div className="flex flex-col gap-2">
                  <div className="flex items-center justify-between">
                    <h6 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Total Contributors:
                    </h6>
                    <p className="text-sm font-semibold text-gray-900 dark:text-white">
                      {totalContributor}
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <h6 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Total Staked:
                    </h6>
                    <p className="text-sm font-semibold text-gray-900 dark:text-white">
                      {totalStacked} DINO
                    </p>
                  </div>
                  <div className="flex items-center justify-between">
                    <h6 className="text-sm font-semibold text-gray-900 dark:text-white">
                      Contract Address:
                    </h6>
                    <div className="text-sm font-semibold text-gray-900 dark:text-white">
                      <CopyAddress addr={stakingAddress} />
                    </div>
                  </div>
                </div>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  )
}
