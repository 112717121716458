import { Disclosure } from '@headlessui/react'
import React, { Fragment, useContext, useEffect, useState } from 'react'
import { BsChevronDown, BsQuestionCircle } from 'react-icons/bs'
import { FiRefreshCw } from 'react-icons/fi'
import { BtnWalletConnect, CopyAddress, LastTxAccount, ModalTxHash, ToastContentTx } from './dino-ui'
import {
  ButtonLoading,
  ButtonLoadingGray,
  InputNumberWithBalance,
} from './Forms'
import { Context } from '../Store'
import { getAppByChainId } from '../libs/Env'
import { BigNumber } from 'bignumber.js'
import { amountFormat, countAPY } from '../libs/WebooLib'
import { toast } from 'react-toastify'

export default function AutoStaking() {
  const [isLoading, setIsLoading] = useState(false)
  const [state, dispatch] = useContext(Context)
  const [hash, setHash] = useState(null)
  const [totalContributor, setTotalContributor] = useState(0)
  const [totalStacked, setTotalStacked] = useState(0)
  const [decimal, setDecimal] = useState(18)
  const [apr, setApr] = useState(0)
  const [apy, setApy] = useState(0)
  const [amount, setAmount] = useState(0)
  const [txHash, setTxHash] = useState('')

  const [tokenAddress, setTokenAddress] = useState('')
  const [tokenBalance, setTokenBalance] = useState(0)
  const [stakingAddress, setStakingAddress] = useState('0x')
  const [stakingBalance, setStakingBalance] = useState(0)
  const [unclaimBalance, setUnclaimBalance] = useState(0)
  const [isUnstake, setIsUnstake] = useState(false)
  const [isNeedApprove, setIsNeedApprove] = useState(false)
  const [isNeedApproveUnstake, setIsNeedApproveUnstake] = useState(false)

  useEffect(async () => {
    if (state && state.web3 && state.account) {
      await init()
      setHash(state.storeHash)
    }
    countingAPY()
  }, [state, txHash])

  useEffect(() => {
    // onGetTotalStacked(totalStacked);
  }, [totalStacked])

  const init = async () => {
    let chainId = state.chainId
    await checkIfNeedEnableContract()
    await checkIfNeedEnableUnstake()

    const token = await new state.web3.eth.Contract(
      getAppByChainId(chainId).ERC20_ABI,
      getAppByChainId(chainId).TOKEN_ADDRESS
    )

    const routerStaking = await new state.web3.eth.Contract(
      getAppByChainId(chainId).ROUTER_STAKING_ABI,
      getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
    )
    const autoStakingAddress = await routerStaking.methods
      .autoStakingAddress()
      .call()
    const staking = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).AUTO_STAKING_ABI,
      autoStakingAddress,
    )
    const dec = await staking.methods.decimals().call()
    const totalSupply = new BigNumber(
      await staking.methods.totalSupply().call(),
    )
    const APR = await staking.methods.APR().call()
    const totalLoop = await staking.methods.loopInterest().call()
    const dividendOf = new BigNumber(
      await staking.methods.dividendOf(state.account).call(),
    )
    setUnclaimBalance(amountFormat(dividendOf.dividedBy(10 ** 18), 18))
    let stkBalance = new BigNumber(
      await staking.methods.balanceOf(state.account).call(),
    )
    stkBalance = stkBalance.dividedBy(10 ** dec)
    setStakingBalance(amountFormat(stkBalance, 4))
    setApr(APR)
    setApy(countAPY(APR, totalLoop))
    setStakingAddress(autoStakingAddress)
    setTokenAddress(getAppByChainId(state.chainId).TOKEN_ADDRESS)
    setTotalStacked(amountFormat(totalSupply.div(10 ** dec).toString()))
  }

  const countingAPY = async () => {
    // let chainId = state.chainId ? state.chainId : 97;
    // const APR = await staking.methods.APR().call()
    // const totalLoop = await staking.methods.loopInterest().call()
    // const staking = await new state.web3.eth.Contract(
    //   getAppByChainId(cha).AUTO_STAKING_ABI,
    //   stakingAddress,
    // )
  }


  const onStake = async () => {
    try {
      setIsLoading(true)
      const token = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ERC20_ABI,
        getAppByChainId(state.chainId).TOKEN_ADDRESS,
      )
      const dec = await token.methods.decimals().call()
      let amnt = new BigNumber(amount)
      if (amnt <= 0) throw "Amount Cannot be Zero"
      amnt = amnt.multipliedBy(10 ** dec)

      const routerStaking = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
        getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
      )
      const autoStakingAddress = await routerStaking.methods
        .autoStakingAddress()
        .call()
      await routerStaking.methods
        .stake(autoStakingAddress, state.account, amnt.toFixed(0))
        .send({
          from: state.account,
        })
        .on('transactionHash', (t) => {
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Submitted"} chainId={state.chainId} txHash={t} />);
          setTxHash(t)
        })
        .on('receipt', (t) => {
          setTxHash(t.transactionHash)
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Received"} chainId={state.chainId} txHash={t.transactionHash} />);
          setIsLoading(false)
        })
    } catch (e) {
      console.error('On Stake', e)
      toast.error(e)
    } finally {
      setIsLoading(false)
      await init()
    }
  }

  const onUnstake = async () => {
    try {
      setIsLoading(true)
      const token = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ERC20_ABI,
        getAppByChainId(state.chainId).TOKEN_ADDRESS,
      )
      const dec = await token.methods.decimals().call()
      let amnt = new BigNumber(amount)
      amnt = amnt.multipliedBy(10 ** dec)
      // alert(amnt.toFixed(0))

      const routerStaking = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
        getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
      )
      const autoStakingAddress = await routerStaking.methods
        .autoStakingAddress()
        .call()
      await routerStaking.methods
        .unstake(autoStakingAddress, state.account, amnt.toFixed(0))
        .send({
          from: state.account,
        })
        .on('transactionHash', (t) => {
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Submitted"} chainId={state.chainId} txHash={t} />);
          setTxHash(t)
        })
        .on('receipt', (t) => {
          setTxHash(t.transactionHash)
          toast.success(({ closeToast }) => <ToastContentTx title={"Transaction Received"} chainId={state.chainId} txHash={t.transactionHash} />);
        })
    } catch (e) {
      console.error('On UnStake', e)
      toast.error(e)
    } finally {
      setIsLoading(false)
      await init()
    }
  }

  const onEnable = async () => {
    const token = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).ERC20_ABI,
      getAppByChainId(state.chainId).TOKEN_ADDRESS,
    )
    let balance = new BigNumber(
      await token.methods.balanceOf(state.account).call(),
    )
    balance = balance.multipliedBy(200)
    setIsLoading(true)
    await token.methods
      .approve(
        getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
        balance.toFixed(0),
      )
      .send({
        from: state.account,
      })
      .on('transactionHash', (h) => {
        setTxHash(h)
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Submitted"} chainId={state.chainId} txHash={h} />);
      })
      .on('receipt', (h) => {
        setTxHash(h.transactionHash)
        setIsLoading(false)
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Received"} chainId={state.chainId} txHash={h.transactionHash} />);
      })
  }
  const checkIfNeedEnableContract = async () => {
    try {
      if (state && state.chainId && state.account) {
        // alert("0xb39AF32036e58605bE3dD3BB91D5898c9bf76F1F")
        const token = await new state.web3.eth.Contract(
          getAppByChainId(state.chainId).ERC20_ABI,
          getAppByChainId(state.chainId).TOKEN_ADDRESS,
        )
        setTokenAddress(getAppByChainId(state.chainId).TOKEN_ADDRESS)
        const scAllowance = await token.methods
          .allowance(
            state.web3.utils.toHex(state.account),
            state.web3.utils.toHex(
              getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
            ),
          )
          .call()
        const allowance = new BigNumber(scAllowance)
        let balance = new BigNumber(
          await token.methods.balanceOf(state.account).call(),
        )
        const dec = await token.methods.decimals().call()
        setDecimal(dec)

        if (allowance.isGreaterThanOrEqualTo(balance)) {
          setIsNeedApprove(false)
        } else {
          setIsNeedApprove(true)
        }
      }
    } catch (e) {
      console.error('check Need Enable', e)
    }
  }

  const checkIfNeedEnableUnstake = async () => {
    try {
      if (state && state.chainId && state.account) {
        const routerStaking = await new state.web3.eth.Contract(
          getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
          getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
        )
        const autoStakingAddress = await routerStaking.methods
          .autoStakingAddress()
          .call()
        const token = await new state.web3.eth.Contract(
          getAppByChainId(state.chainId).ERC20_ABI,
          autoStakingAddress,
        )
        const allowance = new BigNumber(
          await token.methods
            .allowance(
              state.account,
              getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
            )
            .call(),
        )
        let balance = new BigNumber(
          await token.methods.balanceOf(state.account).call(),
        )

        if (allowance.isGreaterThanOrEqualTo(balance)) {
          setIsNeedApproveUnstake(false)
        } else {
          setIsNeedApproveUnstake(true)
        }
      }
    } catch (e) {
      console.error('check Need Enable', e)
    }
  }

  const onEnableUnstake = async () => {
    const routerStaking = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).ROUTER_STAKING_ABI,
      getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
    )
    const autoStakingAddress = await routerStaking.methods
      .autoStakingAddress()
      .call()
    const token = await new state.web3.eth.Contract(
      getAppByChainId(state.chainId).ERC20_ABI,
      autoStakingAddress,
    )
    let balance = new BigNumber(
      await token.methods.balanceOf(state.account).call(),
    )
    balance = balance.multipliedBy(200)
    setIsLoading(true)
    await token.methods
      .approve(
        getAppByChainId(state.chainId).ROUTER_STAKING_ADDRESS,
        balance.toFixed(0),
      )
      .send({
        from: state.account,
      })
      .on('transactionHash', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Submitted"} chainId={state.chainId} txHash={h} />);
        setTxHash(h)
      })
      .on('receipt', (h) => {
        toast.success(({ closeToast }) => <ToastContentTx title={"Approve Received"} chainId={state.chainId} txHash={h.transactionHash} />);
        setTxHash(h.transactionHash)
        setIsLoading(false)
        setIsNeedApproveUnstake(false)
      })
  }

  return (
    <div className="w-full mx-auto">
      <div className="border-2 border-green-500 rounded-2xl">
        <div className="w-full bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-2xl">
          <div className="flex justify-between w-full p-6 border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-t-2xl">
            <div className="block">
              <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                Compounding
              </h3>
              <p className="font-normal text-gray-700 dark:text-gray-100">
                Automatic Restaking <br />
              </p>
            </div>
            <div className="">
              <img
                src="images/icon-reward/dino-token.png"
                className="border border-gray-200 rounded-full w-14"
                alt=""
              />
              <FiRefreshCw
                className="absolute z-10 w-8 h-8 p-2 ml-8 -mt-5 text-white bg-green-500 border border-gray-200 rounded-full"
                alt=""
              />
            </div>
          </div>

          <div className="w-full px-6 py-3 border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900">
            {/* <FormCompounding onGetTotalStacked={(e) => setTotalStacked(e)} /> */}
            <div className="flex justify-between w-full py-2">
              <p className="text-gray-900 dark:text-white">APY</p>
              <p className="text-gray-900 dark:text-white">{apy}%</p>
            </div>

            <div className="flex justify-between w-full py-2">
              <p className="text-gray-900 dark:text-white">
                Balance (DINO-SP(AUTO))
              </p>
              <p className="font-bold text-gray-900 dark:text-white">
                {stakingBalance}
              </p>
            </div>
            <div className="flex justify-between w-full py-2">
              <p className="text-gray-900 dark:text-white">
                Unclaim Reward (BNB)
              </p>
              <p className="flex font-bold text-gray-900 dark:text-white">
                {unclaimBalance}
              </p>
            </div>

            <div className="block w-full py-2">
              <div className="font-semibold text-gray-900 dark:text-white">
                {!isUnstake ? 'Start Earning' : 'Unstake'}
              </div>
              {!isUnstake &&
                <BtnWalletConnect>
                  <InputNumberWithBalance
                    title={"Amount"}
                    onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                    value={amount}
                    subFix={"DINO"}
                    prefix={"DINO"}
                    address={tokenAddress}
                    key={'dino' + txHash}
                  />
                  {isNeedApprove &&
                    <ButtonLoading
                      title={"Enable Contract"}
                      onClick={() => onEnable()}
                      isLoading={isLoading}
                    />
                  }
                  {!isNeedApprove &&
                    <div className="flex items-center justify-between gap-2">
                      <ButtonLoading title={"Stake"} isLoading={isLoading} onClick={() => onStake()} />
                      <ButtonLoadingGray title={"Unstake"} isLoading={isLoading} onClick={() => setIsUnstake(true)} />
                    </div>
                  }
                </BtnWalletConnect>
              }
              {isUnstake &&
                <BtnWalletConnect>
                  <InputNumberWithBalance
                    title={"Amount"}
                    onChange={(e) => { setAmount(e.target ? e.target.value : e) }}
                    value={amount}
                    subFix={"DINO-SP(Auto)"}
                    prefix={"DINO-SP(Auto)"}
                    address={stakingAddress}
                    key={'dino-sp' + txHash}
                  />
                  {isNeedApproveUnstake &&
                    <>
                      <ButtonLoading
                        title={"Enable Contract"}
                        onClick={() => onEnableUnstake()}
                        isLoading={isLoading}
                      />
                      <div className="text-center">or</div>
                      <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                    </>
                  }
                  {!isNeedApproveUnstake &&
                    <div className="flex items-center justify-between gap-2">
                      <ButtonLoadingGray title={"Stake"} isLoading={isLoading} onClick={() => setIsUnstake(false)} />
                      <ButtonLoading title={"Unstake"} isLoading={isLoading} onClick={() => onUnstake()} />
                    </div>
                  }
                </BtnWalletConnect>
              }
              <LastTxAccount loop={3} txHash={txHash} />
              {/* <ModalTxHash txHash={txHash} /> */}
            </div>
          </div>

          <div className="w-full px-6 py-4 border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-b-2xl">
            <Disclosure defaultOpen={true}>
              {({ open }) => (
                <>
                  <div className="flex items-center justify-between">
                    <div className="flex items-center justify-start gap-2">
                      <span className="flex items-center gap-1 px-3 py-1 text-sm font-semibold text-green-500 border border-green-500 rounded-full">
                        <FiRefreshCw />
                        Auto
                      </span>
                      <BsQuestionCircle />
                    </div>
                    <Disclosure.Button className="flex items-center gap-1 font-semibold text-green-500">
                      {open ? 'Hide' : 'Details'}{' '}
                      <BsChevronDown
                        className={`${open ? 'transform rotate-180' : ''
                          } w-4 h-4 text-green-500 font-semibold`}
                      />
                    </Disclosure.Button>
                  </div>
                  <Disclosure.Panel className="py-2 text-gray-900">
                    <div className="flex flex-col gap-2">
                      <div className="flex items-center justify-between">
                        <h6 className="text-sm font-semibold text-gray-900 dark:text-white">
                          Total Contributors:
                        </h6>
                        <p className="text-sm font-semibold text-gray-900 dark:text-white">
                          {totalContributor}
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <h6 className="text-sm font-semibold text-gray-900 dark:text-white">
                          Total Staked:
                        </h6>
                        <p className="text-sm font-semibold text-gray-900 dark:text-white">
                          {totalStacked} DINO
                        </p>
                      </div>
                      <div className="flex items-center justify-between">
                        <h6 className="text-sm font-semibold text-gray-900 dark:text-white">
                          Contract Address:
                        </h6>
                        <div className="text-sm font-semibold text-gray-900 dark:text-white">
                          <CopyAddress addr={stakingAddress} />
                        </div>
                      </div>
                    </div>
                  </Disclosure.Panel>
                </>
              )}
            </Disclosure>
            <br />
            <ButtonLoading title={"Restake " + unclaimBalance + " BNB"} isDisabled={unclaimBalance == 0} /><br />
            <ButtonLoading title={"Bounty"} isDisabled={true} />
          </div>
        </div>
      </div>
    </div>

  )
}
