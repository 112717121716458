import React, { useContext, useEffect, useState } from 'react'
import { RiCoinFill } from 'react-icons/ri'
import { toast } from 'react-toastify';
import { getAppByChainId } from '../libs/Env';
import { amountFormat, amountFormatFromWei } from '../libs/WebooLib';
import { Context } from '../Store'
import { BtnWalletConnect, ToastContentTx } from './dino-ui';


export default function RewardForm() {
  const [state, dispatch] = useContext(Context)
  const [isLoading, setIsLoading] = useState(false)
  const [apr,setApr] = useState(0);
  const [hash,setHash] = useState("")
  const [txHash,setTxHash] = useState("")
  const [rewardBnb,setRewardBnb] = useState(0);
  const [rewardBusd,setRewardBusd] = useState(0);
  const [rewardBtcb,setRewardBtcb] = useState(0);
  const [rewardEth,setRewardEth] = useState(0);
  const [rewardDino,setRewardDino] = useState(0);
  const [rewardBsafu,setRewardBsafu] = useState(0);

  useEffect(() => {
    const check = async()=>{
      if (state && state.web3 && state.web3.utils.toChecksumAddress(state.account)) {
        await init()
        setHash(state.storeHash)
      }
    }
    
    check();
  }, [state, txHash])

  const init = async() => {
    try {
      let chainId = state.chainId

      const earnToken = await new state.web3.eth.Contract(
        getAppByChainId(chainId).EARN_ABI,
        getAppByChainId(chainId).EARN_ADDRESS,
      )
      const APR = await earnToken.methods.APR().call()
      const _rewardBnb = await earnToken.methods.dividendOf(state.web3.utils.toChecksumAddress(state.account)).call();
      const _rewardBusd = await earnToken.methods.estimationReward(state.web3.utils.toChecksumAddress(state.account),getAppByChainId(chainId).BUSD_ADDRESS).call();
      const _rewardBtcb = await earnToken.methods.estimationReward(state.web3.utils.toChecksumAddress(state.account),getAppByChainId(chainId).BTCB_ADDRESS).call();
      const _rewardEth = await earnToken.methods.estimationReward(state.web3.utils.toChecksumAddress(state.account),getAppByChainId(chainId).BETH_ADDRESS).call();
      const _rewardDino = await earnToken.methods.estimationReward(state.web3.utils.toChecksumAddress(state.account),getAppByChainId(chainId).TOKEN_ADDRESS).call();
      const _rewardBsafu = await earnToken.methods.estimationReward(state.web3.utils.toChecksumAddress(state.account),getAppByChainId(chainId).BSAFU_ADDRESS).call();
      
      setRewardBnb(amountFormatFromWei(_rewardBnb,18));
      setRewardBusd(amountFormatFromWei(_rewardBusd[1],18));
      setRewardBtcb(amountFormatFromWei(_rewardBtcb[1],18));
      setRewardEth(amountFormatFromWei(_rewardEth[1],18));
      setRewardDino(amountFormatFromWei(_rewardDino[1],18));
      setRewardBsafu(amountFormatFromWei(_rewardBsafu[1],18))
      setApr(APR)
    } catch(e){
      console.error("Init Reward",e)
    }
  }


  const onClaim = async(name) => {
    if(name === "BNB") {
      try {
        let chainId = state.chainId

        const earnToken = await new state.web3.eth.Contract(
          getAppByChainId(chainId).EARN_ABI,
          getAppByChainId(chainId).EARN_ADDRESS,
        )

        await earnToken.methods.claimToWeth(
          state.web3.utils.toChecksumAddress(state.account),
        ).send({
          from: state.web3.utils.toChecksumAddress(state.account)
        }).on('transactionHash', (h) => {
          setTxHash(h)
          toast.success(({ closeToast }) => <ToastContentTx title={"Approve Submitted"} chainId={state.chainId} txHash={h} />);
        })
        .on('receipt', (h) => {
          setTxHash(h.transactionHash)
          toast.success(({ closeToast }) => <ToastContentTx title={"Approve Received"} chainId={state.chainId} txHash={h.transactionHash} />);
          setIsLoading(false)
        })
      } catch(e) {
        console.error("On Claim Reward",e)
      }
    } else {
      try {
        let chainId = state.chainId
        let targetToken = "";
        if(name === "DINO") targetToken = getAppByChainId(chainId).TOKEN_ADDRESS;
        else if(name === "BSAFU") targetToken = getAppByChainId(chainId).BSAFU_ADDRESS;
        else if(name === "BTCB") targetToken = getAppByChainId(state.chainId).BTCB_ADDRESS;
        else if(name === "BUSD") targetToken = getAppByChainId(state.chainId).BUSD_ADDRESS;
        else if(name === "ETH") targetToken = getAppByChainId(state.chainId).BETH_ADDRESS;

        const earnToken = await new state.web3.eth.Contract(
          getAppByChainId(chainId).EARN_ABI,
          getAppByChainId(chainId).EARN_ADDRESS,
        )

        await earnToken.methods.claimTo(
          state.web3.utils.toChecksumAddress(state.account),
          state.web3.utils.toChecksumAddress(targetToken),
        ).send({
          from: state.web3.utils.toChecksumAddress(state.account)
        }).on('transactionHash', (h) => {
          setTxHash(h)
          toast.success(({ closeToast }) => <ToastContentTx title={"Approve Submitted"} chainId={state.chainId} txHash={h} />);
        })
        .on('receipt', (h) => {
          setTxHash(h.transactionHash)
          toast.success(({ closeToast }) => <ToastContentTx title={"Approve Received"} chainId={state.chainId} txHash={h.transactionHash} />);
          setIsLoading(false)
        })
      } catch(e) {
        console.error("On Claim Reward",e)
      }
    }
  }
  

  return (
    <>
      <div className="grid content-center grid-cols-1 gap-6 align-middle lg:grid-cols-2">
        <div className="w-full mx-auto">
          <div className="border-2 border-green-500 rounded-2xl">
            <div className="w-full bg-white border-b border-gray-300 dark:border-gray-800 dark:bg-gray-900 rounded-2xl">
              <div className="flex justify-between px-6 py-6">
                <div className="block">
                  <h3 className="text-xl font-semibold text-gray-900 dark:text-white">
                    Dino Rewards
                  </h3>
                  <p className="font-normal text-gray-700 dark:text-gray-100">
                    Est. APR: {apr}% <br />
                  </p>
                </div>
                <div className="">
                  <img
                    src="images/icon-reward/dino-token.png"
                    className="border border-gray-200 rounded-full w-14"
                    alt=""
                  />
                  <RiCoinFill
                    className="absolute z-10 w-8 h-8 p-2 ml-8 -mt-5 text-white bg-green-500 border border-gray-200 rounded-full"
                    alt=""
                  />
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-3">
                <div className="flex items-center justify-start">
                  <img
                    src="/images/icon-reward/dino-token.png"
                    className="w-10 h-10"
                    alt=""
                  />
                  <div className="block ml-3">
                    <h1 className="text-lg font-bold text-black dark:text-white">
                      Dino Reward
                    </h1>
                    <p className="text-gray-700 dark:text-gray-500 text-md">
                      <span className="font-light text-green-900">Est</span> {rewardDino}&nbsp;
                      DINO
                    </p>
                  </div>
                </div>
                <div>
                <BtnWalletConnect isRounded={true}>
                  <button
                    type="button"
                    className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full "
                    disabled=""
                    onClick={()=>onClaim("DINO")}
                  >
                    <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      <div>Claim</div>
                    </div>
                  </button>
                </BtnWalletConnect>
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-3">
                <div className="flex items-center justify-start">
                  <img
                    src="/images/icon-reward/bsafu.svg"
                    className="w-10 h-10"
                    alt=""
                  />
                  <div className="block ml-3">
                    <h1 className="text-lg font-bold text-black dark:text-white">
                      BSAFU Reward
                    </h1>
                    <p className="text-gray-700 dark:text-gray-500 text-md">
                      <span className="font-light text-green-900">Est</span> {rewardBsafu}&nbsp;
                      BSAFU
                    </p>
                  </div>
                </div>
                <div>
                <BtnWalletConnect isRounded={true}>
                  <button
                    type="button"
                    className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full "
                    disabled=""
                    onClick={()=>onClaim("BSAFU")}
                  >
                    <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      <div>Claim</div>
                    </div>
                  </button>
                </BtnWalletConnect>
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-3">
                <div className="w-full py-4 font-bold text-center border-t border-b border-gray-400">
                  Or Claim With
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-3">
                <div className="flex items-center justify-start">
                  <img
                    src="/images/icon-reward/bnb.svg"
                    className="w-10 h-10"
                    alt=""
                  />
                  <div className="block ml-3">
                    <h1 className="text-lg font-bold text-black dark:text-white">
                      BNB Reward
                    </h1>
                    <p className="text-gray-700 dark:text-gray-500 text-md">
                      {rewardBnb} BNB
                    </p>
                  </div>
                </div>
                <div>
                <BtnWalletConnect isRounded={true}>
                  <button
                    type="button"
                    className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full "
                    disabled=""
                    onClick={()=>onClaim("BNB")}
                  >
                    <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      <div>Claim</div>
                    </div>
                  </button>
                </BtnWalletConnect>
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-3">
                <div className="flex items-center justify-start">
                  <img
                    src="/images/icon-reward/busd.svg"
                    className="w-10 h-10"
                    alt=""
                  />
                  <div className="block ml-3">
                    <h1 className="text-lg font-bold text-black dark:text-white">
                      BUSD Reward
                    </h1>
                    <p className="text-gray-700 dark:text-gray-500 text-md">
                      {rewardBusd} BUSD
                    </p>
                  </div>
                </div>
                <div>
                <BtnWalletConnect isRounded={true}>
                  <button
                    type="button"
                    className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full "
                    disabled=""
                    onClick={()=>onClaim("BUSD")}
                  >
                    <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      <div>Claim</div>
                    </div>
                  </button>
                </BtnWalletConnect>
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-3">
                <div className="flex items-center justify-start">
                  <img
                    src="/images/icon-reward/btc.svg"
                    className="w-10 h-10"
                    alt=""
                  />
                  <div className="block ml-3">
                    <h1 className="text-lg font-bold text-black dark:text-white">
                      BTCB Reward
                    </h1>
                    <p className="text-gray-700 dark:text-gray-500 text-md">
                      {rewardBtcb} BTCB
                    </p>
                  </div>
                </div>
                <div>
                <BtnWalletConnect isRounded={true}>
                  <button
                    type="button"
                    className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full "
                    disabled=""
                    onClick={()=>onClaim("BTCB")}
                  >
                    <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      <div>Claim</div>
                    </div>
                  </button>
                </BtnWalletConnect>
                </div>
              </div>
              <div className="flex items-center justify-between px-6 py-3">
                <div className="flex items-center justify-start">
                  <img
                    src="/images/icon-reward/eth.svg"
                    className="w-10 h-10"
                    alt=""
                  />
                  <div className="block ml-3">
                    <h1 className="text-lg font-bold text-black dark:text-white">
                      ETH Reward
                    </h1>
                    <p className="text-gray-700 dark:text-gray-500 text-md">
                      {rewardEth} ETH
                    </p>
                  </div>
                </div>
                <div>
                <BtnWalletConnect isRounded={true}>
                  <button
                    type="button"
                    className="block w-full px-5 py-2 text-sm text-white bg-green-500 rounded-full "
                    disabled=""
                    onClick={()=>onClaim("ETH")}
                  >
                    <div className="flex flex-row items-center justify-center text-center align-middle gap-x-2">
                      <div>Claim</div>
                    </div>
                  </button>
                </BtnWalletConnect>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full mx-auto">
          <div className="border-2 border-green-500 rounded-2xl">
            <div className="w-full px-6 py-6 bg-white rounded-2xl">
              <div className="font-normal text-green-900">
                Learn How To Claim
              </div>
              <h1 className="mt-3 text-xl font-extrabold text-black dark:text-white sm:text-2xl lg:text-3xl lg:leading-tight">
                How To Claim
              </h1>
              <div className="my-5 text-black dark:text-white">
                <p>1. Connect Wallet First!</p>
                <p>2. Make Sure You Have Dino Ecosystem Token</p>
                <p>3. Make Sure Reward is not zero!</p>
                <p>4. Select asset you want to claim your reward</p>
                <p>5. Click claim, and pay the gas, wait until loading done!</p>
                <p>6. Your reward already claim</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
