import React, { useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { Footer, Header } from '../components/dino-ui';
import moment from 'moment';
import { Context } from "../Store";
import { RiCalendar2Line } from 'react-icons/ri';

const getSimpleAddress = (str) => {
    if (str.length > 0) {
        return str.substr(0, 6) + "..." + str.substr(str.length - 3, str.length);
    } else {
        return str
    }
};

const getSimpleAddressMobile = (str) => {
    if (str.length > 0) {
        return str.substr(0, 3) + "..." + str.substr(str.length - 3, str.length);
    } else {
        return str
    }
};

function History() {
    const [history, setHistory] = useState('');
    const [owner, setOwner] = useState('');
    const [state, dispatch] = useContext(Context);

    useEffect(async () => {
        if (state && state.web3 && state.account) {
            // console.log(state.account)
            setOwner(state.account);

            axios.get('https://api-shill.dinodapps.com/api/afiliate/detail/' + state.account)
                .then(res => {
                    setHistory(res.data.result)
                })
        }
    }, [state])

    return (
        <>
            <Header />
            <div className="w-full mx-auto">
                <section className="relative py-1 overflow-hidden bg-gray-100 dark:bg-gray-900 md:py-2 lg:py-4 font-body">
                    <div className="px-4 md:px-24 lg:px-28">
                        <div className="w-full">
                            <div className="py-4">
                                <h4 className="text-2xl font-bold">Activity history</h4>
                                <p className="text-lg">Log all activity from your wallet</p>
                            </div>

                            <div className="px-6 py-4 mb-12 bg-white border-2 border-green-500 rounded-md">
                                <div className="flex flex-col items-start justify-between w-full gap-3 md:items-center lg:items-center md:flex-row lg:flex-row xl:flex-row">
                                    <div>
                                        <div className="font-bold">Your Address:</div>
                                        <div className="block mb-3 overflow-x-scroll md:hidden lg:hidden xl:hidden">
                                            {/* {getSimpleAddressMobile(history?.address)} */}
                                            {history.address}
                                        </div>
                                        <div className="hidden mb-3 overflow-x-scroll md:block lg:block xl:block">
                                            {history.address}
                                            {/* {getSimpleAddress(history?.address)} */}
                                        </div>

                                        <div className="font-bold">Your Total Balance</div>
                                        <div className="">{history.balance} DINO</div>
                                    </div>
                                    <a href="" className="px-4 py-2 mt-0 text-white bg-green-500 rounded-md md:mt-4 lg:mt-6 xl:mt-8 md:px-6 md:py-3 lg:px-6 lg:py-3">Claim</a>
                                </div>
                            </div>
                            {history.log?.map((histories, index) => {
                                return (
                                    <div key={index} className="px-6 py-3 my-3 bg-white rounded-md">
                                        <h5 className="font-bold">
                                            {histories.description}
                                        </h5>
                                        {histories.type === 'in' ? (
                                            <div className="text-green-500">
                                                + {histories.amount}
                                            </div>
                                        ) : (
                                            <div className="text-red-500">
                                                - {histories.amount}
                                            </div>
                                        )}
                                        <div className="flex items-center gap-1 text-sm text-gray-600">
                                            <RiCalendar2Line /> {moment(histories.date).format("MMM Do YYYY, h:mm:ss")}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </section>
            </div>
            <Footer />
        </>
    )
}

export default History