import React, { useContext, useEffect, useState } from "react";
import {
  BtnWalletConnect,
  Footer,
  Header,
  ModalTxHash,
} from "../components/dino-ui";
import { Banner } from "../components/Banner";
import { Context } from "../Store";
import { ButtonLoading } from "../components/Forms";
import { getAppByChainId } from "../libs/Env";
import { BigNumber } from "bignumber.js";

const RewardClaimTo = ({ name, symbol, address, icon }) => {
  const [state, dispatch] = useContext(Context);
  const [storeHash, setStoreHash] = useState(null);
  const [estimation, setEstimation] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [txHash, setTxHash] = useState(null);

  useEffect(async () => {
    if (state && state.web3 && state.storeHash != storeHash) {
      setIsLoading(true);
      try {
        const router = await new state.web3.eth.Contract(
          getAppByChainId(state.chainId).REWARD_ROUTER_ABI,
          state.web3.utils.toHex(
            getAppByChainId(state.chainId).REWARD_ROUTER_ADDRESS
          )
        );
        const balance = await router.methods
          .dividendOf(state.web3.utils.toHex(state.account))
          .call();
        const newBalance = new BigNumber(state.web3.utils.fromWei(balance));
        if (!newBalance.isGreaterThan(0)) throw "Zero Dividend";
        if (
          address.toLowerCase() ===
          getAppByChainId(state.chainId).WETH_ADDRESS.toLowerCase()
        ) {
          setEstimation(newBalance.toFormat(8));
        } else {
          const est = await router.methods
            .estimate(state.web3.utils.toHex(state.account), address)
            .call();
          const newEst = new BigNumber(state.web3.utils.fromWei(est));
          // console.log(est);
          setEstimation(newEst.toFormat(8));
        }
        setStoreHash(state.storeHash);
        setIsDisabled(false);
      } catch (e) {
        console.error(3);
        setIsDisabled(true);
      } finally {
        setIsLoading(false);
      }
    } else {
      setEstimation(0);
      setIsDisabled(true);
      setIsLoading(false);
    }
  }, [state]);

  const onClaim = async () => {
    setIsLoading(true);
    try {
      // claim to eth
      const router = await new state.web3.eth.Contract(
        getAppByChainId(state.chainId).REWARD_ROUTER_ABI,
        state.web3.utils.toHex(
          getAppByChainId(state.chainId).REWARD_ROUTER_ADDRESS
        )
      );
      if (
        address.toLowerCase() ===
        getAppByChainId(state.chainId).WETH_ADDRESS.toLowerCase()
      ) {
        await router.methods
          .claimToWeth(state.account)
          .send({
            from: state.account,
          })
          .on("transactionHash", (hash) => setTxHash(hash));
      } else {
        // claim to other
        await router.methods
          .claimToOther(state.account, state.web3.utils.toHex(address))
          .send({
            from: state.account,
          })
          .on("transactionHash", (hash) => setTxHash(hash));
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex items-center justify-between my-6">
      <div className="flex items-center justify-start">
        <img src={icon} className="w-12 h-12" alt="" />
        <div className="block ml-3">
          <h1 className="text-xl font-bold text-black dark:text-white">
            {name} Reward
          </h1>
          <p className="text-gray-700 dark:text-gray-500">
            <span className="font-light text-green-500">
                {name == "BNB" ? <></> : "Est"}
            </span> {estimation}{" "}
            {symbol || name}
          </p>
        </div>
      </div>
      <div>
        <BtnWalletConnect>
          <ButtonLoading
            isLoading={isLoading}
            isDisabled={isDisabled}
            onClick={onClaim}
            title={"Claim"}
          />
        </BtnWalletConnect>
      </div>
      <ModalTxHash txHash={txHash} />
    </div>
  );
};

export default function Reward() {
  return (
    <>
      <Header />
      <Banner />
      <>
        <section className="py-2 bg-white dark:bg-gray-800 md:py-8 lg:py-10">
          <div className="px-4 py-2 mx-2 bg-gray-100 dark:bg-gray-900 md:px-8 lg:px-10 sm:mx-12 md:mx-24 lg:mx-32">
            <div className="flex flex-col mx-auto lg:flex-row">
              <div className="w-full pl-2 md:pl-6 lg:pl-6 md:w-1/2 lg:w-1/2">

                <div className="mt-10 font-normal text-green-500">月 Explain</div>
                <h1 className="mt-3 text-2xl font-extrabold text-black dark:text-white sm:text-3xl lg:text-4xl lg:leading-tight">
                  Reward Not Claimed.
                </h1>
                <p className="my-3 text-black dark:text-gray-100">
                  <span className="px-2 py-1 text-sm text-white bg-green-500 rounded-md">
                    Note:
                  </span>
                  <span className="ml-2">
                  Due to being stuck on the claim rewards, we will force a claim to all holders on February 21-24.
                  </span>
                </p>
                <span className="ml-2 font-bold text-black dark:text-white">
                  Est. APR: 38.47%
                </span>

                <div className="my-10">
                  <RewardClaimTo
                    name={"Weboo"}
                    icon={"./images/icon-reward/weboo.svg"}
                    symbol={"WEBOO"}
                    address={"0x9A093ddcaE05496a05aC76D96d49890b528C8CE0"}
                  />
                  <div className="py-3 font-semibold text-center text-black border-t border-b border-gray-400 dark:border-gray-800 dark:text-white">
                    Or Claim With
                  </div>
                  <RewardClaimTo
                    name={"BNB"}
                    icon={"./images/icon-reward/bnb.svg"}
                    address={"0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"}
                  />
                  <RewardClaimTo
                    name={"BUSD"}
                    icon={"./images/icon-reward/busd.svg"}
                    address={"0xe9e7cea3dedca5984780bafc599bd69add087d56"}
                  />
                  <RewardClaimTo
                    name={"BTCB"}
                    icon={"./images/icon-reward/btc.svg"}
                    address={"0x7130d2a12b9bcbfae4f2634d864a1ee1ce3ead9c"}
                  />
                  <RewardClaimTo
                    name={"ETH"}
                    icon={"./images/icon-reward/eth.svg"}
                    address={"0x2170ed0880ac9a755fd29b2688956bd959f933f8"}
                  />
                </div>
              </div>
              <div className="w-full pl-0 md:pl-20 lg:pl-20 md:w-1/2 lg:w-1/2">
                <div className="mt-10 font-normal text-green-500">
                  月 Learn How To Claim
                </div>
                <h1 className="mt-3 text-2xl font-extrabold text-black dark:text-white sm:text-3xl lg:text-4xl lg:leading-tight">
                  How To Claim
                </h1>
                <div className="my-10 text-black dark:text-white">
                  <p>1. Connect Wallet First!</p>
                  <p>2. Make Sure You Have Weboo v2 (CE0)</p>
                  <p>3. Make Sure Reward is not zero!</p>
                  <p>4. Select asset you want to claim your reward</p>
                  <p>
                    5. Click claim, and pay the gas, wait until loading done!
                  </p>
                  <p>6. Your reward already claim</p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
      <Footer />
    </>
  );
}
